import { analysesReducer } from './analysis/slice';
import { authReducer } from './auth/slice';
import { fileTypeTabsReducer } from './fileTypeTabs/fileTypeTabsSlice';
import { notificationReducer } from './notifications/slice';
import { snackbarReducer } from './snackbar/slice';
import { tableSettingsReducer } from './tableSettings/slice';

const rootReducer = {
  analysis: analysesReducer,
  fileTypeTabs: fileTypeTabsReducer,
  tableSettings: tableSettingsReducer,
  notifications: notificationReducer,
  auth: authReducer,
  snackbar: snackbarReducer,
};

export default rootReducer;
