import { useCallback } from 'react';

import { errorCallback } from 'types/ListHookTypes';
import {
  InternalNotification,
  Notification,
  NotificationId,
} from 'types/notifications';

import { insertNotification } from 'reducers/notifications/slice';
import { useAppDispatch } from 'store/store';

import { nanoid } from 'nanoid';
import { notificationDefaultAutoHideDuration } from 'utils/constants/notifications';

export const useNotifications = () => {
  const dispatch = useAppDispatch();

  const enqueueNotification = useCallback(
    (payload: Notification): NotificationId => {
      const notification: InternalNotification = {
        creationTimestamp: Date.now(),
        uuid: nanoid(),
        read: false,
        autoHideDuration: notificationDefaultAutoHideDuration,
        ...payload,
      };

      dispatch(insertNotification(notification));
      return notification.uuid;
    },
    [dispatch]
  );

  return {
    enqueueNotification,
  };
};

export const useErrorCallback = (): errorCallback => {
  const { enqueueNotification } = useNotifications();
  return useCallback(
    (title, message) => {
      enqueueNotification({
        title,
        message,
        type: 'error',
      });
    },
    [enqueueNotification]
  );
};
