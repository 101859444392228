import React, { ReactNode } from 'react';

import config, { EnvType } from 'config/Config';

import { Provider, ErrorBoundary } from '@rollbar/react';

export default function LogConfig(props: { children: ReactNode }) {
  return (
    <Provider
      config={{
        accessToken: '64806ebf570942a7a89e59dcf9b486e5',
        captureUncaught: true,
        captureUnhandledRejections: true,
        enabled: config.envType === EnvType.Prod,
        environment:
          config.envType === EnvType.Prod
            ? 'production'
            : config.envType === EnvType.Stage
              ? 'staging'
              : 'development',
      }}
    >
      <ErrorBoundary>{props.children}</ErrorBoundary>
    </Provider>
  );
}
