import React, { useCallback, useEffect, useState } from 'react';

import { SubitemCallback } from '../types';
import {
  Analysis,
  AnalysisId,
  AnalysisType,
  BindingAffinityInfo,
  ExperimentInfo,
} from 'types/APITypes/APITypes';

import { getAnalysisChangesPendingById } from 'reducers/analysis/analysisSelectors';
import { useAppSelector } from 'store/store';

import {
  Box,
  Collapse,
  IconButton,
  List,
  Typography,
  useMediaQuery,
  Button,
  Modal,
  Slide,
  Paper,
  useTheme,
  ListItemButton,
  Tooltip,
} from '@mui/material';

import { ValidatedComponent } from 'components/Inputs/Inputs';
import { TextFieldComponent } from 'components/Inputs/ValidatedTextField';
import Loader from 'components/loader/loader';

// Icons
import { CloseIcon, DropdownIcon, AnalysisIcon } from './icons';

import { useStyles } from './styles';

import { ErrorShape, stopEvent, stopPropagation } from 'utils/utils';
import { validateAnalysisName } from 'utils/validation/validation';

import EditAnalysisButton from './EditAnalysisButton';
import { SidebarSubItem } from './SidebarSubItem';

import { withStyles } from 'tss-react/mui';

const AnalysisName = ValidatedComponent(
  TextFieldComponent,
  validateAnalysisName
);

// Customized ListItemButton component with hover-highlighting disabled
const CustomizedListItemButton = withStyles(ListItemButton, {
  root: {
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  selected: {
    backgroundColor: 'rgba(63, 81, 181, 0.08) !important',
  },
});

export type SidebarItemProps = {
  analysis: Analysis;
  selectedAnalysis?: AnalysisId | null;
  sidebarOpen: boolean;
  isSelected: boolean;

  onItemClick?: (id: AnalysisId, type: AnalysisType) => void;
  onItemRemove?: (id: AnalysisId) => void;
  onItemEdit?: (id: AnalysisId) => void;
  onItemRename?: (title: string, id: AnalysisId) => void;
  onSubItemClick?: SubitemCallback;
  onSubItemRemove?: SubitemCallback;
};

export function SidebarItem(props: SidebarItemProps): JSX.Element {
  const { classes, cx } = useStyles();
  const { analysis, sidebarOpen } = props;

  const hasPendingChanges = useAppSelector((state) =>
    getAnalysisChangesPendingById(state, analysis.uuid)
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // State for Name-Editing
  const [analysisName, setAnalysisName] = useState(analysis.analysisName);
  const [changedName, setChangedName] = useState(analysis.analysisName);
  const [analysisNameError, setAnalysisNameError] = useState<ErrorShape>({
    error: false,
    message: '',
  });
  const [editMode, setEditMode] = useState(false);

  // Expansion State and synchronization
  const [isExpanded, setExpanded] = useState(false);
  const { isSelected } = props;

  useEffect(() => {
    setExpanded(isSelected);
  }, [isSelected]);

  const { onItemClick } = props;
  const onItemToggleExpanded = useCallback((): void => {
    setExpanded((expanded) => !expanded);

    // Select this item, if it's not selected already
    if (!isSelected) {
      onItemClick?.(analysis.uuid, analysis.analysisType);
    }
  }, [onItemClick, isSelected, analysis]);

  const handleRootClick = useCallback(() => {
    onItemClick?.(analysis.uuid, analysis.analysisType);
  }, [analysis, onItemClick]);

  // wrapped onClick handlers
  const { onItemRemove } = props;
  const handleAnalysisRemove = useCallback((): void => {
    onItemRemove?.(analysis.uuid);
  }, [onItemRemove, analysis]);

  // Callbacks
  const { onItemEdit } = props;
  const handleAnalysisEdit = useCallback((): void => {
    onItemEdit?.(analysis.uuid);
  }, [onItemEdit, analysis]);

  const { onItemRename } = props;
  const handleNameEditCommit = useCallback((): void => {
    if (!analysisNameError.error) {
      onItemRename?.(changedName.trim(), analysis.uuid);
    }
    setEditMode(false);
  }, [onItemRename, changedName, analysis, analysisNameError]);

  const handleNameCommit = useCallback(
    (name: string): void => {
      setAnalysisName(name);
      onItemRename?.(name.trim(), analysis.uuid);
      setEditMode(false);
    },
    [onItemRename, analysis]
  );

  const handleNameEditCancel = useCallback((): void => {
    setEditMode(false);
    setAnalysisNameError({
      error: false,
      message: '',
    });
  }, []);

  const onAnalysisNameChange = useCallback((name: string) => {
    setChangedName(name);
  }, []);

  const handleTitleClick = useCallback(
    (event: React.MouseEvent<any, any>) => {
      event.stopPropagation();
      // If not selected, fall back to analysis selection instead
      if (!isSelected) {
        onItemClick?.(analysis.uuid, analysis.analysisType);
        return;
      }
      setEditMode(true);
    },
    [isSelected, onItemClick, analysis]
  );

  const isSingleItemAnalysis = analysis.experiments.length === 1;

  const isAffinityScreeningAnalysis =
    analysis.analysisType === 'Affinity Screening';

  const isCovalentKineticsAnalysis =
    analysis.analysisType === 'Covalent Kinetics';

  const showAddButton = !isCovalentKineticsAnalysis;
  const showRemoveButton = !isCovalentKineticsAnalysis && !isSingleItemAnalysis;

  const subItemIsDisabled =
    isSingleItemAnalysis ||
    isAffinityScreeningAnalysis ||
    isCovalentKineticsAnalysis;

  // Cancel editing when the sidebar closes:
  useEffect(() => {
    if (sidebarOpen) return;
    if (!editMode) return;
    handleNameEditCancel();
  }, [editMode, sidebarOpen, handleNameEditCancel]);

  /*
  When the user starts editing both on the Analysis Page and in the sidebar itself,
  this will be called, once they've committed their changes.
  To avoid confusion, the edit-dialog will be closed here, as well. (and the name updated)
  */
  useEffect(() => {
    const name = analysis.analysisName;
    if (analysisName !== name) {
      setAnalysisName(name);
      setChangedName(name);
    }
    setEditMode(false);
  }, [analysis, analysisName]);

  // Analysis Name error callback, so we can place the helper-text into the appropriate place
  const handleError = useCallback((error: ErrorShape) => {
    setAnalysisNameError(error);
  }, []);

  return (
    <Box className={classes.rootWrapper}>
      <CustomizedListItemButton
        onClick={handleRootClick}
        selected={isSelected}
        classes={{
          root: cx({
            [classes.root]: true,
            [classes.rootSidebarOpen]: sidebarOpen,
            [classes.rootEditMode]: sidebarOpen && !isMobile && editMode,
          }),
        }}
        data-testid={`sidebar_item_${analysis.uuid}`}
      >
        {/* ListItem content */}
        <Box
          sx={{
            display: 'flex',
            padding: '8px 8px 8px 16px',
            width: '100%',
            height: '100%',
          }}
          data-testid={`sidebar-item-container-${analysis.uuid}`}
        >
          {/* Icon */}
          <Box
            sx={{
              my: 'auto',
              width: 24,
              height: 24,
            }}
            data-testid={`analysis_icon_${analysis.uuid}`}
          >
            {hasPendingChanges ? (
              <Loader width="24px" height="24px" />
            ) : (
              <AnalysisIcon sx={{ fontSize: 24 }} />
            )}
          </Box>

          {/* Dropdown */}
          <IconButton
            aria-label="expand"
            onClick={stopPropagation(onItemToggleExpanded)}
            size="large"
            sx={{
              padding: '4px',
              my: 'auto',
            }}
            className={cx({
              [classes.dropdownIcon]: true,
              [classes.dropdownIconHidden]: !sidebarOpen,
            })}
          >
            <DropdownIcon
              sx={{ fontSize: 20 }}
              className={cx({
                [classes.icon]: true,
                [classes.dropdown]: true,
                [classes.dropdownClosed]: isExpanded && sidebarOpen,
              })}
              data-testid={`dropdown_icon_${analysis.uuid}`}
            />
          </IconButton>

          {/* Name / TextField in editMode and Subtitle */}
          <Box
            sx={{
              flex: '1 1 0',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
              ml: 1,
            }}
          >
            {/* Title */}
            <Tooltip
              title={!editMode || isMobile ? analysisName : ''}
              placement="right"
              enterDelay={1000}
            >
              <Box sx={{ height: 42, overflow: 'hidden', display: 'flex' }}>
                {(!editMode || isMobile) && (
                  <Typography
                    variant="body1"
                    component="div"
                    onClick={handleTitleClick}
                    onMouseDown={stopEvent}
                    className={cx(classes.analysisTitle, {
                      [classes.analysisTitleActive]: isSelected,
                    })}
                    data-testid={`analysis_name_${analysis.uuid}`}
                    sx={{ my: 'auto' }}
                  >
                    {analysis.analysisName}
                  </Typography>
                )}
                {editMode && !isMobile && (
                  <AnalysisName
                    value={analysisName}
                    onCancel={handleNameEditCancel}
                    onCommit={handleNameCommit}
                    onError={handleError}
                    sx={{ margin: '5px 0 0 0' }}
                    size="small"
                    autoFocus
                    label="Analysis Name"
                  />
                )}
              </Box>
            </Tooltip>
            {/* Subtitle */}
            <Box flex="1 1 0" overflow="hidden">
              {/* Subtitle - Not in edit mode */}
              {(!editMode || isMobile) && (
                <Typography
                  variant="body2"
                  sx={{ color: 'text.secondary' }}
                  data-testid={`sidebar-item-subtitle-${analysis.uuid}`}
                >
                  {analysis.analysisType}
                </Typography>
              )}

              {/* Subtitle - Not mobile, in edit mode */}
              {!isMobile && editMode && (
                <Typography
                  variant="caption"
                  sx={{
                    color: analysisNameError.error
                      ? 'error.main'
                      : 'text.secondary',
                  }}
                  data-testid={`sidebar-item-subtitle-${analysis.uuid}`}
                >
                  {analysisNameError.error
                    ? analysisNameError.message
                    : 'Type In To Rename Your Analysis'}
                </Typography>
              )}
            </Box>
          </Box>

          {/* Close-button */}
          <Box
            sx={{
              my: 'auto',
              ml: editMode && !isMobile ? 0 : 2,
              width: editMode && !isMobile ? 0 : 36,
              transition: (theme) =>
                theme.transitions.create(['width', 'margin'], {
                  duration: theme.transitions.duration.short,
                }),
            }}
          >
            <Tooltip title="Close Analysis" placement="right">
              <IconButton
                aria-label="remove analysis"
                data-testid={`remove_analysis_${analysis.uuid}`}
                onClick={stopPropagation(handleAnalysisRemove)}
                onMouseDown={stopEvent}
                disabled={hasPendingChanges}
                className={classes.icon}
              >
                <CloseIcon sx={{ fontSize: 20 }} />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

        {/* Modal for Name-editing */}
        <Modal
          open={editMode && isMobile}
          onClose={stopPropagation(handleNameEditCancel)}
          onClick={stopEvent}
          data-testid={`name_editing_modal_${analysis.uuid}`}
        >
          <Slide in={editMode && isMobile} direction="up">
            <Paper className={classes.renameModalRoot}>
              {/* Actions */}
              <Box className={classes.renameModalButtonWrapper}>
                <Button
                  autoFocus
                  onClick={stopPropagation(handleNameEditCancel)}
                  data-testid={`cancel_button_${analysis.uuid}`}
                >
                  <Typography variant="body2">
                    <Box fontWeight="fontWeightMedium" component="span">
                      CANCEL
                    </Box>
                  </Typography>
                </Button>
                <Button
                  onClick={stopPropagation(handleNameEditCommit)}
                  className={classes.renameModalRenameButton}
                  data-testid={`rename_button_${analysis.uuid}`}
                >
                  <Typography variant="body2">
                    <Box fontWeight="fontWeightMedium" component="span">
                      RENAME
                    </Box>
                  </Typography>
                </Button>
              </Box>

              {/* Content */}
              <Box className={classes.renameModalContent}>
                <AnalysisName
                  value={analysisName}
                  onCancel={handleNameEditCancel}
                  onCommit={handleNameCommit}
                  onChange={onAnalysisNameChange}
                  onError={handleError}
                />
                <Typography
                  variant="caption"
                  data-testid={`sidebar-item-edithint-${analysis.uuid}`}
                >
                  <Box component="span" color="text.hint">
                    Type In To Rename Your Analysis
                  </Box>
                </Typography>
              </Box>
            </Paper>
          </Slide>
        </Modal>
      </CustomizedListItemButton>

      {/* Child-items */}
      <Collapse
        in={isExpanded && sidebarOpen}
        timeout={theme.timing.sidebarAnimation * 0.5}
        unmountOnExit
      >
        <List
          disablePadding
          data-testid={`sidebaritem-subitemlist-${analysis.uuid}`}
        >
          {/* Edit Analysis Button */}
          {showAddButton && (
            <EditAnalysisButton
              analysisId={analysis.uuid}
              onEditExperiments={handleAnalysisEdit}
            />
          )}
          {/* Experiments */}
          {analysis.experiments.map(
            (experiment: BindingAffinityInfo | ExperimentInfo, i: number) => (
              <SidebarSubItem
                key={i}
                activeExperimentId={analysis.activeExperimentId}
                analysisSelected={isSelected}
                experiment={experiment}
                disabled={subItemIsDisabled}
                showRemoveButton={showRemoveButton}
                onClick={() =>
                  props.onSubItemClick?.({
                    itemId: analysis.uuid,
                    subItemId: experiment.uuid,
                  })
                }
                onRemove={() =>
                  props.onSubItemRemove?.({
                    itemId: analysis.uuid,
                    subItemId: experiment.uuid,
                  })
                }
              />
            )
          )}
        </List>
      </Collapse>
    </Box>
  );
}

export default SidebarItem;
