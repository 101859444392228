import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestStatus, WithRequestStatus } from 'reducers/utils';

export enum FileTypeTabLabel {
  Binding = 'Binding Experiments',
  Stability = 'Stability Measurements',
}

export type FileTypeTab = {
  fileTypeTabLabel: FileTypeTabLabel;
  routeName?: string;
  fileTypes: string[];
};

interface FileTypesAndTabsType extends WithRequestStatus {
  fileTypeTabs: FileTypeTab[];
}

const initialState: FileTypesAndTabsType = {
  requestStatus: RequestStatus.IDLE,
  fileTypeTabs: [],
};

const fileTypeTabsSlice = createSlice({
  name: 'fileTypeTabs',
  initialState,
  reducers: {
    getFileTypesSent: (state) => {
      state.requestStatus = RequestStatus.PENDING;
    },
    getFileTypesReceived: (state, action: PayloadAction<FileTypeTab[]>) => {
      state.requestStatus = RequestStatus.SUCCEEDED;
      state.fileTypeTabs = action.payload.map((ftt) => {
        const processedFileTypeTab = { ...ftt } as FileTypeTab;
        processedFileTypeTab.routeName = ftt.fileTypeTabLabel
          .toLowerCase()
          .replace(' ', '-');
        return processedFileTypeTab;
      });
    },
    getFileTypesRejected: (state) => {
      state.requestStatus = RequestStatus.FAILED;
    },
    resetGetFileTypes: (state) => {
      state.requestStatus = RequestStatus.IDLE;
    },
  },
});

export default fileTypeTabsSlice;

export const {
  getFileTypesSent,
  getFileTypesReceived,
  getFileTypesRejected,
  resetGetFileTypes,
} = fileTypeTabsSlice.actions;

export const fileTypeTabsReducer = fileTypeTabsSlice.reducer;
