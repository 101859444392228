import React, { useCallback, useState } from 'react';

import { Analysis, AnalysisId } from 'types/APITypes/APITypes';

import { selectActiveAnalysisId } from 'reducers/analysis/analysisSelectors';
import { toggleSingleLinkedExperimentActiveStatus } from 'reducers/analysis/slice';
import { removeLinkedExperiment } from 'reducers/analysis/thunk';
import { useAppDispatch, useAppSelector } from 'store/store';

import { Box, Theme, useMediaQuery, useTheme } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';

import AppToolbar from 'components/AppToolbar/AppToolbar';
import NotificationDrawer from 'components/NotificationDrawer/NotificationDrawer';
import SideBar from 'components/SideBar/Sidebar';
import AnalysisEditModal from 'modals/AnalysisEditModal/AnalysisEditModal';

import Routes from 'routes/routes';

import './App.scss';

import { selectSnackbarState } from './reducers/snackbar/selectors';
import { closeSnackbar } from './reducers/snackbar/slice';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  contentOpen: {
    width: `calc(100% - ${theme.sizing.sidebarWidthOpen}px)`,
    overflow: 'hidden',
  },

  contentOpenMobile: {
    display: 'none',
    width: `calc(100% - ${theme.sizing.sidebarWidthOpen}px)`,
  },

  contentClose: {
    width: `calc(100% - ${theme.sizing.sidebarWidthClosed}px)`,
    overflowX: 'hidden',
  },

  root: {
    display: 'flex',
  },

  menuButton: {
    marginRight: 36,
  },

  hide: {
    display: 'none',
  },

  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    // The as any is necessary because otherwise react-tss throws a type-error
    ...(theme.mixins.toolbar as any),
  },

  content: {
    display: 'flex',
    paddingTop: theme.sizing.toolbarHeight,
    height: '100%',
    maxWidth: '100%',
  },
}));

const App: React.FC<{}> = () => {
  const { classes, cx } = useStyles();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const [sidebarOpen, setSidebarOpen] = useState(true);

  // Edit Modal state:
  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const [analysisToEdit, setAnalysisToEdit] = React.useState<
    AnalysisId | undefined
  >();
  const handleEditModalClose = useCallback((): void => {
    setEditModalOpen(false);
    setAnalysisToEdit(undefined);
  }, []);

  const handleOnEdit = useCallback((id: string): void => {
    setAnalysisToEdit(id);
    setEditModalOpen(true);
  }, []);

  const handleSidebarToggle = useCallback(() => {
    setSidebarOpen((open) => !open);
  }, []);

  const analysisState = useAppSelector((state) => state.analysis);
  const activeAnalysisId = useAppSelector(selectActiveAnalysisId);
  const actions = Object.values(analysisState.analyses) as Analysis[];

  const snackbarState = useAppSelector(selectSnackbarState);

  return (
    <>
      <AppToolbar />
      <Box display="flex" className={classes.content}>
        <SideBar
          open={sidebarOpen}
          setOpen={setSidebarOpen}
          actions={actions}
          active={activeAnalysisId}
          onItemEdit={handleOnEdit}
          onSubItemRemove={({ itemId, subItemId }) =>
            dispatch(
              removeLinkedExperiment({
                analysisId: itemId,
                experimentId: subItemId,
              })
            )
          }
          onSubItemClick={(options) =>
            dispatch(
              toggleSingleLinkedExperimentActiveStatus({
                analysisId: options.itemId,
                experimentId: options.subItemId,
              })
            )
          }
          onSidebarToggle={handleSidebarToggle}
        />
        <Box
          display="flex"
          flexDirection="column"
          flexGrow="1"
          className={cx({
            [classes.contentOpen]: sidebarOpen && !mobile,
            [classes.contentOpenMobile]: sidebarOpen && mobile,
            [classes.contentClose]: !sidebarOpen,
          })}
        >
          <Routes />
        </Box>
      </Box>

      <AnalysisEditModal
        open={editModalOpen}
        editId={analysisToEdit}
        onClose={handleEditModalClose}
      />

      <NotificationDrawer />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackbarState.open}
        message={snackbarState.message}
        onClose={() => dispatch(closeSnackbar())}
        autoHideDuration={4000}
      />
    </>
  );
};

export default App;
