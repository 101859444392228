import { useEffect } from 'react';

import { errorCallback } from 'types/ListHookTypes';

import { selectFileTypeTabs } from 'reducers/fileTypeTabs/fileTypeTabsSelectors';
import {
  FileTypeTab,
  getFileTypesSent,
  getFileTypesRejected,
  getFileTypesReceived,
  FileTypeTabLabel,
  resetGetFileTypes,
} from 'reducers/fileTypeTabs/fileTypeTabsSlice';
import { RequestStatus } from 'reducers/utils';
import { useAppDispatch, useAppSelector } from 'store/store';

import { getFileTypes } from '../files';

import { isEqual } from 'lodash';
import log from 'loglevel';

interface FileTypeHookOptions {
  onError?: errorCallback;
}
interface FileTypeHookReturn {
  fileTypesLoading: boolean;
  fileTypeTabs: FileTypeTab[];
}

export const useFileTypes = (
  options: FileTypeHookOptions = {}
): FileTypeHookReturn => {
  const { onError } = options;
  const dispatch = useAppDispatch();
  const { requestStatus, fileTypeTabs } = useAppSelector(selectFileTypeTabs);

  /* Register hook, which get's called on Component-mount */
  useEffect(() => {
    function fetchFileTypeList() {
      dispatch(getFileTypesSent());
      getFileTypes()
        .then(({ status, body }) => {
          if (status !== 200) {
            onError?.(
              'Could not get file-types',
              'The backend server did not respond, while retreiving the information required for uploading files.'
            );
            dispatch(getFileTypesRejected());
            return;
          }
          if (body == null) {
            dispatch(getFileTypesReceived([]));
            return;
          }
          const tabs = new Set(body.map((r) => r.fileTypeTabLabel));
          const tabsList = [...tabs].sort();
          const expectedTabsList = [
            FileTypeTabLabel.Binding,
            FileTypeTabLabel.Stability,
          ];
          if (!isEqual(tabsList, expectedTabsList)) {
            const logString = `Frontend only supports FileTabs ${expectedTabsList.join(
              ','
            )}, but was ${tabsList.join(',')}`;
            log.error(logString);
            onError?.('Could not get file-types', logString);
            return;
          }
          const response = [...tabs].map(
            (tab) =>
              ({
                fileTypeTabLabel: tab,
                fileTypes: body
                  .filter((r) => r.fileTypeTabLabel === tab)
                  .map((r) => r.fileType),
              }) as FileTypeTab
          );
          dispatch(getFileTypesReceived(response));
        })
        .catch((err) => {
          dispatch(getFileTypesRejected());
          onError?.(
            'Could not get file-types',
            'A not-expected error occurred when requesting the list of supported file type from the backend.'
          );
          log.error(err);
        });
    }
    if (requestStatus === RequestStatus.IDLE) {
      fetchFileTypeList();
    }

    if (requestStatus === RequestStatus.FAILED) {
      setTimeout(() => {
        dispatch(resetGetFileTypes());
      }, 1000);
    }
  }, [onError, requestStatus, dispatch]);

  return {
    fileTypesLoading: requestStatus === RequestStatus.PENDING,
    fileTypeTabs: fileTypeTabs ?? [],
  };
};
