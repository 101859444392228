import React from 'react';

import { DataTableSortButtonProps, AriaLabel } from './types';
import { DataTableSort } from 'components/DataTable/types/types';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import { ExpandLess, ExpandMore } from '@mui/icons-material';

import { useStyles } from './styles';

export function DataTableHeadSortButton(props: DataTableSortButtonProps) {
  const { classes, cx } = useStyles();

  const getAriaLabel = (sortDirection: DataTableSort['sortDirection']) => {
    if (sortDirection === undefined) return AriaLabel.none;
    if (sortDirection === 'ascending') return AriaLabel.asc;
    if (sortDirection === 'descending') return AriaLabel.desc;
  };

  const getTestId = (sortDirection: DataTableSort['sortDirection']) => {
    switch (sortDirection) {
      case 'ascending':
        return 'sortasc-upicon';
      case 'descending':
        return 'sortdesc-downicon';
      default:
        return 'nosort-icon';
    }
  };

  return (
    <IconButton
      color="secondary"
      data-testid="sort-griddata-button"
      onClick={props.onClick}
      aria-label={getAriaLabel(props.sortDirection)}
      style={{
        padding: 1,
        margin: '0 7px',
      }}
      size="large"
    >
      <Box display="flex">
        <Box
          sx={{
            fontSize: 16,
            display: 'flex',
            flexDirection: 'column',
            my: 'auto',
          }}
          data-testid={getTestId(props.sortDirection)}
        >
          <ExpandLess
            sx={{ mb: '-2.5px', mt: '-4.75px' }}
            fontSize="inherit"
            className={cx(
              classes.icon,
              props.sortDirection === 'ascending' ? 'active' : 'inactive'
            )}
          />
          <ExpandMore
            sx={{ mb: '-4.75px', mt: '-2.5px' }}
            fontSize="inherit"
            className={cx(
              classes.icon,
              props.sortDirection === 'descending' ? 'active' : 'inactive'
            )}
          />
        </Box>
      </Box>
    </IconButton>
  );
}

export default React.memo(DataTableHeadSortButton);
