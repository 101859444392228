import React from 'react';

import LoadingSpinner from 'assets/icons/LoadingSpinner.svg?react';

import './style.scss';

export interface LoaderProps {
  width?: string;
  height?: string;
  style?: Partial<React.CSSProperties>;
  centered?: boolean;
  'data-testid'?: string;
}

function Loader(props: LoaderProps): JSX.Element {
  const width = props.width ?? '3rem';
  const height = props.height ?? '3rem';

  const loader = (
    <LoadingSpinner
      data-testid={props['data-testid'] ?? 'loader-svg'}
      className="loader"
      width={width}
      height={height}
      style={{ overflow: 'visible', ...props.style }}
    />
  );

  if (props.centered) {
    return <div className="centered">{loader}</div>;
  }
  return loader;
}

export default Loader;
