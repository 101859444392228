import * as React from 'react';

import { Box, Button, SxProps, Theme, Typography } from '@mui/material';

import { Close as CloseIcon } from '@mui/icons-material';

export type AnalysisListSubheaderProps = {
  sx?: SxProps<Theme>;
  numOpenAnalyses?: number;
  onCloseAllAnalyses?: () => void;
};

function AnalysisListSubheader(props: AnalysisListSubheaderProps) {
  return (
    <Box
      sx={[
        {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexShrink: 0,
        },
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
    >
      <Typography
        variant="overline"
        sx={{ padding: '0.8125rem 1rem' }}
        data-testid="open-objects-count"
      >
        Open Objects ({props.numOpenAnalyses ?? 0})
      </Typography>
      <Button
        data-testid="close-all-button"
        sx={{ '& .MuiButton-endIcon': { marginRight: '-1px' } }}
        disabled={props.numOpenAnalyses === 0}
        size="small"
        endIcon={<CloseIcon />}
        onClick={props.onCloseAllAnalyses}
      >
        Close All
      </Button>
    </Box>
  );
}

export default AnalysisListSubheader;
