import {
  EditableDataTableColumnSetup,
  GenericDataTableColumnSetup,
  NonEditableDataTableColumnSetup,
} from './types';
import {
  DataTableColumn_t,
  DataTableRowBase,
} from 'components/DataTable/types/types';
import { DeepPrefixedKeys } from 'types/Utilities';

import { nanoid } from 'nanoid';

import { createDataTableCell } from '../DataTableCell/DataTableCell';
import { createDataTableCellEditable } from '../DataTableCellEditable/DataTableCellEditable';

function createGenericDataTableColumn<
  TRow extends DataTableRowBase,
  TRowId,
  Name_t extends DeepPrefixedKeys<TRow> | DeepPrefixedKeys<TRow>[],
>(
  column: GenericDataTableColumnSetup<TRow, TRowId, Name_t>
): DataTableColumn_t<TRow, TRowId> {
  const columnId = nanoid();
  const sortLabel =
    column.sortLabel ??
    (Array.isArray(column.key) ? column.key[0] : column.key).toString();

  return {
    columnId,
    key: column.key,
    label: column.label,
    sortLabel,
    disableSort: column.disableSort ?? false,
    editable: column.editable ?? false,
    options: {
      customBodyRender: column.editable
        ? createDataTableCellEditable({
            columnId,
            label: column.editLabel ?? '',
            placeholder: column.placeholder ?? '',
            onChange: column.onUpdate,
            EditableCellComponent: column.EditableCellComponent,
          })
        : column.customBodyRenderer ??
          createDataTableCell({
            formatValue: column.formatValue,
          }),
      sortThirdClickReset: true,
    },
  };
}

export function DataTableColumn<
  TRow extends DataTableRowBase,
  TRowId,
  Name_t extends DeepPrefixedKeys<TRow> | DeepPrefixedKeys<TRow>[],
>(
  column: NonEditableDataTableColumnSetup<TRow, TRowId, Name_t>
): DataTableColumn_t<TRow, TRowId> {
  return createGenericDataTableColumn({ ...column, editable: false });
}

export function EditableDataTableColumn<
  TRow extends DataTableRowBase,
  TRowId,
  Name_t extends DeepPrefixedKeys<TRow> | DeepPrefixedKeys<TRow>[],
>(
  column: EditableDataTableColumnSetup<TRow, TRowId, Name_t>
): DataTableColumn_t<TRow, TRowId> {
  return createGenericDataTableColumn({ ...column, editable: true });
}

type ColumnStylingInfo = 'shortColumnHeaders' | 'normalColumnHeaders';
// MakeStyles-esque wrapper for Column-def creation
// This is only used, to inject typing into the columns
type RowChangeCallback<TRowId> = (id: TRowId) => void;
type MakeTableColumnDefinition<TRow extends DataTableRowBase, TRowId> = (
  onRowChange?: RowChangeCallback<TRowId>,
  stylingInfo?: ColumnStylingInfo
) => DataTableColumn_t<TRow, TRowId>[];

export function makeTableColumns<TRow extends DataTableRowBase, TRowId>(
  definition: MakeTableColumnDefinition<TRow, TRowId>
): (
  onRowChange?: RowChangeCallback<TRowId>,
  stylingInfo?: ColumnStylingInfo
) => DataTableColumn_t<TRow, TRowId>[] {
  return (onRowChange, stylingInfo) => definition(onRowChange, stylingInfo);
}
