import { useStyles } from './styles';

import Scrollbar from './Scrollbar';
import { useScrollbarMapping } from './utils';

type ScrollContainerProps = {
  forcePaddingX?: boolean;
  forcePaddingY?: boolean;
  onScrollableChanged?: (scrollable: { x: boolean; y: boolean }) => void;
};

const ScrollContainer = (
  props: React.PropsWithChildren<ScrollContainerProps>
): JSX.Element => {
  const { forcePaddingX, forcePaddingY, onScrollableChanged } = props;
  const { classes, cx } = useStyles();

  const {
    containerRef,
    scrollbarSize,
    scrollable,
    scrollbarOffsets,
    scrollbarOffsetsMax,
    scrollbarThumbSize,
    handleThumbMouseDownY,
    handleThumbMouseDownX,
    userScrolling,
  } = useScrollbarMapping({ onScrollableChanged });

  const scrollableBothWays = scrollable.x && scrollable.y;

  return (
    <div
      className={cx({
        [classes.container]: true,
        vertical: forcePaddingY ?? scrollable.y,
        horizontal: forcePaddingX ?? scrollable.x,
      })}
    >
      <div
        className={cx(classes.root, {
          [classes.rootEnableSnapping]: !userScrolling,
        })}
        ref={containerRef}
      >
        {props.children}
      </div>
      <Scrollbar
        show={scrollable.y}
        orientation="vertical"
        size={scrollbarSize.y}
        thumbSize={scrollbarThumbSize.y}
        offset={scrollbarOffsets.y}
        offsetMax={scrollbarOffsetsMax.y}
        onThumbMouseDown={handleThumbMouseDownY}
      />
      <Scrollbar
        show={scrollable.x}
        orientation="horizontal"
        size={scrollbarSize.x}
        thumbSize={scrollbarThumbSize.x}
        offset={scrollbarOffsets.x}
        offsetMax={scrollbarOffsetsMax.x}
        onThumbMouseDown={handleThumbMouseDownX}
      />
      {scrollableBothWays && <div className={classes.xyScrollFiller} />}
    </div>
  );
};

export default ScrollContainer;
