import React from 'react';

import { Analysis } from 'types/APITypes/APITypes';

import {
  Box,
  IconButton,
  ListItem,
  ListItemButton,
  Typography,
} from '@mui/material';

import { RemoveIcon } from './icons';

import { useStyles } from './styles';

type ListItemWrapperProps = {
  children: React.ReactNode;
  disabled: boolean;
  className: string;
  onClick: () => void;
  ['data-testid']: string;
};

function ListItemWrapper({
  children,
  ...props
}: ListItemWrapperProps): JSX.Element {
  return props.disabled ? (
    <ListItem data-testid={props['data-testid']} className={props.className}>
      {children}
    </ListItem>
  ) : (
    <ListItemButton
      data-testid={props['data-testid']}
      className={props.className}
      onClick={props.onClick}
    >
      {children}
    </ListItemButton>
  );
}

type Unpacked<T> = T extends (infer U)[] ? U : T;

type SidebarSubItemProps = {
  experiment: Unpacked<Analysis['experiments']>;
  disabled: boolean;
  activeExperimentId: Analysis['activeExperimentId'];
  analysisSelected: boolean;
  showRemoveButton: boolean;
  onClick: () => void;
  onRemove: () => void;
};

export function SidebarSubItem(props: SidebarSubItemProps): JSX.Element {
  const { experiment, disabled, showRemoveButton } = props;
  const { classes, cx } = useStyles();

  const experimentSelected =
    experiment.uuid === props.activeExperimentId && props.analysisSelected;

  return (
    <ListItemWrapper
      key={`${experiment.uuid}-subitem`}
      disabled={disabled}
      onClick={props.onClick}
      className={classes.subItemWrapper}
      data-testid={`sidebarsubitem-${experiment.uuid}`}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        className={cx({
          [classes.subItemRoot]: true,
          [classes.subItemActive]: experimentSelected,
        })}
      >
        <Box
          my="auto"
          overflow="hidden"
          data-testid={`sidebarsubitem-name-${experiment.uuid}`}
        >
          <Typography variant="body1">
            <Box
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
              component="span"
            >
              - {experiment.experimentName}
            </Box>
          </Typography>
        </Box>

        {showRemoveButton && (
          <Box flexGrow={0}>
            <IconButton
              aria-label="delete experiment"
              onClick={props.onRemove}
              className={classes.icon}
              size="large"
              sx={{ padding: 1 }}
              data-testid={`sidebarsubitem-remove-button-${experiment.uuid}`}
            >
              <RemoveIcon sx={{ fontSize: 20 }} />
            </IconButton>
          </Box>
        )}
      </Box>
    </ListItemWrapper>
  );
}
