import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    padding: '0 1rem',
    flexWrap: 'wrap',
    background: theme.palette.background.default,
  },

  errorBox: {
    display: 'flex',
    marginRight: '1.25rem',
    color: 'rgba(0, 0, 0, 0.54)',
  },

  warning: {
    color: theme.palette.warning.dark,
  },

  error: {
    color: theme.palette.error.dark,
  },
}));
