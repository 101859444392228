import {
  APIResponse,
  FileFilterProps,
  FileInfo,
  GenericAPIRequestMethod,
} from '../types/APITypes/APITypes';

import { AxiosError } from 'axios';

import { getUrl } from 'utils/helpers/requestbuilder';

import { cleanObject } from '../utils/utils';
import {
  axiosInstance,
  parseAPIResponse,
  ProgressCallback,
  catchError,
} from './CASAPI';

// Parsing

export const parseFileInfoFromAPI = (file: FileInfo): FileInfo => ({
  ...file,
  experimentIds: file.experimentIds || [],
  updatedAt: file.createdAt,
});

// API Calls

export const uploadFile: GenericAPIRequestMethod<
  never,
  File,
  Pick<FileInfo, 'uuid'>
> = async (file: File, requestConfig) => {
  const formData = new FormData();
  formData.append('fileUpload', file);
  const response = await axiosInstance.post(getUrl('fileUpload'), formData, {
    ...requestConfig,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return parseAPIResponse(response, parseFileInfoFromAPI);
};

export const getFiles: GenericAPIRequestMethod<
  FileFilterProps | undefined,
  never,
  FileInfo[]
> = async (filterProps, requestConfig) => {
  try {
    const params = cleanObject({
      sort: filterProps?.sort,
      sortDirection: filterProps?.sortDirection,
      filename: filterProps?.filename,
      filetype: filterProps?.fileType,
    });
    const response = await axiosInstance.get(getUrl('fileList'), {
      ...requestConfig,
      params,
    });
    return parseAPIResponse(response, (files: FileInfo[]) =>
      files.map(parseFileInfoFromAPI)
    );
  } catch (error) {
    return catchError(error as Error | AxiosError);
  }
};

export type FileTypesResponse = {
  fileType: string;
  fileTypeTabLabel: string;
};

export const getFileTypes: GenericAPIRequestMethod<
  never,
  never,
  FileTypesResponse[]
> = async (requestConfig) => {
  const response = await axiosInstance.get(getUrl('fileTypeList'), {
    ...requestConfig,
  });
  return parseAPIResponse(response, (fileExtensionsAndTabs: string[][]) =>
    fileExtensionsAndTabs.map(
      (fileExtensionAndTab) =>
        ({
          fileType: fileExtensionAndTab[0],
          fileTypeTabLabel: fileExtensionAndTab[1],
        }) as unknown as FileTypesResponse
    )
  );
};

export async function downloadFile(
  file: FileInfo,
  progressCallback?: ProgressCallback
): Promise<APIResponse<string | null>> {
  try {
    const response = await axiosInstance.get(
      getUrl('fileDownload', { id: file.uuid }),
      {
        onDownloadProgress: progressCallback,
        responseType: 'blob',
      }
    );
    return parseAPIResponse(response, (body: BlobPart) => {
      const url = window.URL.createObjectURL(new Blob([body]));
      return url;
    });
  } catch (error) {
    return catchError(error as Error | AxiosError);
  }
}
