import { useEffect, useState } from 'react';

import { useHeaderTitle } from 'context/TitleContext';

export const useTitle = (title: string): void => {
  useEffect(() => {
    document.title = title;
  }, [title]);
};

export const usePageAndHeaderTitle = (
  pageTitle: string,
  headerTitle?: string
): void => {
  const { setHeaderTitle } = useHeaderTitle();

  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  const toolbarTitle = headerTitle ?? pageTitle;

  useEffect(() => {
    setHeaderTitle?.(toolbarTitle);
  }, [toolbarTitle, setHeaderTitle]);
};

export const useInitialRender = (): boolean => {
  const [isInitialRender, setInitialRender] = useState(true);

  useEffect(() => {
    setInitialRender(false);
  }, []);

  return isInitialRender;
};
