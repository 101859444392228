export type RouteUrl = string;

export type RouteQueryParams = {
  [key in (typeof AllowedParams)[number]]?: string;
};

export type RouteReturnType = ReturnType<typeof getRoutes>;

const AllowedParams = ['id'];

/**
 * Returns the routes for the application. Define the routes here.
 *
 * @param params - Optional query parameters for the routes.
 * @returns The routes for the application.
 */
const getRoutes = (params: RouteQueryParams | undefined) => ({
  analysisAffinityScreening: `/analyses/affinity-screening/${params?.id || ':id'}/`,
  analysisAffinityScreeningBindingAffinities: `/analyses/affinity-screening/${params?.id || ':id'}/contained-binding-affinities/`,
  analysisAffinityScreeningCreate: '/analyses/affinity-screening/',
  analysisAffinityScreeningEdit: `/analyses/affinity-screening/${params?.id || ':id'}/`,
  analysisAffinityScreeningExcelExport: `/analyses/affinity-screening/${params?.id || ':id'}/excel-report/`,
  analysisAffinityScreeningList: '/analyses/affinity-screening/',

  analysisBindingAffinity: `/analyses/binding-affinity/${params?.id || ':id'}/`,
  analysisBindingAffinityCreate: '/analyses/binding-affinity/',
  analysisBindingAffinityEdit: `/analyses/binding-affinity/${params?.id || ':id'}/`,
  analysisBindingAffinityList: '/analyses/binding-affinity/',

  analysisCoavalentKinetics: `/analyses/covalent-kinetics/${params?.id || ':id'}/`,
  analysisCoavalentKineticsCreate: '/analyses/covalent-kinetics/',
  analysisCoavalentKineticsCSVExport: `/analyses/covalent-kinetics/${params?.id || ':id'}/csv-export/`,
  analysisCoavalentKineticsEdit: `/analyses/covalent-kinetics/${params?.id || ':id'}/`,
  analysisCoavalentKineticsList: '/analyses/covalent-kinetics/',

  basePath: '/',

  experiment: `/experiments/${params?.id || ':id'}/`,
  experimentList: '/experiments/',
  experimentTypeList: '/experiments/experiment-types/',
  experimentStatusList: '/experiments/experiment-statuses/',
  experimentUpdate: `/experiments/${params?.id || ':id'}/`,

  experimentAffinityScreening: `/experiments/affinity-screening/${params?.id || ':id'}/`,
  experimentBindingAffinity: `/experiments/binding-affinity/${params?.id || ':id'}/`,

  file: `/files/${params?.id || ':id'}/`,
  fileDownload: `/files/${params?.id || ':id'}/download/`,
  fileList: '/files/',
  fileTypeList: '/files/filetypes/',
  fileUpload: '/files/',

  frontdoor: '/auth/frontdoor/',

  logging: '/logging/',

  userMe: '/users/me/',
  users: '/users/',
  userToken: '/users/token/',
});

/**
 * Validates the URL route and parameters.
 *
 * @param route - The URL route.
 * @param name - The name of the route.
 * @param params - The route query parameters.
 * @throws {Error} If the route contains invalid parameters or if any required parameter is missing.
 */
const validateUrl = (
  route: string,
  name: keyof ReturnType<keyof typeof getRoutes>,
  params?: RouteQueryParams
) => {
  const missingParams = Object.keys(params || {}).filter(
    (param) => !AllowedParams.includes(param)
  );

  if (!route.startsWith('/')) {
    throw new Error(`Route ${name as string} should start with a slash`);
  }

  if (!route.endsWith('/')) {
    throw new Error(`Route ${name as string} should end with a slash`);
  }

  if (missingParams.length > 0) {
    throw new Error(
      `${name as string} contains the following invalid parameters: ${missingParams.join(', ')}`
    );
  }

  for (const param of AllowedParams) {
    if (route.includes(`:${param}`) && !params?.[param]) {
      throw new Error(`Missing parameter ${param} for route ${name as string}`);
    }
  }
};

/**
 * Returns the URL for a given route name and optional query parameters.
 * @param name - The name of the route.
 * @param params - Optional query parameters for the route.
 * @returns The URL for the specified route.
 * @throws Error if the route name is not found or if the route is missing a parameter.
 */
export const getUrl = (
  name: keyof RouteReturnType,
  params?: RouteQueryParams
) => {
  const routes = getRoutes(params);

  // Typescript will throw an error if the route name is not found
  // This is just to make sure we receive an error at runtime
  if (!routes[name]) {
    throw new Error(`Route not found: ${name as string}`);
  }

  const route = routes[name];

  validateUrl(route, name, params);

  return `${route}`;
};

/**
 * The exported RequestBuilder object is intended to allow
 * the unit tests to cover the contained logic.
 *
 * It is NOT intended to be used inside non-test classes.
 * Instead, use the directly exported functions.
 *
 * @returns {Object} The RequestBuilder object
 */
export const RequestBuilder = {
  getRoutes,
  getUrl,
  validateUrl,
};
