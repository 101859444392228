import { createSelector } from '@reduxjs/toolkit';

import { RequestStatus } from 'reducers/utils';
import { RootState } from 'store/store';

export const selectFileTypeTabs = createSelector(
  (state: RootState) => state.fileTypeTabs.requestStatus,
  (state: RootState) => state.fileTypeTabs.fileTypeTabs,
  (requestStatus, tabs) => {
    return {
      requestStatus,
      fileTypeTabs:
        requestStatus !== RequestStatus.SUCCEEDED ? undefined : tabs,
    };
  }
);
