import { Theme } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  rootWrapper: {
    overflow: 'hidden',
  },
  root: {
    color: theme.palette.text.primary,
    width: `max(100%, ${
      theme.sizing.sidebarWidthOpen - theme.sizing.scrollbarThickness
    }px)`,
    padding: 0,
    justifyContent: 'center',
    height: 36,
    '&:hover ': {
      '$menuItemListing > svg': {
        color: theme.palette.primary.light,
      },
      '$menuItemListing > .MuiTypography-body1': {
        color: theme.palette.primary.light,
      },
      '$menuItemListing > .MuiTypography-body2': {
        color: theme.palette.primary.light,
      },
    },
    transition: theme.transitions.create(['height'], {
      duration: theme.timing.sidebarAnimation,
    }),
  },

  rootSidebarOpen: {
    height: 80,
  },
  rootEditMode: {
    height: 106,
  },

  // ListItem content
  content: {
    display: 'grid',
    width: '100%',
    height: '100%',
    gridTemplateColumns: `${theme.sizing.sidebarWidthClosed}px 16px auto auto`,
    gridTemplateRows: 'min(53px, 100%) 1.875rem',
    transition: theme.transitions.create(['height'], {
      duration: theme.timing.sidebarAnimation,
    }),
  },

  contentSidebarOpen: {
    gridTemplateColumns: `${theme.sizing.sidebarWidthClosed}px 16px auto auto`,
    gridTemplateRows: 'min(53px, 100%) 1.875rem',
  },

  contentSidebarOpenEditMode: {
    gridTemplateColumns: `${theme.sizing.sidebarWidthClosed}px 16px auto 12.5px`,
    gridTemplateRows: 'min(53px, 100%) 2.8125rem',
  },

  // AnalysisTitle
  analysisTitle: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    padding: '4px 0 5px 0',
    height: 32,
    width: '100%',
    borderBottom: '1px solid rgba(0, 0, 0, 0.0)',
    transition: theme.transitions.create(['border-bottom'], {
      duration: theme.transitions.duration.short,
    }),
  },
  analysisTitleActive: {
    '&:hover': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.34)',
    },
  },

  // Icons
  icon: {
    color: theme.palette.text.primary,
  },

  // Dropdown animation
  dropdown: {
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    transform: 'rotateZ(-90deg)',
  },

  dropdownClosed: {
    transform: 'rotateZ(0deg)',
  },

  // Sub Item (Experiments) styling
  subItemRoot: {
    height: 36,
    padding: '0 0.5rem 0 0.25rem',
    color: theme.palette.text.primary,
    '& .MuiTypography-body1': {
      overflowWrap: 'break-word',
    },
    '& .MuiTypography-body2': {
      overflowWrap: 'break-word',
    },
  },

  subItemActive: {
    color: theme.palette.primary.main,
  },

  subItemWrapper: {
    padding: '4px 0 4px 68px',
  },

  // Menu Icon Styling
  optionsMenuIcon: {
    marginRight: '8px',
  },

  // Rename modal
  renameModalRoot: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    padding: '1rem',
  },

  renameModalContent: {
    marginBottom: '3rem',
  },

  renameModalButtonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '0.875rem',
  },

  renameModalRenameButton: {
    color: theme.palette.primary.main,
  },

  // Dropdown icon
  dropdownIcon: {
    opacity: 1,
    transition: theme.transitions.create(['opacity'], {
      duration: theme.timing.sidebarAnimation * 0.4,
    }),
  },

  dropdownIconHidden: {
    opacity: 0,
    transition: theme.transitions.create(['opacity'], {
      delay: theme.timing.sidebarAnimation * 0.4,
      duration: theme.timing.sidebarAnimation,
    }),
  },
}));
