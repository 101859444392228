// Error constants for the API-calls/hooks

// Analyses
export const BindingAffinityAnalysisErrorTitle = 'BindingAffinityAnalysis';
export const AnalysisRetrievalBackendError =
  'The backend server did not respond while retrieving the analysis.';
export const AnalysisRetrievalUnknownError =
  'An unexpected error occurred while trying to retrieve the analysis.';

export const AnalysisListErrorTitle = 'Analysis List';
export const AnalysisListRetrievalBackendError =
  'The backend server did not respond while retrieving the list of analyses';
export const AnalysisListRetrievalUnknownError =
  'An unexpected error occurred while trying to retrieve the analysis list';

// Experiments
export const ExperimentListErrorTitle = 'Experiment List';
export const ExperimentListRetrievalBackendError =
  'The backend server did not respond while retrieving the list of experiments.';
export const ExperimentListRetrievalUnknownError =
  'A unexpected error occurred while trying to retrieve the experiment list.';

export const ExperimentStatusErrorTitle = 'Experiment Types';
export const ExperimentStatusRetrievalBackendError =
  'The backend server did not respond with the list of supported experiment types.';
export const ExperimentStatusRetrievalUnknownError =
  'A not-expected error occurred when requesting the list of supported experiment types from the backend ' +
  'server.';

export const ExperimentStatusesErrorTitle = 'Experiment Statuses';
export const ExperimentStatusesRetrievalBackendError =
  'The backend server did not respond with the list of supported experiment statuses.';
export const ExperimentStatusesRetrievalUnknownError =
  'A non-expected error occurred when requesting the list of supported experiment statuses from the backend ' +
  'server.';

export const ExperimentInfoErrorTitle = 'Could not get experiment info';
export const ExperimentInfoRetrievalBackendError =
  'The backend server did not respond, while updating the experiment annotation.';
export const ExperimentInfoRetrievalUnknownError =
  'An unexpected error occurred while retreiving an experiment from the backend server.';

export const UpdateExperimentInfoErrorTitle =
  'Could not change experiment info';
export const UpdateExperimentInfoRetrievalBackendError =
  'The backend server did not respond, while updating the experiment info.';
export const UpdateExperimentInfoRetrievalUnknownError =
  'An unexpected error occurred while updating the experiment info.';
