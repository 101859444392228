import React, { useCallback } from 'react';

import { NotificationId } from 'types/notifications';

import { removeNotification } from 'reducers/notifications/slice';
import { closeNotificationDrawer } from 'reducers/notifications/thunk';
import { useAppDispatch, useAppSelector } from 'store/store';

import {
  Box,
  Drawer,
  IconButton,
  PaperProps,
  Typography,
  useMediaQuery,
} from '@mui/material';

import ErrorMessage from 'components/ErrorMessage/ErrorMessage';

import { ArrowLeftIcon } from './icons';

import { useStyles } from './styles';
import { theme } from 'theme/theme';

export function NotificationDrawer(): JSX.Element {
  const { classes, cx } = useStyles();
  const mobileMode = useMediaQuery(theme.breakpoints.down('md'));

  const dispatch = useAppDispatch();
  const { notificationDrawerOpen, notifications } = useAppSelector(
    (state) => state.notifications
  );

  const handleClose = useCallback(() => {
    dispatch(closeNotificationDrawer());
  }, [dispatch]);

  const handleNotificationRemoval = useCallback(
    (id: NotificationId): void => {
      dispatch(removeNotification(id));
    },
    [dispatch]
  );

  return (
    <Drawer
      anchor="right"
      variant="permanent"
      open={notificationDrawerOpen}
      onClose={handleClose}
      classes={{
        paper: cx({
          [classes.drawer]: true,
          [classes.drawerOpen]: notificationDrawerOpen,
        }),
      }}
      PaperProps={
        {
          'data-testid': 'notification-drawer',
        } as unknown as Partial<PaperProps>
      }
    >
      <Box display="flex" flexDirection="column" height="100%">
        {/* Header */}
        <Box display="flex" className={classes.title}>
          <Box display="flex">
            {mobileMode && (
              <Box my="auto" paddingLeft="5px" paddingRight="12px">
                <IconButton
                  onClick={handleClose}
                  data-testid="notification-drawer-close-button"
                  size="large"
                >
                  <ArrowLeftIcon sx={{ fontSize: 22 }} />
                </IconButton>
              </Box>
            )}
            <Box my="auto" p={mobileMode ? 0 : 2}>
              <Typography variant="h5">
                <Box
                  fontWeight="fontWeightMedium"
                  lineHeight="1em"
                  component="span"
                  color="#0000008A"
                >
                  Notifications
                </Box>
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* Content */}
        <Box className={classes.content}>
          {notifications.map((notification, i) => (
            <Box
              py={0.5}
              key={`notification_${notification.uuid}`}
              data-testid={`notification-${i}`}
            >
              <ErrorMessage
                message={notification.message}
                title={notification.title}
                type={notification.type}
                showBadge={!notification.read}
                onClose={() => handleNotificationRemoval(notification.uuid)}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Drawer>
  );
}

export default React.memo(NotificationDrawer);
