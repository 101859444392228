import React, { useCallback } from 'react';

import {
  Badge,
  Box,
  Button,
  ClickAwayListener,
  Popover,
  Typography,
} from '@mui/material';

import DataTableFilterForm from 'components/DataTableAccessories/DataTableFilter/components/DataTableFilterForm/DataTableFilterForm';

import { FilterIcon } from './icons';

import { useStyles } from './styles';

type DataTableFilterProps = {
  container?: () => Element | null;
  children: React.ReactNode;
  onReset: () => void;
  active?: boolean;
};

function DataTableFilter(props: DataTableFilterProps) {
  const { classes } = useStyles();
  const { active } = props;

  const anchorEl = React.useRef(null);
  const [isFilterOpen, setFilterOpen] = React.useState(false);

  const handleClick = React.useCallback(() => {
    setFilterOpen((open) => !open);
  }, []);

  const handleClose = useCallback(() => {
    setFilterOpen(false);
  }, []);

  return (
    <>
      <Button
        variant="text"
        color="primary"
        startIcon={
          <Badge
            color="primary"
            variant="dot"
            invisible={!active}
            componentsProps={
              {
                badge: { 'data-testid': 'table-filter-icon-badge' },
              } as any
            }
          >
            <FilterIcon sx={{ fontSize: 20 }} data-testid="table-filter-icon" />
          </Badge>
        }
        onClick={handleClick}
        ref={anchorEl}
        className={classes.root}
        aria-label="open filter"
        data-testid="table-filter-button"
      >
        <Typography variant="body2">
          <Box component="span" fontWeight={'fontWeightMedium'}>
            Filter
          </Box>
        </Typography>
      </Button>
      <Popover
        className={classes.filterPopOver}
        open={isFilterOpen}
        anchorEl={anchorEl.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
        container={props.container}
        disablePortal
      >
        <ClickAwayListener onClickAway={handleClose}>
          <DataTableFilterForm onClose={handleClose} onReset={props.onReset}>
            {props.children}
          </DataTableFilterForm>
        </ClickAwayListener>
      </Popover>
    </>
  );
}

export default DataTableFilter;
