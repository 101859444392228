import React, { useMemo } from 'react';

import {
  DataTablePageChangeCallback,
  DataTableSort,
  RowSelectionCallback,
} from 'components/DataTable/types/types';
import { ExperimentInfo } from 'types/APITypes/APITypes';

import { FileTypeTabLabel } from 'reducers/fileTypeTabs/fileTypeTabsSlice';

import { rowsPerPageIds } from 'utils/hooks/tableIds';
import { useGenericRowIdMapper } from 'utils/table/table';

import DataTable from '../DataTable/DataTable';
import {
  createBindingExperimentTableColumns,
  createStabilityMeasurementTableColumns,
} from './columns';

type ExperimentTableProps = {
  isLoading?: boolean;
  experiments: ExperimentInfo[];
  totalExperiments: number;
  selectedFileTypeTab?: FileTypeTabLabel;

  experimentSelection?: number[];
  experimentSelectionCount?: number;

  onExperimentSelectionChange: RowSelectionCallback<ExperimentInfo, string>;
  onExperimentSelectionClear: () => void;
  onPageChange: DataTablePageChangeCallback;
  currentPage?: number;
  onExperimentSortChange: (sort: DataTableSort) => void;

  // Show only selected:
  enableShowSelectedOnly?: boolean;
  showSelectedOnly?: boolean;
  onShowSelectedOnlyChange?: (event: any, show: boolean) => void;
};

function ExperimentTable(props: ExperimentTableProps) {
  const { experiments } = props;
  const rowIdMapper = useGenericRowIdMapper();

  const columns = useMemo(() => {
    return props.selectedFileTypeTab === FileTypeTabLabel.Stability
      ? createStabilityMeasurementTableColumns()
      : createBindingExperimentTableColumns();
  }, [props.selectedFileTypeTab]);

  return (
    <DataTable
      columns={columns}
      rowsPerPageId={rowsPerPageIds.experiment}
      isLoading={props.isLoading}
      rows={experiments}
      getRowId={rowIdMapper}
      totalRows={props.totalExperiments}
      rowSelection={props.experimentSelection}
      rowSelectionCount={props.experimentSelectionCount ?? 0}
      onRowSelectionChange={props.onExperimentSelectionChange}
      onAllRowsDeselect={props.onExperimentSelectionClear}
      onColumnSortChange={props.onExperimentSortChange}
      selectionType={'experiment'}
      pagination
      onPageChange={props.onPageChange}
      currentPage={props.currentPage}
      enableShowSelectedOnly={props.enableShowSelectedOnly}
      showSelectedOnly={props.showSelectedOnly}
      onShowSelectedOnlyChange={props.onShowSelectedOnlyChange}
    />
  );
}

ExperimentTable.defaultProps = {
  isLoading: false,
};

export default ExperimentTable;
