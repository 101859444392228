// There's no need for the schema here, since it's all validated
// by vite-plugin-validate-env before starting or building the app

export enum EnvType {
  Dev = 'Dev',
  Stage = 'Stage',
  Prod = 'Prod',
}

const config = {
  apiBaseUrl: import.meta.env.VITE_API_BASEURL,
  envType: import.meta.env.VITE_ENV_TYPE,
  localLogLevel: import.meta.env.VITE_LOGLEVEL,
  serverLogLevel: import.meta.env.VITE_API_LOGLEVEL,
};

export default config;
