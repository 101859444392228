import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
  useRef,
} from 'react';

import {
  GenericAPIRequestMethod,
  PaginatedList,
} from 'types/APITypes/APITypes';
import { PaginationParams } from 'types/QueryParams';

import { getCancelTokenSource } from 'api/CASAPI';
import axios from 'axios';

import log from 'loglevel';

// Constants

export const defaultPaginationSize = {
  offset: 0,
  limit: 10,
};

// Pagination Query helpers

type PaginationOptions<T extends PaginationParams> = {
  currentPage?: number;
  rowsPerPage?: number;
  setQueryParams?: Dispatch<SetStateAction<T>>;
};

export const usePaginationQuery = <T extends PaginationParams>(
  options: PaginationOptions<T>
) => {
  const { setQueryParams } = options;
  const [currentPage, setCurrentPage] = useState(
    options.currentPage ?? defaultPaginationSize.offset
  );
  const [rowsPerPage, setRowsPerPage] = useState(
    options.rowsPerPage ?? defaultPaginationSize.limit
  );

  const handlePageChange = useCallback(
    (page: number, update?: Partial<T>) => {
      setCurrentPage(page);
      setQueryParams?.((params) => ({
        ...params,
        ...update,
        offset: page * rowsPerPage,
      }));
    },
    [rowsPerPage, setQueryParams]
  );

  const handleRowsPerPageChange = useCallback(
    (newRowsPerPage: number, update?: Partial<T>) => {
      // Skip if the operation is a noop
      if (rowsPerPage === newRowsPerPage && !update) return;
      setCurrentPage(0);
      setRowsPerPage(newRowsPerPage);
      setQueryParams?.((params) => ({
        ...params,
        ...update,
        offset: 0,
        limit: newRowsPerPage,
      }));
    },
    [setQueryParams, rowsPerPage]
  );

  return {
    currentPage,
    rowsPerPage,
    handlePageChange,
    handleRowsPerPageChange,
  };
};

// Pagination hook helpers
type usePaginatedListOptions<T, QP extends Object> = {
  onBackendError?: () => void;
  onUnexpectedError?: () => void;
  getElements?: GenericAPIRequestMethod<QP, never, PaginatedList<T>>;
  // Extra state/anything to watch for changes, which should reload the list
  watch?: any;
};

type usePaginatedListReturn<T> = {
  total: number;
  elements: T[];
  loading: boolean;
};

export const usePaginatedListWithQueryParams = <T, QP extends {}>(
  options: usePaginatedListOptions<T, QP> = {},
  queryParams: QP
): usePaginatedListReturn<T> => {
  const { onBackendError, onUnexpectedError, getElements, watch } = options;
  const isMountedRef = useRef(false);

  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [elements, setElements] = useState<T[]>([]);

  useEffect(() => {
    if (!getElements) return;
    isMountedRef.current = true;

    const cancelTokenSource = getCancelTokenSource();

    setLoading(true);
    getElements(queryParams, { cancelToken: cancelTokenSource.token })
      .then(({ status, body }) => {
        if (!isMountedRef.current) return;
        setLoading(false);

        if (status !== 200) {
          onBackendError?.();
          return;
        }

        if (body == null) {
          return;
        }
        setElements(body.data);
        setTotal(body.total);
      })
      .catch((err) => {
        if (axios.isCancel(err)) return;
        if (!isMountedRef.current) return;
        setLoading(false);
        onUnexpectedError?.();
        log.error(err);
      });

    return () => {
      isMountedRef.current = false;
      cancelTokenSource.cancel();
    };
  }, [queryParams, getElements, onBackendError, onUnexpectedError, watch]);

  return {
    total,
    elements,
    loading,
  };
};

// Pagination Query Initial State helper

export type QueryParamsWithoutPagination<T extends PaginationParams> = Omit<
  T,
  'offset' | 'limit'
>;

// creates the initial state for a paginated query
export const usePaginationQueryInitialStateGenerator = <
  T extends PaginationParams,
>(
  state: QueryParamsWithoutPagination<T>,
  rowsPerPage?: number
): (() => T) => {
  return () =>
    ({
      ...state,
      offset: 0,
      limit: rowsPerPage ?? defaultPaginationSize.limit,
    }) as T;
};
