import React, { useCallback, useState } from 'react';

import {
  DataTableColumn_t,
  DataTableRowBase,
  RowSelectionCallback,
  DataTableSort,
  DataTablePageChangeCallback,
  RowIDMapper,
  RowClickCallback,
} from './types/types';

import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import ScrollContainer from 'components/ScrollContainer/ScrollContainer';
import Loader from 'components/loader/loader';

import { useStyles } from './styles';

import { RowsPerPageId } from 'utils/hooks/tableIds';

import DataTableRow from './components/DataTableRow';
import DataTableToolbarSelect from './components/DataTableToolbarSelect/DataTableToolbarSelect';
import UniversalTableHeader from './components/UniversalTableHeader';
import TableErrorContextWrapper from './context/TableErrorContext';

type DataTableProps<TRow extends DataTableRowBase, TRowId> = {
  columns: DataTableColumn_t<TRow, TRowId>[];
  rows: TRow[];
  getRowId: RowIDMapper<TRow, TRowId>;
  totalRows?: number;

  rowSelection?: number[];
  rowSelectionCount: number;
  isLoading?: boolean;
  onRowSelectionChange: RowSelectionCallback<TRow, TRowId>;
  onRowClick?: RowClickCallback<TRow, TRowId>;
  onAllRowsDeselect?: () => void;
  onColumnSortChange: (sort: DataTableSort) => void;
  selectionType?: string;

  // Sorting
  intialSorting?: DataTableSort;

  // pagination
  pagination?: boolean;
  onPageChange?: DataTablePageChangeCallback;
  currentPage?: number;

  // split-view
  openedRow?: TRowId;

  // configurations
  hideCheckbox?: boolean;

  // Show only selected:
  enableShowSelectedOnly?: boolean;
  showSelectedOnly?: boolean;
  onShowSelectedOnlyChange?: (event: any, show: boolean) => void;

  rowsPerPageId?: RowsPerPageId;
};

function DataTable<TRow extends DataTableRowBase, TRowId extends string>(
  props: DataTableProps<TRow, TRowId>
) {
  const {
    onColumnSortChange,
    onRowSelectionChange,
    onAllRowsDeselect,
    rows,
    getRowId,
    rowSelectionCount,
  } = props;
  const { classes, cx } = useStyles();

  const [columnSort, setColumnSort] = useState<DataTableSort>(
    props.intialSorting ?? {}
  );

  const handleColumnSortChange = useCallback(
    (sortDirection: DataTableSort) => {
      onColumnSortChange(sortDirection);
      setColumnSort(sortDirection);
    },
    [onColumnSortChange]
  );

  // Checkbox callbacks
  const handleTotalSelectionChange = useCallback(() => {
    // Check if anything is selected:
    if (rowSelectionCount > 0) {
      onAllRowsDeselect?.();
    } else {
      // Select all currently shown rows
      for (const row of rows) {
        const id = getRowId(row);
        onRowSelectionChange(id, row, true);
      }
    }
  }, [
    onRowSelectionChange,
    onAllRowsDeselect,
    rowSelectionCount,
    rows,
    getRowId,
  ]);

  const atLeastOneRowSelected = props.rowSelectionCount > 0;

  const hasToolbar =
    !props.hideCheckbox ||
    props.pagination !== undefined ||
    props.totalRows !== undefined;

  return (
    <TableErrorContextWrapper>
      <Box display="flex" flexDirection="column" height="100%">
        <TableContainer className={classes.tableContainer}>
          <ScrollContainer>
            <Table className={classes.tableRoot} size={'small'}>
              <TableHead>
                <TableRow className={classes.tableHeader}>
                  {/* Checkbox */}
                  {!props.hideCheckbox && (
                    <TableCell
                      className={cx(
                        classes.tableCellSticky,
                        classes.tableCellStickyTop,
                        'checkbox'
                      )}
                      variant="head"
                    >
                      <Checkbox
                        indeterminate={atLeastOneRowSelected}
                        checked={atLeastOneRowSelected}
                        onChange={handleTotalSelectionChange}
                        sx={{
                          pt: '7px',
                          pb: '7px',
                          '& .MuiSvgIcon-root': { fontSize: 19 },
                        }}
                      />
                    </TableCell>
                  )}

                  {/* Columns */}
                  {props.columns.map((column, columnIndex) => (
                    <UniversalTableHeader
                      key={column.columnId}
                      column={column}
                      columnIndex={columnIndex}
                      onSortChange={handleColumnSortChange}
                      sorting={columnSort}
                    />
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row: TRow, rowIndex: number) => (
                  <DataTableRow
                    key={getRowId(row)}
                    columns={props.columns}
                    getRowId={getRowId}
                    selected={props.rowSelection?.includes(rowIndex) ?? false}
                    row={row}
                    rowIndex={rowIndex}
                    onRowSelect={props.onRowSelectionChange}
                    onRowClick={props.onRowClick}
                    hideCheckbox={props.hideCheckbox}
                    opened={props.openedRow === getRowId(row)}
                  />
                ))}
              </TableBody>
            </Table>
          </ScrollContainer>
          {/* Overlay the Loader */}
          {props.isLoading && (
            <Box data-testid="loader-wrapper" className={classes.loaderOverlay}>
              <Box m="auto">
                <Loader />
              </Box>
            </Box>
          )}
        </TableContainer>
        {hasToolbar && props.rowsPerPageId && (
          <DataTableToolbarSelect
            numSelectedRows={rowSelectionCount}
            selectionType={
              props.selectionType ? props.selectionType : undefined
            }
            pagination={props.pagination}
            totalRows={props.totalRows}
            page={props.currentPage}
            onPageChange={props.onPageChange}
            disableSelection={props.hideCheckbox}
            enableShowSelectedOnly={props.enableShowSelectedOnly}
            showSelectedOnly={props.showSelectedOnly}
            rowsPerPageId={props.rowsPerPageId}
            onShowSelectedOnlyChange={props.onShowSelectedOnlyChange}
          />
        )}
      </Box>
    </TableErrorContextWrapper>
  );
}

DataTable.defaultProps = {
  isLoading: false,
};

export default React.memo(DataTable) as unknown as typeof DataTable;
