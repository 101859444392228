import { useCallback, useEffect, useRef, useState } from 'react';

import { DataTableSort } from 'components/DataTable/types/types';
import {
  PaginatedQueryParams,
  SortFilterSearchQueryParams,
} from 'types/QueryParams';

import { useAppSelector } from 'store/store';

import {
  QueryParamsWithoutPagination,
  usePaginationQuery,
  usePaginationQueryInitialStateGenerator,
} from 'utils/pagination/pagination';

import { RowsPerPageId, defaultRowsPerPage } from './tableIds';

export type QueryParams<TFilter> = QueryParamsWithoutPagination<
  PaginatedQueryParams<SortFilterSearchQueryParams<TFilter>>
> & {
  rowsPerPageId: RowsPerPageId;
};

export function useQueryParams<TFilter>(
  initialQueryParams: QueryParams<TFilter>
) {
  type TQueryParams = PaginatedQueryParams<
    SortFilterSearchQueryParams<TFilter>
  >;
  // Use the global Table settings:
  const { rowsPerPage: rowsPerPageConfig } = useAppSelector(
    (state) => state.tableSettings
  );
  const rowsPerPage =
    rowsPerPageConfig[initialQueryParams.rowsPerPageId] ?? defaultRowsPerPage;
  const initialQueryGenerator = usePaginationQueryInitialStateGenerator(
    initialQueryParams,
    rowsPerPage
  );

  const [queryParams, setQueryParams] = useState<TQueryParams>(
    initialQueryGenerator()
  );
  const { currentPage, handlePageChange, handleRowsPerPageChange } =
    usePaginationQuery({
      setQueryParams: setQueryParams,
      rowsPerPage,
    });

  const handleSortChange = useCallback((sorting: DataTableSort) => {
    setQueryParams((params) => ({
      ...params,
      sorting,
    }));
  }, []);

  const handleFilterChange = useCallback(
    (filtering: TFilter) => {
      handlePageChange(0, { filtering });
    },
    [handlePageChange]
  );

  const handleSearchChange = useCallback(
    (searchValue?: string) => {
      handlePageChange(0, { searchValue });
    },
    [handlePageChange]
  );

  // Instead of providing the handleRowsPerPageChange, call it via useEffect
  const isInitialRender = useRef(true);
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    handleRowsPerPageChange(rowsPerPage);
  }, [handleRowsPerPageChange, rowsPerPage]);

  return [
    queryParams,
    handleSortChange,
    handleFilterChange,
    handleSearchChange,
    handlePageChange,
    currentPage,
  ] as const;
}
