// Utils
import {
  MouseEventHandler,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { SCROLLBAR_ARROW_PADDING } from './styles';

import { useInitialRender } from 'utils/utils';

const MIN_SCROLL_THUMB_SIZE = 20;
const USER_SCROLL_RESET_TIME = 500;

type ScrollbarMappingReturn = {
  scrollbarSize: { x: number; y: number };
  scrollbarThumbSize: { x: number; y: number };
  scrollbarOffsets: { x: number; y: number };
  scrollbarOffsetsMax: { x: number; y: number };
  scrollable: { x: boolean; y: boolean };
  containerRef: RefObject<HTMLDivElement>;
  handleThumbMouseDownX: MouseEventHandler<HTMLDivElement>;
  handleThumbMouseDownY: MouseEventHandler<HTMLDivElement>;
  userScrolling: boolean;
};

const ensureNumberIsUsable = (val: number, defaultValue?: number): number => {
  if (Number.isNaN(val)) return defaultValue ?? 0;
  return val;
};

type Number2D = {
  x: number;
  y: number;
};

type InternalState = {
  // limits for the scroll-tumb - same as the state synced to the outside
  maxThumbOffset: Number2D;

  // State for dragging
  dragThumbPosition: Partial<Number2D>;

  // State related to switching between mouse-scrolling and touch/bar scrolling
  scrollResetInterval: NodeJS.Timeout | null;
  userScrolling: boolean;
};

type useScrollbarMappingOptions = {
  onScrollableChanged?: (scrollable: { x: boolean; y: boolean }) => void;
};

export const useScrollbarMapping = (
  options: useScrollbarMappingOptions
): ScrollbarMappingReturn => {
  const { onScrollableChanged } = options;
  const containerRef = useRef<HTMLDivElement>(null);

  const [scrollable, setScrollable] = useState({ x: false, y: false });
  const [maxThumbOffset, setMaxThumbOffset] = useState<Number2D>({
    x: 0,
    y: 0,
  });

  // Calculated state for sizing + positioning
  const [scrollbarSize, setScrollbarSize] = useState<Number2D>({ x: 0, y: 0 });
  const [scrollbarThumbSize, setScrollbarThumbSize] = useState<Number2D>({
    x: 0,
    y: 0,
  });
  const [scrollbarOffsets, setScrollbarOffsets] = useState<Number2D>({
    x: SCROLLBAR_ARROW_PADDING,
    y: SCROLLBAR_ARROW_PADDING,
  });

  // Fix for a browser-scrolling issue
  // Once the user has finished scrolling, the scroll-snap fires immediately
  // This is not a problem for manually moving the scrollbar or element (via Touch)
  // But poses an issue for mouse-scrolling, as it works in small steps
  // So we need to disable snapping while scrolling - this is not as much of a problem
  // Since the only time, that scroll-snapping is now active is for TouchScreen devices
  const [userScrolling, setUserScrolling] = useState(false);

  // Internal State, handled with Refs, so it doesn't cause any re-renders
  const innerStateRef = useRef<InternalState>({
    maxThumbOffset: { x: 0, y: 0 },
    dragThumbPosition: {},
    scrollResetInterval: null,
    userScrolling: false,
  });

  // Helper functions to update state:
  const updateScrollbarPercentageAndOffsets = useCallback(
    (scrollPercentage: Partial<Number2D>) => {
      const maxThumbOffset = innerStateRef.current.maxThumbOffset;
      if (scrollPercentage.x) {
        const offsetX =
          SCROLLBAR_ARROW_PADDING + scrollPercentage.x * maxThumbOffset.x;
        setScrollbarOffsets((offsets) => ({
          ...offsets,
          x: offsetX,
        }));
      }

      if (scrollPercentage.y) {
        const offsetY =
          SCROLLBAR_ARROW_PADDING + scrollPercentage.y * maxThumbOffset.y;
        setScrollbarOffsets((offsets) => ({
          ...offsets,
          y: offsetY,
        }));
      }

      // Update the container's scrollState, but only if the user is scrolling
      if (innerStateRef.current.userScrolling) {
        const scrollContainer = containerRef.current;
        if (!scrollContainer) return;

        if (scrollPercentage.x) {
          const { scrollWidth, clientWidth } = scrollContainer;
          scrollContainer.scrollLeft =
            (scrollWidth - clientWidth) * scrollPercentage.x;
        }

        if (scrollPercentage.y) {
          const { scrollHeight, clientHeight } = scrollContainer;
          scrollContainer.scrollTop =
            (scrollHeight - clientHeight) * scrollPercentage.y;
        }
      }
    },
    []
  );

  // Handle scroll-events, dispatched by either the browser or the mouse
  const handleScroll = useCallback(
    (e: Event) => {
      const scrollContainer = containerRef.current;
      if (!scrollContainer) return;

      // scrollTop is how much the container was scrolled down (in px)
      // scrollHeight is the container's content's size (in px)
      // offsetHeight is the container's size without any borders. (in px)
      const {
        scrollTop,
        scrollLeft,
        scrollWidth,
        scrollHeight,
        clientHeight,
        clientWidth,
      } = scrollContainer;

      // Although this shouldn't change, this can't be state
      // Otherwise it leads to an infinite update-chain
      // (useEffects sets it, which regenerates this callback, which calls useEffect again)
      const maxScrollX = scrollWidth - clientWidth;
      const maxScrollY = scrollHeight - clientHeight;

      // If both are zero, we can't calculate anything usable
      // So we can just return
      if (maxScrollX === 0 && maxScrollY === 0) return;

      // Calculate how much (in %) the container was scrolled down
      const scrollPercentageX = scrollLeft / maxScrollX;
      const scrollPercentageY = scrollTop / maxScrollY;

      updateScrollbarPercentageAndOffsets({
        x: ensureNumberIsUsable(scrollPercentageX, 0),
        y: ensureNumberIsUsable(scrollPercentageY, 0),
      });
    },
    [updateScrollbarPercentageAndOffsets]
  );

  const handleWheelEvent = useCallback(() => {
    if (innerStateRef.current.scrollResetInterval != null) {
      clearTimeout(innerStateRef.current.scrollResetInterval);
      innerStateRef.current.scrollResetInterval = null;
    }
    setUserScrolling(true);
    innerStateRef.current.userScrolling = true;

    innerStateRef.current.scrollResetInterval = setTimeout(() => {
      innerStateRef.current.scrollResetInterval = null;
      innerStateRef.current.userScrolling = false;
      setUserScrolling(false);
    }, USER_SCROLL_RESET_TIME);
  }, []);

  // Handlers for dragging the scrollbars by hand
  const handleDocumentMouseUp = useCallback(
    (e: MouseEvent) => {
      const dragThumbPosition = innerStateRef.current.dragThumbPosition;
      const draggingX = dragThumbPosition.x != null;
      const draggingY = dragThumbPosition.y != null;
      if (draggingX || draggingY) {
        e.preventDefault();
        e.stopPropagation();
        const delta = {
          x: dragThumbPosition.x != null ? e.clientX - dragThumbPosition.x : 0,
          y: dragThumbPosition.y != null ? e.clientY - dragThumbPosition.y : 0,
        };

        if (draggingX) {
          const scrollbarOffsetX =
            scrollbarOffsets.x + delta.x - SCROLLBAR_ARROW_PADDING;
          updateScrollbarPercentageAndOffsets({
            x: Math.max(0, Math.min(1, scrollbarOffsetX / maxThumbOffset.x)),
          });
        }

        if (draggingY) {
          const scrollbarOffsetY =
            scrollbarOffsets.y + delta.y - SCROLLBAR_ARROW_PADDING;
          updateScrollbarPercentageAndOffsets({
            y: Math.max(0, Math.min(1, scrollbarOffsetY / maxThumbOffset.y)),
          });
        }

        // Update drag position
        innerStateRef.current.dragThumbPosition = {};

        // Release scrolling back to the snap-behaviour
        setUserScrolling(false);
        innerStateRef.current.userScrolling = false;
      }
    },
    [scrollbarOffsets, maxThumbOffset, updateScrollbarPercentageAndOffsets]
  );

  const handleDocumentMouseMove = useCallback(
    (e: MouseEvent) => {
      const dragThumbPosition = innerStateRef.current.dragThumbPosition;
      const draggingX = dragThumbPosition.x != null;
      const draggingY = dragThumbPosition.y != null;
      if (draggingX || draggingY) {
        e.preventDefault();
        e.stopPropagation();

        // calculate the mouse-movement delta:
        const delta = {
          x: dragThumbPosition.x != null ? e.clientX - dragThumbPosition.x : 0,
          y: dragThumbPosition.y != null ? e.clientY - dragThumbPosition.y : 0,
        };

        if (draggingX) {
          const scrollbarOffsetX =
            scrollbarOffsets.x + delta.x - SCROLLBAR_ARROW_PADDING;
          updateScrollbarPercentageAndOffsets({
            x: Math.max(0, Math.min(1, scrollbarOffsetX / maxThumbOffset.x)),
          });
        }

        if (draggingY) {
          const scrollbarOffsetY =
            scrollbarOffsets.y + delta.y - SCROLLBAR_ARROW_PADDING;
          updateScrollbarPercentageAndOffsets({
            y: Math.max(0, Math.min(1, scrollbarOffsetY / maxThumbOffset.y)),
          });
        }

        // Update drag position
        innerStateRef.current.dragThumbPosition = {
          x: draggingX ? e.clientX : undefined,
          y: draggingY ? e.clientY : undefined,
        };
      }
    },
    [scrollbarOffsets, maxThumbOffset, updateScrollbarPercentageAndOffsets]
  );

  const handleThumbMouseDownX = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      innerStateRef.current.dragThumbPosition = { x: e.clientX };
      setUserScrolling(true);
      innerStateRef.current.userScrolling = true;
    },
    []
  );

  const handleThumbMouseDownY = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      innerStateRef.current.dragThumbPosition = { y: e.clientY };
      setUserScrolling(true);
      innerStateRef.current.userScrolling = true;
    },
    []
  );

  // Setup-function
  const handleScrollSetup = useCallback(() => {
    const scrollContainer = containerRef.current;
    if (!scrollContainer) return;
    const { clientHeight, scrollHeight, clientWidth, scrollWidth } =
      scrollContainer;

    const maxScrollX = scrollWidth - clientWidth;
    const maxScrollY = scrollHeight - clientHeight;

    const scrollableX = maxScrollX > 0;
    const scrollableY = maxScrollY > 0;

    setScrollable({
      x: scrollableX,
      y: scrollableY,
    });
    onScrollableChanged?.({
      x: scrollableX,
      y: scrollableY,
    });

    // This needs to be set, so the correct styles can be applied to the ScrollbarComponents
    setScrollbarSize({
      x: clientWidth,
      y: clientHeight,
    });

    // reserve some space (for icons, etc)
    const availableWidth = clientWidth - 2 * SCROLLBAR_ARROW_PADDING;
    const availableHeight = clientHeight - 2 * SCROLLBAR_ARROW_PADDING;

    // Calculate scrollbar Thumb sizes
    const thumbSizeX = (clientWidth / scrollWidth) * availableWidth;
    const thumbSizeY = (clientHeight / scrollHeight) * availableHeight;
    setScrollbarThumbSize({
      x: Math.max(
        MIN_SCROLL_THUMB_SIZE,
        Math.min(
          ensureNumberIsUsable(thumbSizeX, MIN_SCROLL_THUMB_SIZE),
          availableWidth
        )
      ),
      y: Math.max(
        MIN_SCROLL_THUMB_SIZE,
        Math.min(
          ensureNumberIsUsable(thumbSizeY, MIN_SCROLL_THUMB_SIZE),
          availableHeight
        )
      ),
    });

    // Set the maxThumbOffset
    // TODO function!
    const maxThumbOffset = {
      x: availableWidth - thumbSizeX,
      y: availableHeight - thumbSizeY,
    };
    setMaxThumbOffset(maxThumbOffset);
    innerStateRef.current.maxThumbOffset = maxThumbOffset;
  }, [onScrollableChanged]);

  const isInitialRender = useInitialRender();
  useEffect(() => {
    const scrollContainer = containerRef.current;
    if (!scrollContainer) return;

    if (isInitialRender) {
      handleScrollSetup();
    }

    scrollContainer.addEventListener('scroll', handleScroll, true);
    scrollContainer.addEventListener('wheel', handleWheelEvent, true);
    return () => {
      scrollContainer.removeEventListener('scroll', handleScroll, true);
      scrollContainer.removeEventListener('wheel', handleWheelEvent, true);
    };
  }, [handleScroll, isInitialRender, handleScrollSetup, handleWheelEvent]);

  // register the resize observer
  useEffect(() => {
    const scrollContainer = containerRef.current;
    if (!scrollContainer) return;

    // This is not the cause for the unsetting of state.
    const observer = new ResizeObserver(handleScrollSetup);

    // The observer must look at the element's children and the container itself
    for (const e of scrollContainer.children) {
      observer.observe(e);
    }
    observer.observe(scrollContainer);

    return () => {
      observer.disconnect();
    };
  }, [handleScrollSetup]);

  // register global drag/mouse handlers
  useEffect(() => {
    document.addEventListener('mousemove', handleDocumentMouseMove);
    document.addEventListener('mouseup', handleDocumentMouseUp);
    document.addEventListener('mouseleave', handleDocumentMouseUp);
    return function cleanup() {
      document.removeEventListener('mousemove', handleDocumentMouseMove);
      document.removeEventListener('mouseup', handleDocumentMouseUp);
      document.removeEventListener('mouseleave', handleDocumentMouseUp);
    };
  }, [handleDocumentMouseMove, handleDocumentMouseUp]);

  return {
    scrollbarSize,
    scrollbarThumbSize,
    scrollbarOffsets,
    scrollbarOffsetsMax: maxThumbOffset,
    scrollable,
    containerRef,
    handleThumbMouseDownX,
    handleThumbMouseDownY,
    userScrolling,
  };
};
