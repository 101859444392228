import React, { MouseEventHandler } from 'react';

import { Orientation } from '@mui/material';

import { useStyles } from './styles';

type ScrollbarThumbProps = {
  size: number;
  offset: number;
  onThumbMouseDown: MouseEventHandler<HTMLDivElement>;
  orientation: Orientation;
};

const ScrollbarThumb: React.VFC<ScrollbarThumbProps> = (props) => {
  const { orientation, size, offset } = props;
  const { classes, cx } = useStyles();

  return (
    <div
      className={cx(classes.scrollbarThumb, orientation)}
      style={{
        height: orientation === 'vertical' ? size : undefined,
        width: orientation === 'horizontal' ? size : undefined,
        top: orientation === 'vertical' ? offset : undefined,
        left: orientation === 'horizontal' ? offset : undefined,
      }}
      onMouseDown={props.onThumbMouseDown}
      data-testid="scrollbar-thumb"
    />
  );
};

export default React.memo(ScrollbarThumb);
