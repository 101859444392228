import { Theme } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  drawer: {
    width: 360,
    paddingTop: theme.sizing?.toolbarHeight,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      paddingTop: 0,
      zIndex: theme.zIndex.notificationDrawer,
    },
    transform: 'translateX(100%)',
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxShadow: '0px 4px 4px #00000061',
  },
  drawerOpen: {
    transform: 'translateX(0%)',
  },
  title: {
    [theme.breakpoints.down('md')]: {
      height: theme.sizing?.toolbarHeight,
    },
  },
  content: {
    flex: '1 1 0',
    overflowY: 'auto',
    padding: theme.spacing(2),
  },
}));
