import { createContext, useContext, useState } from 'react';

type TitleContextType = {
  headerTitle: string;
  setHeaderTitle: (newTitle: string) => void;
};

const TitleContext =
  createContext<Partial<TitleContextType | undefined>>(undefined);

function useHeaderTitle() {
  const context = useContext(TitleContext);
  if (!context) {
    throw new Error(`useHeaderTitle must be used within a TitleProvider`);
  }
  return context;
}

const TitleProvider = (props: React.PropsWithChildren<{}>): JSX.Element => {
  const [headerTitle, setHeaderTitle] = useState('CAS');
  return (
    <TitleContext.Provider value={{ headerTitle, setHeaderTitle }}>
      {props.children}
    </TitleContext.Provider>
  );
};

export { TitleProvider, useHeaderTitle };
