import {
  AnalysesCreator,
  AnalysisFetchById,
  AnalysisFetchPaginated,
  AnalysisPatch,
} from './types';
import {
  AnalysisId,
  CovalentKineticsAnalysis,
  CovalentKineticsAnalysisOverview,
  PaginatedList,
  CovalentKineticsAnalysisPatch,
  UUIDListObject,
  APIResponse,
} from 'types/APITypes/APITypes';
import { CovalentKineticsAnalysisFilter } from 'types/Filters';

import { axiosInstance, catchError, parseAPIResponse } from 'api/CASAPI';
import { AxiosError } from 'axios';

import { applyDefaultSorting } from 'utils/api/api';
import { getUrl } from 'utils/helpers/requestbuilder';

import { parseExperimentFromAPI } from '../experiments';

// API Calls

const parseCovalentKineticsAnalysisFromAPI = (
  analysis: CovalentKineticsAnalysis
): CovalentKineticsAnalysis => ({
  ...analysis,
  experiments: analysis.experiments.map(parseExperimentFromAPI),
});

export const createCovalentKineticsAnalyses: AnalysesCreator = async (
  experimentIds
) => {
  const response = await axiosInstance.post(
    getUrl('analysisCoavalentKineticsCreate'),
    {
      experimentIds: experimentIds,
    }
  );
  return parseAPIResponse<UUIDListObject>(response);
};

export const getCovalentKineticsAnalysis: AnalysisFetchById<
  CovalentKineticsAnalysis
> = async (id, queryParams) => {
  const response = await axiosInstance.get(
    getUrl('analysisCoavalentKinetics', { id: id }),
    {
      ...queryParams,
    }
  );
  return parseAPIResponse(response, parseCovalentKineticsAnalysisFromAPI);
};

export const getCovalentKineticsAnalysisList: AnalysisFetchPaginated<
  CovalentKineticsAnalysisOverview,
  CovalentKineticsAnalysisFilter
> = async (qp, requestConfig) => {
  const queryParams = applyDefaultSorting(qp, {
    sort: 'createdAt',
    sortDirection: 'descending',
  });
  const response = await axiosInstance.get(
    getUrl('analysisCoavalentKineticsList'),
    {
      ...requestConfig,
      params: {
        limit: queryParams.limit,
        offset: queryParams.offset,
        sort: queryParams.sorting?.sort,
        sortDirection: queryParams.sorting?.sortDirection,
        analysisName: queryParams.filtering?.analysisName,
        targetName: queryParams.filtering?.targetName,
        ligandName: queryParams.filtering?.ligandName,
        annotation: queryParams.filtering?.analysisAnnotation,
        searchValue: queryParams.searchValue,
      },
    }
  );
  return parseAPIResponse(
    response,
    (res: PaginatedList<CovalentKineticsAnalysisOverview>) => ({
      total: res.total,
      data: res.data,
    })
  );
};

export const patchCovalentKineticsAnalysis: AnalysisPatch<
  CovalentKineticsAnalysisPatch
> = async (id: AnalysisId, update: CovalentKineticsAnalysisPatch) => {
  const response = await axiosInstance.patch(
    getUrl('analysisCoavalentKineticsEdit', { id: id }),
    update
  );

  return parseAPIResponse(response);
};

export async function getCovalentKineticsAnalysisCSVExport(
  analysisId: string
): Promise<APIResponse<{ url: string; filename?: string } | null>> {
  try {
    const response = await axiosInstance.get(
      getUrl('analysisCoavalentKineticsCSVExport', { id: analysisId }),
      {
        responseType: 'blob',
      }
    );
    return parseAPIResponse(response, (body: BlobPart) => {
      const url = window.URL.createObjectURL(
        new Blob([body], { type: 'text/csv' })
      );
      const filename = response.headers['content-disposition']?.match(
        /filename="?(.+?)"?($|;)/
      )?.[1];
      return { url, filename };
    });
  } catch (error) {
    return catchError(error as Error | AxiosError);
  }
}
