import { createSelector } from '@reduxjs/toolkit';

import { AnalysisId } from 'types/APITypes/analyses';

import { RootState } from 'store/store';

const getAnalyses = (state: RootState) => state.analysis.analyses;

const getAnalysisId = (_: RootState, id: AnalysisId | null | undefined) => id;
export const selectAnalysisById = createSelector(
  getAnalyses,
  getAnalysisId,
  (analyses, id) => {
    return id ? analyses[id] : undefined;
  }
);

export const getAnalysisChangesPending = (state: RootState) =>
  state.analysis.analysisChangesPending;
export const getAnalysisChangesPendingById = createSelector(
  getAnalysisChangesPending,
  getAnalysisId,
  (changesPending, id) => {
    return id ? changesPending[id] : undefined;
  }
);

export const selectActiveAnalysis = (state: RootState) =>
  state.analysis.activeAnalysis;
export const selectActiveAnalysisId = (state: RootState) =>
  state.analysis.activeAnalysis?.uuid;

// The concatenated string is a workaround to avoid unneccessary re-triggers of a selectorHook.
// This is because unlike lists, strings are compared on a value basis to decide on triggers.
// In particular, when any 'Analysis' in the record entry is changed, but ids stay the same,
// this selector returns the same list by reference.
export const selectAnalysisIds = createSelector(
  (state: RootState) => Object.keys(state.analysis.analyses).join(';'),
  (concatenatedIds) => concatenatedIds.split(';')
);
