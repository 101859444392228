import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AnalysisContextError, SplitView } from 'context/types';
import {
  Analysis,
  AnalysisId,
  ExperimentId,
  ExperimentInfo,
} from 'types/APITypes/APITypes';

import log from 'loglevel';

type AnalysisState = {
  activeAnalysis: Analysis | null;
  analyses: Partial<Record<AnalysisId, Analysis>>;
  error: AnalysisContextError | null;
  analysisCreationLoading: boolean;
  analysisOpeningLoading: boolean;
  analysisChangesPending: Partial<Record<AnalysisId, boolean>>;
  splitView: SplitView;
};

export const initialState: AnalysisState = {
  activeAnalysis: null,
  analyses: {},
  error: null,
  analysisCreationLoading: false,
  analysisOpeningLoading: false,
  analysisChangesPending: {},
  splitView: {
    isSplitViewOpen: false,
    uuid: undefined,
  },
};

const analysisSlice = createSlice({
  name: 'analysis',
  initialState,
  reducers: {
    setActiveAnalysis: (
      state: AnalysisState,
      action: PayloadAction<{
        analysisId?: AnalysisId | null;
      }>
    ) => {
      const { analysisId } = action.payload;
      // The ?? null is required because an analysis with the given id may not exist
      state.activeAnalysis =
        (analysisId ? state.analyses[analysisId] : null) ?? null;
    },
    removeAnalysis: (
      state: AnalysisState,
      action: PayloadAction<AnalysisId>
    ) => {
      const analysisId = action.payload;

      // Remove the analysis
      delete state.analyses[analysisId];

      // Check if the given analysis was active
      if (state.activeAnalysis?.uuid === analysisId) {
        state.activeAnalysis = null;
      }
    },
    removeAllAnalyses: (state: AnalysisState) => {
      state.analyses = {};
      state.activeAnalysis = null;
    },
    removeLinkedExperiment: (
      state: AnalysisState,
      action: PayloadAction<{
        analysisId: AnalysisId;
        experimentId: ExperimentId;
      }>
    ) => {
      const { analysisId, experimentId } = action.payload;

      // Check if the analysis exists
      const analysis = state.analyses[analysisId];
      if (!analysis) return;

      // Modify the analysis' experiments
      const modifiedAnalysis: Analysis = {
        ...analysis,
        experiments: analysis.experiments.filter(
          (e) => e.uuid !== experimentId
        ) as never[],
      };

      // Write back to the state
      state.analyses[analysisId] = modifiedAnalysis;

      // Update activeAnalysis, if required.
      if (state.activeAnalysis?.uuid === analysisId) {
        state.activeAnalysis = modifiedAnalysis;
      }
    },
    toggleSingleLinkedExperimentActiveStatus: (
      state: AnalysisState,
      action: PayloadAction<{
        analysisId: AnalysisId;
        experimentId: ExperimentId;
      }>
    ) => {
      const { analysisId, experimentId } = action.payload;

      // Check if the analysis exists
      const analysis = state.analyses[analysisId];
      if (!analysis) return;

      // Toggle the activeExperimentId
      const experimentCurrentlyActive =
        analysis.activeExperimentId === experimentId;
      const modifiedAnalysis: Analysis = {
        ...analysis,
        activeExperimentId: experimentCurrentlyActive
          ? undefined
          : experimentId,
      };

      // Write back to the state
      state.analyses[analysisId] = modifiedAnalysis;

      // Update activeAnalysis, if required.
      if (state.activeAnalysis?.uuid === analysisId) {
        state.activeAnalysis = modifiedAnalysis;
      }
    },
    setAllLinkedExperimentsInactive: (
      state: AnalysisState,
      action: PayloadAction<{ analysisId: AnalysisId }>
    ) => {
      const { analysisId } = action.payload;

      // Check if the analysis exists
      const analysis = state.analyses[analysisId];
      if (!analysis) return;

      // Modify the analysis
      const modifiedAnalysis: Analysis = {
        ...analysis,
        activeExperimentId: undefined,
      };

      // Write back to the state
      state.analyses[analysisId] = modifiedAnalysis;

      // Update activeAnalysis, if required.
      if (state.activeAnalysis?.uuid === analysisId) {
        state.activeAnalysis = modifiedAnalysis;
      }
    },
    setLinkedExperiments: (
      state: AnalysisState,
      action: PayloadAction<{
        analysisId: AnalysisId;
        experiments: ExperimentInfo[];
      }>
    ) => {
      const { analysisId, experiments } = action.payload;

      // Check if the analysis exists
      const analysis = state.analyses[analysisId];
      if (!analysis) return;

      // Modify the analysis
      const modifiedAnalysis = {
        ...analysis,
        experiments,
      } as Analysis;

      // Write back to the state
      state.analyses[analysisId] = modifiedAnalysis;

      // Update activeAnalysis, if required.
      if (state.activeAnalysis?.uuid === analysisId) {
        state.activeAnalysis = modifiedAnalysis;
      }
    },
    renameAnalysis: (
      state: AnalysisState,
      action: PayloadAction<{
        analysisId: AnalysisId;
        name: Analysis['analysisName'];
      }>
    ) => {
      const { analysisId, name } = action.payload;

      // Check if the analysis exists
      const analysis = state.analyses[analysisId];
      if (!analysis) return;

      // Modify the analysis
      const modifiedAnalysis: Analysis = {
        ...analysis,
        analysisName: name,
      };

      // Write back to the state
      state.analyses[analysisId] = modifiedAnalysis;

      // Update activeAnalysis, if required.
      if (state.activeAnalysis?.uuid === analysisId) {
        state.activeAnalysis = modifiedAnalysis;
      }
    },
    insertAnalysis: (state: AnalysisState, action: PayloadAction<Analysis>) => {
      const analysis = action.payload;
      state.analyses[analysis.uuid] = analysis;
      state.activeAnalysis = analysis;
    },
    updateAnalysis: (state: AnalysisState, action: PayloadAction<Analysis>) => {
      const analysis = action.payload;
      // check if the analysis is already store locally
      // TODO: if not, that's an error, how should that be handled?
      if (!state.analyses[analysis.uuid]) {
        throw new Error(
          'Cannot update Analysis, which is not present locally.'
        );
      }

      // Write to state
      state.analyses[analysis.uuid] = analysis;

      // Update active analysis, if required
      if (state.activeAnalysis?.uuid === analysis.uuid) {
        state.activeAnalysis = analysis;
      }
    },
    setAnalysisError: (
      state: AnalysisState,
      action: PayloadAction<AnalysisContextError | null>
    ) => {
      if (action.payload) {
        log.error(
          `AnalysisReducer ERROR: ${action.payload.title} ${action.payload.message}`
        );
      }
      state.error = action.payload;
    },
    setAnalysisCreationLoading: (state, action: PayloadAction<boolean>) => {
      state.analysisCreationLoading = action.payload;
    },
    setAnalysisOpeningLoading: (state, action: PayloadAction<boolean>) => {
      state.analysisOpeningLoading = action.payload;
    },
    setAnalysisChangesPending: (
      state,
      action: PayloadAction<{ id: AnalysisId; pending: boolean }>
    ) => {
      const { id, pending } = action.payload;
      // Check if the analysis exists
      if (!state.analyses[id]) {
        throw new Error(
          'Cannot set pending changes flag, for an analysis, which is not present locally.'
        );
      }

      state.analysisChangesPending[id] = pending;
    },
  },
});

export const {
  setActiveAnalysis,
  removeAnalysis,
  removeAllAnalyses,
  toggleSingleLinkedExperimentActiveStatus,
  setAllLinkedExperimentsInactive,
  insertAnalysis,
  updateAnalysis,
  setAnalysisError,
} = analysisSlice.actions;

export const analysisActions = analysisSlice.actions;

export const analysesReducer = analysisSlice.reducer;
