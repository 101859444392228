import { DataTableSort } from 'components/DataTable/types/types';

export function applyDefaultSorting<
  T extends { sorting?: Partial<DataTableSort> },
>(params: T | undefined, defaultSorting: DataTableSort): T {
  if (!params) return { sorting: defaultSorting } as T;
  const noSortingApplied =
    !params.sorting?.sort || !params.sorting?.sortDirection;
  return {
    ...params,
    ...(noSortingApplied ? { sorting: defaultSorting } : undefined),
  };
}

export function toUrlSearchParams(
  params: Record<string, string[] | string | number | undefined>
): URLSearchParams {
  const urlParams = new URLSearchParams();
  for (const key in params) {
    const value = params[key];
    if (typeof value == 'undefined') {
      continue;
    }
    if (typeof value == 'string') {
      urlParams.append(key, value);
      continue;
    }
    if (typeof value == 'number') {
      urlParams.append(key, value.toString());
      continue;
    }
    for (const element of value) {
      urlParams.append(key, element);
    }
  }
  return urlParams;
}
