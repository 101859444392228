import {
  AnalysisCreator,
  AnalysisFetchById,
  AnalysisFetchPaginated,
  AnalysisPatch,
} from './types';
import {
  AnalysisId,
  BindingAffinityAnalysis,
  BindingAffinityAnalysisPatch,
  PaginatedList,
} from 'types/APITypes/APITypes';

import { axiosInstance, parseAPIResponse } from 'api/CASAPI';
import { parseExperimentFromAPI } from 'api/experiments';

import { applyDefaultSorting } from 'utils/api/api';
import { getUrl } from 'utils/helpers/requestbuilder';

// Parsing

const parseBindingAffinityAnalysisFromAPI = (
  analysis: BindingAffinityAnalysis
): BindingAffinityAnalysis => ({
  ...analysis,
  experiments: analysis.experiments.map(parseExperimentFromAPI),
});

// API Calls

export const getBindingAffinityAnalysisList: AnalysisFetchPaginated<
  BindingAffinityAnalysis
> = async (qp, requestConfig) => {
  const queryParams = applyDefaultSorting(qp, {
    sort: 'createdAt',
    sortDirection: 'descending',
  });
  const response = await axiosInstance.get(
    getUrl('analysisBindingAffinityList'),
    {
      ...requestConfig,
      params: {
        limit: queryParams.limit,
        offset: queryParams.offset,
        sort: queryParams.sorting?.sort,
        sortDirection: queryParams.sorting?.sortDirection,
        analysisName: queryParams.filtering?.analysisName,
        annotation: queryParams.filtering?.analysisAnnotation,
        searchValue: queryParams.searchValue,
      },
    }
  );
  return parseAPIResponse(
    response,
    (res: PaginatedList<BindingAffinityAnalysis>) => ({
      total: res.total,
      data: res.data.map(parseBindingAffinityAnalysisFromAPI),
    })
  );
};

export const createBindingAffinityAnalysis: AnalysisCreator = async (
  experimentIds
) => {
  const analysisSetup = {
    experimentIds: experimentIds.slice(0, 10),
  };

  const response = await axiosInstance.post(
    getUrl('analysisBindingAffinityCreate'),
    analysisSetup
  );
  return parseAPIResponse(response);
};

export const getBindingAffinityAnalysis: AnalysisFetchById<
  BindingAffinityAnalysis
> = async (id, queryParams) => {
  const response = await axiosInstance.get(
    getUrl('analysisBindingAffinity', { id: id }),
    {
      ...queryParams,
    }
  );
  return parseAPIResponse(response, parseBindingAffinityAnalysisFromAPI);
};

export const patchBindingAffinityAnalysis: AnalysisPatch<
  BindingAffinityAnalysisPatch
> = async (id: AnalysisId, update: BindingAffinityAnalysisPatch) => {
  const response = await axiosInstance.patch(
    getUrl('analysisBindingAffinityEdit', { id: id }),
    update
  );

  return parseAPIResponse(response);
};
