import React from 'react';

export const stopEvent = (event: React.MouseEvent<any, any>): void => {
  event.stopPropagation();
};

export const stopPropagation = (
  callback?: () => void
): ((event: React.MouseEvent<any, any>) => void) => {
  return (event: React.MouseEvent<any, any>): void => {
    event.stopPropagation();
    callback?.();
  };
};
