import { Theme } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  tableCellContentBox: {
    padding: '6px 16px 6px 16px',
    margin: 'auto 0',
  },

  tableCellPara: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));
