import { DataTableSort } from 'components/DataTable/types/types';

export type DataTableSortButtonProps = {
  sortDirection: DataTableSort['sortDirection'];
  onClick: () => void;
};

export enum AriaLabel {
  none = 'Change sorting to ascending',
  asc = 'Change sorting to descending',
  desc = 'Change sorting to none',
}
