import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    // The as any is necessary because otherwise react-tss throws a type-error
    ...(theme.typography.body2 as any),
    width: '100%',
    height: 'calc(100% + 2px)',
    padding: '0px 16px',
    borderTop: `1px solid transparent`,
    borderBottom: `1px solid transparent`,
    '&:hover': {
      padding: '0 1rem',
      backgroundColor: theme.palette.action.hover,
      borderBottom: `1px solid ${theme.palette.primary.dark}`,
      borderTop: `1px solid ${theme.palette.primary.dark}`,
      '& ::placeholder': {
        color: theme.palette.secondary.main,
      },
    },
    '&.Mui-focused': {
      padding: '0 1rem',
      backgroundColor: theme.palette.background.default,
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      borderTop: `1px solid ${theme.palette.primary.main}`,
      '& ::placeholder': {
        color: `${theme.palette.text.secondary} !important`,
      },
    },
    '&.warning': {
      padding: '0 1rem',
      borderBottom: `1px solid ${theme.palette.warning.dark}`,
      borderTop: `1px solid ${theme.palette.warning.dark}`,
    },
    '&.error': {
      padding: '0 1rem',
      borderBottom: `1px solid ${theme.palette.error.dark}`,
      borderTop: `1px solid ${theme.palette.error.dark}`,
    },
  },
  input: {
    padding: '0px',
    '&::placeholder': {
      color: theme.palette.text.secondary,
      fontStyle: 'italic',
    },
  },
}));
