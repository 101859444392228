import { APIResponse } from '../types/APITypes/APITypes';

import axios, {
  AxiosError,
  AxiosProgressEvent,
  AxiosResponse,
  CancelTokenSource,
} from 'axios';

import Config from '../config/Config';

import json5 from 'json5';

export const parseAPIResponse = <T extends unknown>(
  response: AxiosResponse<any>,
  bodyParser?: (body: any) => T
): APIResponse<T> => {
  const { status, data } = response;
  return {
    status,
    body: bodyParser != null ? bodyParser(data) : (data as T),
  };
};

export type ProgressCallback = (prog: AxiosProgressEvent) => void;

// Use non-HTTP-Status codes (like -1 ect for handling other errors)
// TODO create some error constants/ parsing somewhere.
export const errorToStatus = (error: Error | AxiosError): number => {
  switch (error.message) {
    case 'Network Error':
      return -2;
    default:
      return -1;
  }
};

export const catchError = (error: Error | AxiosError) => ({
  status: errorToStatus(error),
  body: null,
});

export const getCancelTokenSource = (): CancelTokenSource => {
  return axios.CancelToken.source();
};

export const axiosInstance = axios.create({
  baseURL: Config.apiBaseUrl,
  // make any Request status pass, so we can wrap it via an APIResponse
  validateStatus: () => true,
  withCredentials: true,
  transformResponse: [
    function transformResponse(data) {
      const transitional = this.transitional;
      const forcedJSONParsing = transitional && transitional.forcedJSONParsing;
      const JSONRequested = this.responseType === 'json';

      if (
        data &&
        typeof data === 'string' &&
        ((forcedJSONParsing && !this.responseType) || JSONRequested)
      ) {
        const silentJSONParsing =
          transitional && transitional.silentJSONParsing;
        const strictJSONParsing = !silentJSONParsing && JSONRequested;

        try {
          return json5.parse(data);
        } catch (e: any) {
          if (strictJSONParsing) {
            if (e.name === 'SyntaxError') {
              throw AxiosError.from(e, AxiosError.ERR_BAD_RESPONSE, this, null);
            }
            throw e;
          }
        }
      }

      return data;
    },
  ],
});
