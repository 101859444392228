import { Theme } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    margin: 'auto 1rem auto 0',
    padding: '6px 8px',
  },
}));
