import { Theme } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  // Menu Items
  menuItemActive: {
    '& > *': {
      backgroundColor: 'rgba(63, 81, 181, 0.08) !important',
    },
  },

  menuItemLink: {
    textDecoration: 'none',
  },

  // Drawer
  drawer: {
    overflow: 'hidden',
    paddingTop: theme.sizing?.toolbarHeight,
    backgroundColor: theme.palette.grey[100],
    marginRight: '8px',
    transition: theme.transitions.create(['width'], {
      duration: theme.timing.sidebarAnimation,
    }),
  },

  drawerMobile: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },

  drawerOpen: {
    width: theme.sizing?.sidebarWidthOpen + 1,
    whiteSpace: 'pre-wrap',
  },

  drawerOpenMobile: {
    width: '100%',
    display: '',
    whiteSpace: 'pre-wrap',
  },

  drawerClose: {
    width: theme.sizing?.sidebarWidthClosed,
  },

  drawerCloseMobile: {
    display: 'none',
    width: '74px',
  },

  // AnalysisList
  analysisListWrapper: {
    height: '100%',
    maxHeight: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    // Firefox
    scrollbarWidth: 'none',
    // IE and Edge
    msOverflowStyle: 'none',
    // Chrome, Safari and Opera
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },

  // Chevron
  chevron: {
    width: 24,
    height: 24,
    transform: 'rotateZ(0deg)',
    transition: theme.transitions.create(['transform'], {
      duration: theme.timing.sidebarAnimation,
    }),
  },
  chevronClosed: {
    transform: 'rotateZ(-180deg)',
  },
  chevronWrapper: {
    width: theme.sizing.sidebarWidthClosed,
    padding: '0 4px',
    display: 'flex',
    justifyContent: 'center',
  },
}));
