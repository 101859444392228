import React from 'react';

import { DataTablePageChangeCallback } from 'components/DataTable/types/types';

import { Box, Typography, Checkbox } from '@mui/material';

import { useTableErrorContext } from 'components/DataTable/context/TableErrorContext';

import { ErrorOutline } from '@mui/icons-material';

import { useStyles } from './styles';

import { RowsPerPageId } from 'utils/hooks/tableIds';
import { Severity } from 'utils/utils';

import ToolbarPaginationControls from './ToolbarPaginationControls';

interface DataTableToolbarSelectBaseProps {
  numSelectedRows: number;
  selectionType?: string;
  pagination?: boolean;
  disableSelection?: boolean;

  rowsPerPageId: RowsPerPageId;

  enableShowSelectedOnly?: boolean;
  showSelectedOnly?: boolean;
  onShowSelectedOnlyChange?: (event: any, show: boolean) => void;
}

interface DataTableToolbarSelectNoPaginationProps
  extends DataTableToolbarSelectBaseProps {
  pagination?: false;
}

interface DataTableToolbarSelectPaginationProps
  extends DataTableToolbarSelectBaseProps {
  pagination: true;

  totalRows?: number;
  page?: number;
  onPageChange?: DataTablePageChangeCallback;
}

export type DataTableToolbarSelectProps =
  | DataTableToolbarSelectNoPaginationProps
  | DataTableToolbarSelectPaginationProps;

function DataTableToolbarSelect(props: DataTableToolbarSelectProps) {
  const {
    pagination,
    numSelectedRows,
    disableSelection,
    enableShowSelectedOnly,
  } = props;
  const { classes, cx } = useStyles();

  const {
    message: errorMessage,
    error,
    errorSeverity,
  } = useTableErrorContext();

  let selectionType = props.selectionType ?? 'row';

  if (numSelectedRows !== 1) {
    selectionType += 's';
  }

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      lineHeight="30px"
      className={classes.root}
    >
      {/* Selection */}
      <Box height={60} mr={1.5} display="flex" flexDirection="column">
        {!disableSelection && enableShowSelectedOnly && (
          <Box display="flex" my="auto">
            {/* Checkbox */}
            <Box my="auto">
              <Checkbox
                checked={props.showSelectedOnly}
                onChange={props.onShowSelectedOnlyChange}
                color="primary"
                size="small"
                data-testid="show-selected-experiments-checkbox"
              />
            </Box>
            {/* Text */}
            <Box>
              <Typography variant="body1" data-testid="table-toolbar-selection">
                Show only selected
              </Typography>
              <Typography
                component={'span'}
                variant="subtitle2"
                color="textSecondary"
                data-testid="table-toolbar-selection"
              >
                {numSelectedRows} {selectionType} selected
              </Typography>
            </Box>
          </Box>
        )}
        {!disableSelection && !enableShowSelectedOnly && (
          <Box my="auto">
            <Typography
              component={'span'}
              variant="subtitle2"
              color="textSecondary"
              data-testid="table-toolbar-selection"
            >
              {numSelectedRows} {selectionType} selected
            </Typography>
          </Box>
        )}
      </Box>

      {/* Validation Messages */}
      {error && (
        <Box
          height={60}
          data-testid="error-box"
          className={cx({
            [classes.errorBox]: true,
            [classes.error]: errorSeverity === Severity.ERROR,
            [classes.warning]: errorSeverity === Severity.WARNING,
          })}
        >
          <ErrorOutline
            style={{ margin: 'auto 4px auto 0' }}
            fontSize="small"
            data-testid="error-icon"
          />
          <Box my="auto">
            <Typography variant="subtitle2">{errorMessage || ''}</Typography>
          </Box>
        </Box>
      )}

      {/* Pagination */}
      {pagination && (
        <ToolbarPaginationControls
          totalRows={props.totalRows}
          rowsPerPageId={props.rowsPerPageId}
          page={props.page}
          onPageChange={props.onPageChange}
        />
      )}
    </Box>
  );
}

export default React.memo(DataTableToolbarSelect);
