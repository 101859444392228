import { createTheme } from '@mui/material/styles';

// Augment the type-declarations used for the Theme, so we can use TS and custom properties

declare module '@mui/material/styles/zIndex' {
  interface ZIndex {
    appToolbar: number;
    notificationDrawer: number;
    overlay: number;
  }
}
declare module '@mui/material/styles' {
  interface Theme {
    sizing: {
      toolbarHeight: number;
      sidebarWidthOpen: number;
      sidebarWidthClosed: number;
      scrollbarThickness: number;
    };
    timing: {
      sidebarAnimation: number;
    };
  }
  interface ThemeOptions {
    sizing: {
      toolbarHeight: number;
      sidebarWidthOpen: number;
      sidebarWidthClosed: number;
      scrollbarThickness: number;
    };
    timing: {
      sidebarAnimation: number;
    };
  }
}

const baseTheme = createTheme();

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xxl: true;
  }
}

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0, // default value
      sm: 600, // default value
      md: 900, // default value
      lg: 1200, // default value
      xl: 1536, // default value for extra large screen
      xxl: 1800, // custom breakpoint for extra extra large screen
    },
  },
  palette: {
    mode: 'light',
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.6)',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
    primary: {
      main: '#006FD3',
      dark: '#025AAB',
      light: '#3189D9',
    },
    secondary: {
      main: '#0E253A',
      dark: '#01172B',
      light: '#1C4973',
    },
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(0, 0, 0, 0.04)',
      selected: 'rgba(0, 0, 0, 0.08)',
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
      focus: 'rgba(0, 0, 0, 0.12)',
    },
    error: {
      main: '#AB0303',
      dark: '#BE0303',
      light: '#E35757',
    },
    warning: {
      main: '#ed7104',
      light: '#f18308',
      dark: '#d84003',
    },
    info: {
      main: '#84368A',
      light: '#76307C',
      dark: '#9B3FA3',
    },
    success: {
      main: '#08893E',
      dark: '#077234',
      light: '#7BC67E',
    },
    background: {
      paper: '#FFFFFF',
      default: '#FAFAFA',
    },
    divider: 'rgba(0, 0, 0, 0.12)',
  },
  typography: {
    fontFamily: '"Source Sans Pro", sans-serif',
    fontSize: 16,
    overline: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '32px',
      letterSpacing: '1.3px',
      textTransform: 'uppercase',
    },
    h1: {
      fontSize: 96,
      fontWeight: 300,
      lineHeight: '112px',
      letterSpacing: '-1.5px',
    },
    h2: {
      fontSize: 60,
      fontWeight: 300,
      lineHeight: 72,
      letterSpacing: '-0.5px',
    },
    h3: {
      fontSize: 39,
      fontWeight: 300,
      lineHeight: '48px',
    },
    h4: {
      fontSize: 32,
      fontWeight: 400,
      lineHeight: '42px',
      letterSpacing: '0.25px',
    },
    h5: {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: '32px',
      letterSpacing: '0.15px',
    },
    h6: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '24px',
      letterSpacing: '0.15px',
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '0.15px',
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '0.15px',
    },
    subtitle1: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: '30px',
      letterSpacing: '0.15px',
    },
    subtitle2: {
      fontSize: 14,
      lineHeight: '22px',
      letterSpacing: '0.1px',
    },
    caption: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '166%',
      letterSpacing: '0.4px',
    },
    button: {
      fontSize: 16,
      fontWeight: 600,
      letterSpacing: '0.5px',
      textTransform: 'uppercase',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        sizeSmall: {
          fontSize: 13,
          lineHeight: '16px',
        },
        sizeMedium: {
          fontSize: 14,
          lineHeight: '20px',
        },
        sizeLarge: {
          fontSize: 16,
          lineHeight: '24px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        standard: {
          fontSize: 12,
          lineHeight: '12px',
          letterSpacing: '0.15px',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: 12,
          lineHeight: '20px',
          letterSpacing: '0.4px',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: 13,
          lineHeight: '18px',
          letterSpacing: '0.16px',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 10,
          fontWeight: 600,
          lineHeight: '14px',
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontSize: 16,
          fontWeight: 600,
          lineHeight: '150%',
          letterSpacing: '0.15px',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 600,
          lineHeight: '24px',
          letterSpacing: '0.17px',
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        root: {
          fontSize: 12,
          fontWeight: 600,
          lineHeight: '20px',
          letterSpacing: '0.14px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          lineHeight: 1.25,
        },
        input: {
          height: '1.25rem',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:hover $notchedOutline': {
            borderColor: '#025AAB',
          },
          '&$focused $notchedOutline': {
            borderWidth: 1,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        sizeSmall: {
          padding: 0,
          '&:last-child': {
            paddingRight: 0,
          },
        },
      },
    },
    MuiList: {
      defaultProps: {
        dense: true,
      },
    },
    MuiMenuItem: {
      defaultProps: {
        dense: true,
      },
    },
    MuiTable: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiAppBar: {
      defaultProps: {
        color: 'secondary',
      },
    },
  },
  sizing: {
    toolbarHeight: 64,
    sidebarWidthOpen: 280,
    sidebarWidthClosed: 56,
    scrollbarThickness: 20,
  },
  timing: {
    sidebarAnimation: baseTheme.transitions.duration.standard,
  },
  zIndex: {
    appToolbar: baseTheme.zIndex.drawer + 1,
    notificationDrawer: baseTheme.zIndex.drawer + 2,
    overlay: 1000,
  },
});
