import { useEffect, useState, useCallback } from 'react';

import { isFilterEmpty } from 'utils/utils';

type UseDataTableFilterOptions<T> = {
  fixedFilter?: T;
  initialFilter?: T;
};

export function useDataTableFilter<T>(
  onChange: (dataTableFilter: T) => void,
  options?: UseDataTableFilterOptions<T>
) {
  const [dataTableFilter, setDataTableFilter] = useState(() => {
    let baseFilter = options?.fixedFilter ?? ({} as T);
    if (options?.initialFilter) {
      baseFilter = { ...baseFilter, ...options.initialFilter };
    }
    return baseFilter;
  });
  const [active, setActive] = useState(!isFilterEmpty(dataTableFilter));

  const handleChange = useCallback(
    (name: string, value?: string) => {
      const newFilter: T = {
        ...dataTableFilter,
        [name]: value,
      };
      setDataTableFilter(newFilter);
      onChange(newFilter);
    },
    [dataTableFilter, onChange]
  );

  const handleReset = useCallback(() => {
    const baseFilter = options?.fixedFilter ?? ({} as T);
    setDataTableFilter(baseFilter);
    onChange(baseFilter);
  }, [onChange, options?.fixedFilter]);

  useEffect(() => {
    if (isFilterEmpty(dataTableFilter)) {
      setActive(false);
    } else {
      setActive(true);
    }
  }, [dataTableFilter]);

  return [dataTableFilter, handleChange, handleReset, active] as const;
}
