import { useCallback } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { SubitemCallback, SubitemCallbackOptions } from './types';
import { Analysis, AnalysisId } from 'types/APITypes/APITypes';

import { setActiveAnalysis } from 'reducers/analysis/slice';
import { useAppDispatch } from 'store/store';

import {
  Box,
  Divider,
  Drawer,
  List,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import ScrollContainer from 'components/ScrollContainer/ScrollContainer';

import { AnalysisIcon, ExperimentsIcon, UploadFilesIcon } from './icons';

import { useStyles } from './styles';

import { useAnalysisHandlers } from 'utils/hooks/analysis';

import AnalysisListSubheader from './AnalysisListSubheader/AnalysisListSubheader';
import SidebarItem from './SidebarItem/SidebarItem';
import SidebarMenuItem from './SidebarMenuItem';
import SidebarMenuItemOpenClose from './SidebarMenuItemOpenClose';

type SideBarProps = {
  onItemEdit?: (id: AnalysisId) => void;
  onSubItemClick?: SubitemCallback;
  onSubItemRemove?: SubitemCallback;
  actions: Analysis[];
  active?: AnalysisId | null;
  open: boolean;
  setOpen: (open: boolean) => void;
  onSidebarToggle?: () => void;
};

export function SideBar(props: SideBarProps): JSX.Element {
  const { open, setOpen } = props;
  const dispatch = useAppDispatch();

  const location = useLocation();
  const routeMatch = matchPath(
    {
      path: '/analyses/:analysisTypeSlug/:analysisId',
    },
    location.pathname
  );

  const { classes: styles, cx } = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const {
    onAnalysisClick,
    onAnalysisDelete,
    onDeleteAllAnalyses,
    onAnalysisTitleChange,
  } = useAnalysisHandlers();

  // Call this on every item click
  const handleMobileClick = useCallback((): void => {
    if (mobile && open) {
      setOpen(false);
    }
  }, [mobile, open, setOpen]);

  // Clicking on an non expanded item's subitem should
  // first open the item and the click
  const wrapSubitemCallback = (
    callback?: SubitemCallback,
    permissive = true
  ): SubitemCallback => {
    return (options: SubitemCallbackOptions) => {
      if (props.active !== options.itemId) {
        onAnalysisClick(options.itemId);
        if (!permissive) return;
      }
      callback?.(options);
    };
  };

  // Callback handlers for items/subitems
  const onAnalysisMenuItemClick = useCallback(
    (id: AnalysisId): void => {
      handleMobileClick();
      onAnalysisClick(id);
      dispatch(setActiveAnalysis({ analysisId: id }));
    },
    [handleMobileClick, onAnalysisClick, dispatch]
  );

  const handleMenuItemClick = useCallback(() => {
    dispatch(setActiveAnalysis({}));
    handleMobileClick();
  }, [handleMobileClick, dispatch]);

  const onSubItemClick: SubitemCallback = wrapSubitemCallback(
    props.onSubItemClick
  );
  const onSubItemRemove: SubitemCallback = wrapSubitemCallback(
    props.onSubItemRemove
  );

  let classesMap = {
    className: {
      mobile: {
        [styles.drawer]: true,
        [styles.drawerMobile]: true,
        [styles.drawerOpenMobile]: open,
        [styles.drawerCloseMobile]: !open,
      },
      desktop: {
        [styles.drawer]: true,
        [styles.drawerOpen]: open,
        [styles.drawerClose]: !open,
      },
    },
    classes: {
      mobile: {
        [styles.drawer]: true,
        [styles.drawerOpenMobile]: open,
        [styles.drawerCloseMobile]: !open,
      },
      desktop: {
        [styles.drawer]: true,
        [styles.drawerOpen]: open,
        [styles.drawerClose]: !open,
      },
    },
  };

  const classNames = mobile
    ? classesMap.className.mobile
    : classesMap.className.desktop;
  const classes = mobile
    ? classesMap.classes.mobile
    : classesMap.classes.desktop;

  const menuItems = [
    {
      path: '/',
      onClick: handleMenuItemClick,
      IconComponent: UploadFilesIcon,
      label: 'Upload Files',
      dataTestId: 'upload-files',
    },
    {
      path: '/experiments',
      onClick: handleMenuItemClick,
      IconComponent: ExperimentsIcon,
      label: 'Data Overview',
      dataTestId: 'experiment-overview',
    },
    {
      path: '/analyses',
      comparePath: '/analyses/:type',
      onClick: handleMenuItemClick,
      IconComponent: AnalysisIcon,
      label: 'Analysis Overview',
      dataTestId: 'analysis-overview',
    },
  ];

  return (
    <Drawer
      variant="permanent"
      PaperProps={{
        style: { overflow: 'hidden' },
      }}
      className={cx(classNames)}
      classes={{
        paper: cx(classes),
      }}
      data-testid="sidebar-drawer"
    >
      <List>
        <SidebarMenuItemOpenClose
          sidebarOpen={open}
          onSidebarToggle={props.onSidebarToggle}
        />
        {menuItems.map((props, index) => (
          <SidebarMenuItem key={index} sidebarOpen={open} {...props} />
        ))}
      </List>
      <Divider variant="middle" />
      <AnalysisListSubheader
        sx={{
          overflow: 'hidden',
          opacity: open ? 1 : 0,
          height: open ? '74px' : 0,
          padding: open ? '0.5rem' : 0,
          margin: open
            ? 0
            : `0 0 0 ${
                (theme.sizing.sidebarWidthClosed -
                  theme.sizing.sidebarWidthOpen) /
                2
              }px`,
          transition: (theme) =>
            theme.transitions.create(['opacity', 'height', 'margin'], {
              duration: theme.timing.sidebarAnimation,
            }),
        }}
        numOpenAnalyses={props.actions.length}
        onCloseAllAnalyses={onDeleteAllAnalyses}
      />
      {props.actions.length > 0 && (
        <List sx={{ minHeight: 0 }}>
          <ScrollContainer>
            <Box data-testid="sidebar-item-list">
              {props.actions.map((analysis) => (
                <SidebarItem
                  key={`sidebar_item_${analysis.uuid}`}
                  isSelected={Boolean(
                    routeMatch &&
                      routeMatch?.params?.analysisId === analysis.uuid
                  )}
                  analysis={analysis}
                  sidebarOpen={open}
                  selectedAnalysis={props.active}
                  onItemClick={onAnalysisMenuItemClick}
                  onItemRemove={onAnalysisDelete}
                  onItemEdit={props.onItemEdit}
                  onItemRename={onAnalysisTitleChange}
                  onSubItemClick={onSubItemClick}
                  onSubItemRemove={onSubItemRemove}
                />
              ))}
            </Box>
          </ScrollContainer>
        </List>
      )}
    </Drawer>
  );
}

export default SideBar;
