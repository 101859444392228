import React from 'react';

import { ExperimentSelectorProps } from './types';
import { ExperimentQueryParams } from 'types/QueryParams';

import {
  useExperimentList,
  useExperimentStatuses,
  useExperimentTypes,
  useFileTypes,
} from 'api/hooks/hooks';

import { useStyles } from './styles';

import { defaultPaginationSize } from 'utils/pagination/pagination';

import InnerExperimentSelector from './InnerExperimentSelector';

function ExperimentSelector(props: ExperimentSelectorProps) {
  const [experimentQueryParams, setExperimentQueryParams] =
    React.useState<ExperimentQueryParams>({
      filtering: props.fixedFilters,
      ...defaultPaginationSize,
    });

  const { experimentList, experimentListLoading, totalExperiments } =
    useExperimentList({}, experimentQueryParams);
  const experimentTypes = useExperimentTypes();
  const experimentStatuses = useExperimentStatuses();
  const fileTypeTabs = useFileTypes();
  const fileTypes = fileTypeTabs.fileTypeTabs.map((t) => t.fileTypes).flat();

  const loading =
    experimentListLoading ||
    experimentTypes.experimentTypesLoading ||
    experimentStatuses.experimentStatusesLoading ||
    fileTypeTabs.fileTypesLoading;

  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <InnerExperimentSelector
        isLoading={loading}
        experiments={experimentList}
        totalExperiments={totalExperiments}
        selected={props.selected}
        experimentTypes={experimentTypes.experimentTypeList}
        experimentStatuses={experimentStatuses.experimentStatusList}
        fileTypes={fileTypes}
        onExperimentQueryParamsChange={setExperimentQueryParams}
        onSelectionChanged={props.onSelectionChanged}
        experimentFilterContainer={props.experimentFilterContainer}
        fixedFilters={props.fixedFilters}
      />
    </div>
  );
}

export default ExperimentSelector;
