import { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  AnalysisId,
  AnalysisType,
  AnalysisTypeToSlug,
} from 'types/APITypes/APITypes';

import { removeAllAnalyses, removeAnalysis } from 'reducers/analysis/slice';
import { renameAnalysis } from 'reducers/analysis/thunk';
import { useAppSelector, useAppDispatch } from 'store/store';

export const useAnalysisViewHandlers = () => {
  const navigate = useNavigate();
  const analysisState = useAppSelector((state) => state.analysis);
  const dispatch = useAppDispatch();

  const { activeAnalysis } = analysisState;

  const handleOnDelete = useCallback(
    (id: AnalysisId): void => {
      // If the analysis to be removed is active, return to home-page
      // Ideally, we save the previuous non-analysis route and return to that.
      if (activeAnalysis?.uuid === id) {
        navigate(
          `/analyses/${AnalysisTypeToSlug[activeAnalysis.analysisType]}`
        );
      }
      dispatch(removeAnalysis(id));
    },
    [activeAnalysis, navigate, dispatch]
  );

  const handleAnalysisTitleChange = useCallback(
    (newName: string, analysisId: string): void => {
      dispatch(renameAnalysis({ analysisId, newName }));
    },
    [dispatch]
  );

  return {
    onAnalysisDelete: handleOnDelete,
    onAnalysisTitleChange: handleAnalysisTitleChange,
  };
};

export const useAnalysisHandlers = () => {
  const analysisState = useAppSelector((state) => state.analysis);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { activeAnalysis } = analysisState;

  const handleNavigateAnalysis = useCallback(
    (id: string): void => {
      if (id !== activeAnalysis?.uuid) return;
      navigate(
        `/analyses/${AnalysisTypeToSlug[activeAnalysis.analysisType]}/${id}`
      );
    },
    [activeAnalysis, navigate]
  );

  const handleOnDelete = useCallback(
    (id: string): void => {
      // If the analysis to be removed is active, return to home-page
      // Ideally, we save the previuous non-analysis route and return to that.
      if (activeAnalysis?.uuid === id) {
        navigate(
          `/analyses/${AnalysisTypeToSlug[activeAnalysis.analysisType]}`
        );
      }
      dispatch(removeAnalysis(id));
    },
    [activeAnalysis, dispatch, navigate]
  );

  const handleDeleteAll = useCallback(() => {
    if (activeAnalysis) {
      navigate(`/analyses/${AnalysisTypeToSlug[activeAnalysis.analysisType]}`);
    }
    dispatch(removeAllAnalyses());
  }, [activeAnalysis, dispatch, navigate]);

  const handleAnalysisTitleChange = useCallback(
    (newName: string, analysisId: string): void => {
      dispatch(renameAnalysis({ analysisId, newName }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (activeAnalysis) {
      handleNavigateAnalysis(activeAnalysis.uuid);
    }
  }, [activeAnalysis, handleNavigateAnalysis]);

  return {
    onAnalysisClick: handleNavigateAnalysis,
    onAnalysisDelete: handleOnDelete,
    onDeleteAllAnalyses: handleDeleteAll,
    onAnalysisTitleChange: handleAnalysisTitleChange,
  };
};

export const useAnalysisListTabHandler = () => {
  const navigate = useNavigate();

  const handleTabChange = useCallback(
    (_: any, value: AnalysisType) => {
      navigate(`/analyses/${AnalysisTypeToSlug[value]}`);
    },
    [navigate]
  );

  return {
    handleTabChange,
  };
};
