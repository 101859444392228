import { useCallback, useEffect, useMemo, useRef } from 'react';

import { SortFilterSearchQueryParams } from 'types/QueryParams';
import { ExtractedKeys } from 'types/Utilities';

import { selectPersistedQueryParamsById } from 'reducers/tableSettings/selectors';
import { saveQueryParams } from 'reducers/tableSettings/slice';
import { useAppDispatch, useAppSelector } from 'store/store';

import { TableId } from 'utils/hooks/tableIds';
import { QueryParams, useQueryParams } from 'utils/hooks/useQueryParams';

type GenericUUIDObject = {
  uuid: string;
};
export const useGenericRowIdMapper = <T extends GenericUUIDObject>() =>
  useCallback((row: T) => row.uuid, []);

const compareObjectsAsJSON = <T>(a: T, b: T): boolean => {
  // If both a and b are undefined, we should return true as well
  if (a == null && b == null) return true;
  return JSON.stringify(a) === JSON.stringify(b);
};

const extractByKey = <T, KTE extends ReadonlyArray<keyof T>>(
  obj: T,
  keys: KTE
): ExtractedKeys<T, KTE> => {
  const extracted = {} as ExtractedKeys<T, KTE>;
  for (const key of keys) {
    extracted[key] = obj[key];
  }
  return extracted as ExtractedKeys<T, KTE>;
};

type QueryParamsWithPersistence<TFilter> = QueryParams<TFilter> & {
  paramsToPersist: (keyof SortFilterSearchQueryParams<TFilter>)[];
  tableId: TableId;
};

export function useQueryParamsWithPersistence<TFilter extends Partial<{}>>(
  queryParamsWithPersistence: QueryParamsWithPersistence<TFilter>
) {
  const { paramsToPersist, rowsPerPageId, tableId, ...initialQueryParams } =
    queryParamsWithPersistence;

  // Since the tableId and paramsToPersist should never change after hook has been called
  // We can just throw them into states/or refs even.
  const paramsToPersistRef = useRef(paramsToPersist);

  const dispatch = useAppDispatch();

  // Get the persisted Params from the store
  const persistedQueryParams = useAppSelector((state) =>
    selectPersistedQueryParamsById(state, tableId)
  );

  // Merge persisted Params with defaults
  // And call the useQueryParams with that
  const queryParamReturn = useQueryParams({
    ...initialQueryParams,
    ...persistedQueryParams,
    rowsPerPageId,
  });

  // Extract the QPs we should persist
  const queryParams = queryParamReturn[0];
  const queryParamsToPersist = useMemo(() => {
    const paramsToPersist = paramsToPersistRef.current;
    return extractByKey(queryParams, paramsToPersist);
  }, [queryParams]);
  useEffect(() => {
    // Check if the persistedQPs really don't match the ones we should have stored
    if (compareObjectsAsJSON(queryParamsToPersist, persistedQueryParams as any))
      return;

    // If something has really changed, update the store
    dispatch(
      saveQueryParams({
        tableId,
        queryParams: queryParamsToPersist,
      })
    );
  }, [
    queryParamsToPersist,
    persistedQueryParams,
    tableId,
    initialQueryParams,
    dispatch,
  ]);

  return queryParamReturn;
}
