import { useCallback, useEffect, useRef, useState } from 'react';

import { ExperimentInfo } from 'types/APITypes/APITypes';
import {
  errorCallback,
  ExperimentListHookOptions,
  ExperimentListHookReturn,
} from 'types/ListHookTypes';
import { TabFilteredExperimentQueryParams } from 'types/QueryParams';

import { getCancelTokenSource } from 'api/CASAPI';
import axios from 'axios';

import { usePaginatedListWithQueryParams } from 'utils/pagination/pagination';
import {
  ExperimentInfoErrorTitle,
  ExperimentInfoRetrievalBackendError,
  ExperimentInfoRetrievalUnknownError,
  ExperimentListErrorTitle,
  ExperimentListRetrievalBackendError,
  ExperimentListRetrievalUnknownError,
  ExperimentStatusErrorTitle,
  ExperimentStatusesErrorTitle,
  ExperimentStatusesRetrievalBackendError,
  ExperimentStatusesRetrievalUnknownError,
  ExperimentStatusRetrievalBackendError,
  ExperimentStatusRetrievalUnknownError,
  UpdateExperimentInfoErrorTitle,
  UpdateExperimentInfoRetrievalBackendError,
  UpdateExperimentInfoRetrievalUnknownError,
} from 'utils/utils';

import {
  getExperimentById,
  getExperiments,
  getExperimentStatuses,
  getExperimentTypes,
  updateExperiment,
} from '../experiments';

import log from 'loglevel';

export const useExperimentList = (
  options: ExperimentListHookOptions = {},
  queryParams: TabFilteredExperimentQueryParams
): ExperimentListHookReturn => {
  const { onError } = options;

  const onBackendError = useCallback(() => {
    onError?.(ExperimentListErrorTitle, ExperimentListRetrievalBackendError);
  }, [onError]);

  const onUnexpectedError = useCallback(() => {
    onError?.(ExperimentListErrorTitle, ExperimentListRetrievalUnknownError);
  }, [onError]);

  const { elements, loading, total } = usePaginatedListWithQueryParams(
    {
      getElements: getExperiments,
      onBackendError,
      onUnexpectedError,
    },
    queryParams
  );

  return {
    experimentListLoading: loading,
    experimentList: elements,
    totalExperiments: total,
  };
};

interface ExperimentTypeHookOptions {
  onError?: errorCallback;
}

interface ExperimentTypeHookReturn {
  experimentTypesLoading: boolean;
  experimentTypeList: string[];
}

export const useExperimentTypes = ({
  onError,
}: ExperimentTypeHookOptions = {}): ExperimentTypeHookReturn => {
  const [experimentTypesLoading, setExperimentTypesLoading] = useState(false);
  const [experimentTypeList, setExperimentTypeList] = useState<string[]>([]);

  const isMountedRef = useRef(false);

  useEffect(() => {
    isMountedRef.current = true;
    const cancelTokenSource = getCancelTokenSource();

    const fetchExperimentTypeList = () => {
      setExperimentTypesLoading(true);
      getExperimentTypes({ cancelToken: cancelTokenSource.token })
        .then(({ status, body }) => {
          if (!isMountedRef.current) return;

          setExperimentTypesLoading(false);

          if (status !== 200) {
            onError?.(
              ExperimentStatusErrorTitle,
              ExperimentStatusRetrievalBackendError
            );
            return;
          }
          if (body === null) {
            return;
          }
          setExperimentTypeList(body);
        })
        .catch((error) => {
          if (axios.isCancel(error)) return;
          if (!isMountedRef.current) return;

          setExperimentTypesLoading(false);
          onError?.(
            ExperimentStatusErrorTitle,
            ExperimentStatusRetrievalUnknownError
          );
          log.error(error);
        });
    };

    fetchExperimentTypeList();

    return () => {
      cancelTokenSource.cancel();
      isMountedRef.current = false;
    };
  }, [onError]);

  return {
    experimentTypesLoading,
    experimentTypeList,
  };
};

interface ExperimentStatusHookOptions {
  onError?: errorCallback;
}
interface ExperimentStatusHookReturn {
  experimentStatusesLoading: boolean;
  experimentStatusList: string[];
}

export const useExperimentStatuses = ({
  onError,
}: ExperimentStatusHookOptions = {}): ExperimentStatusHookReturn => {
  const [experimentStatusesLoading, setExperimentStatusesLoading] =
    useState(false);
  const [experimentStatusList, setExperimentStatusList] = useState<string[]>(
    []
  );

  const isMountedRef = useRef(false);

  useEffect(() => {
    isMountedRef.current = true;
    const cancelTokenSource = getCancelTokenSource();

    const fetchExperimentStatusList = () => {
      setExperimentStatusesLoading(true);
      getExperimentStatuses({ cancelToken: cancelTokenSource.token })
        .then(({ status, body }) => {
          if (!isMountedRef.current) return;

          setExperimentStatusesLoading(false);
          if (status !== 200) {
            onError?.(
              ExperimentStatusesErrorTitle,
              ExperimentStatusesRetrievalBackendError
            );
            return;
          }
          if (!body) return;
          setExperimentStatusList(body);
        })
        .catch((error) => {
          if (axios.isCancel(error)) return;
          if (!isMountedRef.current) return;

          setExperimentStatusesLoading(false);
          onError?.(
            ExperimentStatusesErrorTitle,
            ExperimentStatusesRetrievalUnknownError
          );
          log.error(error);
        });
    };

    fetchExperimentStatusList();

    return () => {
      cancelTokenSource.cancel();
      isMountedRef.current = false;
    };
  }, [onError]);

  return {
    experimentStatusesLoading,
    experimentStatusList,
  };
};

type ExperimentHookOptions = {
  onError?: errorCallback;
  id?: string;
};
type ExperimentHookReturn = {
  experiment: ExperimentInfo | null;
  experimentLoading: boolean;
};

export const useExperiment = (
  options: ExperimentHookOptions
): ExperimentHookReturn => {
  const { onError, id } = options;
  const [experimentLoading, setExperimentLoading] = useState(false);
  const [experiment, setExperiment] = useState<null | ExperimentInfo>(null);

  const isMountedRef = useRef(false);

  /* Register hook, which get's called on Component-mount */
  useEffect(() => {
    isMountedRef.current = true;
    const cancelTokenSource = getCancelTokenSource();

    function fetchExperiment(): void {
      if (!id) return;

      setExperimentLoading(true);
      getExperimentById(id, { cancelToken: cancelTokenSource.token })
        .then(({ status, body }) => {
          if (!isMountedRef.current) return;
          setExperimentLoading(false);
          if (status !== 200) {
            onError?.(
              ExperimentInfoErrorTitle,
              ExperimentInfoRetrievalBackendError
            );
            setExperiment(null);
            return;
          }
          setExperiment(body);
        })
        .catch((err) => {
          if (axios.isCancel(err)) return;
          if (!isMountedRef.current) return;
          setExperimentLoading(false);
          onError?.(
            ExperimentInfoErrorTitle,
            ExperimentInfoRetrievalUnknownError
          );
          setExperiment(null);
          log.error(err);
        });
    }

    fetchExperiment();

    return () => {
      isMountedRef.current = false;
      cancelTokenSource.cancel();
    };
  }, [id, onError]);

  return {
    experiment,
    experimentLoading,
  };
};

interface UpdateExperimentOptions {
  id: string;
  onError?: errorCallback;
}

interface UpdateExperimentHookReturn {
  update: (newTitle: string) => void;
}

export const useUpdateExperiment = (
  options: UpdateExperimentOptions
): UpdateExperimentHookReturn => {
  const { id, onError } = options;

  const update = (newTitle: string) => {
    if (!id) return;
    updateExperiment(id, { annotation: newTitle })
      .then(({ status, body }) => {
        if (status !== 200) {
          onError?.(
            UpdateExperimentInfoErrorTitle,
            UpdateExperimentInfoRetrievalBackendError
          );
          return;
        }
      })
      .catch((err) => {
        onError?.(
          UpdateExperimentInfoErrorTitle,
          UpdateExperimentInfoRetrievalUnknownError
        );
      });
  };

  return { update };
};
