import React from 'react';

import {
  DataTableHeadRendererProps,
  DataTableRowBase,
} from 'components/DataTable/types/types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useTableErrorContext } from 'components/DataTable/context/TableErrorContext';

import { useStyles } from './styles';

import { Severity } from 'utils/utils';

import DataTableHeadSortButton from '../DataTableHeadSortButton/DataTableHeadSortButton';

function DataTableHead<TRow extends DataTableRowBase, TRowId>(
  props: DataTableHeadRendererProps<TRow, TRowId>
): JSX.Element {
  const { column, tableSorting } = props;
  const { key, columnId, label, sortLabel, disableSort } = column;
  const { classes, cx } = useStyles();

  // Instead of getting the data some weird way, just use the TableErrorContext Directly.
  const {
    columnId: errorContextColumnId,
    errorSeverity: errorContextSeverity,
  } = useTableErrorContext();

  const errorSeverity =
    errorContextColumnId === columnId ? errorContextSeverity : undefined;

  const sortDirection =
    tableSorting.sort === sortLabel ? tableSorting.sortDirection : undefined;

  return (
    <th
      data-testid="table-head"
      className={cx({
        [classes.tableHead]: true,
        [classes.tableHeadEditableColumn]: column.editable,
        warning: errorSeverity === Severity.WARNING,
        error: errorSeverity === Severity.ERROR,
      })}
    >
      <Box display="flex" height="100%">
        <Typography
          data-testid="table-head-label"
          variant="subtitle2"
          style={{
            width: 'min-content',
            marginBottom: 'auto',
            fontWeight: 600,
            lineHeight: '24px',
            letterSpacing: '0.17px',
          }}
        >
          {label ?? key}
        </Typography>
        {!disableSort && (
          <Box mb="auto" height="20px">
            <DataTableHeadSortButton
              sortDirection={sortDirection}
              onClick={props.onSortClick}
            />
          </Box>
        )}
      </Box>
    </th>
  );
}

export default React.memo(DataTableHead) as typeof DataTableHead;
