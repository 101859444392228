import React, { useEffect, useState, useCallback } from 'react';

import { InnerExperimentSelectorProps } from './types';
import { ExperimentInfo } from 'types/APITypes/APITypes';
import { ExperimentFilter } from 'types/Filters';

import { Box } from '@mui/material';

import DataTableSearch from 'components/DataTableAccessories/DataTableSearch/DataTableSearch';

import { useInitialRender } from 'utils/hooks/hooks';
import { rowsPerPageIds } from 'utils/hooks/tableIds';
import { useQueryParams } from 'utils/hooks/useQueryParams';
import { useGenericRowIdMapper } from 'utils/table/table';
import { debounce } from 'utils/utils';

import { useRowSelection } from '../DataTable/hooks/useRowSelection';
import ExperimentTable from '../ExperimentTable/ExperimentTable';
import ExperimentTableFilter from '../ExperimentTableFilter/ExperimentTableFilter';

export function InnerExperimentSelector(
  props: InnerExperimentSelectorProps
): JSX.Element {
  const { onExperimentQueryParamsChange, onSelectionChanged, experiments } =
    props;
  const rowIdMapper = useGenericRowIdMapper();
  const [showSelectedExperimentsOnly, setShowSelectedExperimentsOnly] =
    useState(false);
  const [initialSelection, setInitialSelection] = useState<ExperimentInfo[]>(
    []
  );
  const [currentSelectionIndices, setCurrentSelectionIndices] = useState([
    ...initialSelection.keys(),
  ]);
  const isInitialRender = useInitialRender();

  const {
    selection,
    selectedIndices,
    handleChange: handleExperimentSelectionChange,
    clear: handleExperimentSelectionClear,
  } = useRowSelection<ExperimentInfo, string>({
    rows: experiments,
    rowIdMapper,
    initialSelection: props.selected ?? [],
  });

  const [
    queryParams,
    handleExperimentSortChange,
    handleExperimentFilterChange,
    handleExperimentSearchChange,
    handlePageChange,
    currentPage,
  ] = useQueryParams<ExperimentFilter>({
    ...props.experimentQueryParams,
    filtering: {
      ...props.experimentQueryParams?.filtering,
      ...props.fixedFilters,
    },
    rowsPerPageId: rowsPerPageIds.experiment,
  });

  useEffect(() => {
    if (isInitialRender) {
      setInitialSelection(selection);
    }
    let indices: number[] = [];
    const expIds = selection.map((exp) => exp.uuid);
    initialSelection.forEach((exp, idx) => {
      if (expIds.includes(exp.uuid)) {
        indices.push(idx);
      }
    });
    setCurrentSelectionIndices(indices);
  }, [selection, initialSelection, isInitialRender]);

  useEffect(() => {
    onExperimentQueryParamsChange(queryParams);
  }, [onExperimentQueryParamsChange, queryParams]);

  // Update outer selection whenever the selection here changes
  useEffect(() => {
    onSelectionChanged?.(selection);
  }, [selection, onSelectionChanged]);

  const handleShowSelectedOnlyChange = useCallback(() => {
    setShowSelectedExperimentsOnly((show) => !show);
  }, []);

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      data-testid="experiment-selector"
    >
      <Box
        textAlign="right"
        display="flex"
        justifyContent="flex-end"
        height="3.5rem"
      >
        <ExperimentTableFilter
          container={props.experimentFilterContainer}
          experimentTypes={props.experimentTypes}
          experimentStatuses={props.experimentStatuses}
          fileTypes={props.fileTypes}
          onChange={handleExperimentFilterChange}
          fixedFilters={props.fixedFilters}
        />
        <DataTableSearch onChange={debounce(handleExperimentSearchChange)} />
      </Box>
      <ExperimentTable
        isLoading={props.isLoading}
        experimentSelection={
          showSelectedExperimentsOnly
            ? currentSelectionIndices
            : selectedIndices
        }
        experimentSelectionCount={selection.length}
        experiments={
          showSelectedExperimentsOnly ? initialSelection : props.experiments
        }
        totalExperiments={
          showSelectedExperimentsOnly
            ? selection.length
            : props.totalExperiments
        }
        onPageChange={handlePageChange}
        currentPage={currentPage}
        onExperimentSortChange={handleExperimentSortChange}
        onExperimentSelectionChange={handleExperimentSelectionChange}
        onExperimentSelectionClear={handleExperimentSelectionClear}
        enableShowSelectedOnly
        showSelectedOnly={showSelectedExperimentsOnly}
        onShowSelectedOnlyChange={handleShowSelectedOnlyChange}
      />
    </Box>
  );
}

export default React.memo(InnerExperimentSelector);
