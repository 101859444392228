import { ErrorShape, Severity } from './types';

import { analysisNameforbiddenCharacters } from './constants';

export const validateAnalysisName = (name: string): ErrorShape => {
  if (analysisNameforbiddenCharacters.some((c) => name.includes(c))) {
    return {
      error: true,
      errorSeverity: Severity.ERROR,
      message: `Following characters are not allowed ${analysisNameforbiddenCharacters.join(
        ', '
      )}`,
    };
  }
  if (name.trim() === '') {
    return {
      error: true,
      errorSeverity: Severity.ERROR,
      message: 'Name should not be empty',
    };
  }

  return {
    error: false,
    message: '',
  };
};
