import { useCallback, useMemo } from 'react';

import { RowIDMapper } from '../types/types';

export type SelectionRecord<TRow, TRowId> = {
  id: TRowId;
  data: TRow;
  selected: boolean;
};

export type SelectionMap<TRow, TRowId extends string> = Record<
  TRowId,
  SelectionRecord<TRow, TRowId>
>;

type useRowSelectionConversionReturn<TRow, TRowId extends string> = {
  mapFromDataTable: (indices: number[]) => SelectionMap<TRow, TRowId>;
  mapToDataTable: (selection: SelectionMap<TRow, TRowId>) => number[];
};

export function useRowSelectionConversion<
  TRow extends object,
  TRowId extends string,
>(
  rows: TRow[],
  getRowId: RowIDMapper<TRow, TRowId>
): useRowSelectionConversionReturn<TRow, TRowId> {
  const rowIdIndexMap = useMemo(() => {
    return rows.reduce(
      (map, row, rowIndex) => {
        map[getRowId(row)] = rowIndex;
        return map;
      },
      {} as Record<TRowId, number>
    );
  }, [rows, getRowId]);

  // Mapping functions
  const mapFromDataTable = useCallback(
    (indices: number[]): SelectionMap<TRow, TRowId> => {
      return rows.reduce(
        (map, row, rowIndex) => {
          const selected = indices.includes(rowIndex);
          const id = getRowId(row);
          map[id] = {
            id,
            data: row,
            selected,
          };
          return map;
        },
        {} as SelectionMap<TRow, TRowId>
      );
    },
    [rows, getRowId]
  );

  const mapToDataTable = useCallback(
    (selection: SelectionMap<TRow, TRowId>): number[] => {
      const indices = [];
      for (const id in rowIdIndexMap) {
        if (selection[id]?.selected) {
          indices.push(rowIdIndexMap[id]);
        }
      }

      return indices;
    },
    [rowIdIndexMap]
  );

  return {
    mapFromDataTable,
    mapToDataTable,
  };
}
