import {
  ReportedLogLevelToLogLevelNumber,
  ReportedLogLevel,
} from 'types/APITypes/APITypes';

import config from 'config/Config';

import { LogOnServer } from 'api/logging';

import { getAuthToken } from 'utils/auth/auth';

import log, { LogLevelNames, LogLevelNumbers } from 'loglevel';
import Rollbar from 'rollbar';

const originalFactory = log.methodFactory;

function logLevelNumberToReportedLogLevel(
  input: LogLevelNumbers
): ReportedLogLevel {
  if (input === 0) {
    return ReportedLogLevel.Debug;
  }
  if (input === 1) {
    return ReportedLogLevel.Debug;
  }
  if (input === 2) {
    return ReportedLogLevel.Info;
  }
  if (input === 3) {
    return ReportedLogLevel.Warning;
  }
  if (input === 4) {
    return ReportedLogLevel.Error;
  }
  if (input === 5) {
    return ReportedLogLevel.Error;
  }
  throw new TypeError('all cases should be covered');
}

function logLevelNameToLogLevelNumber(name: LogLevelNames): LogLevelNumbers {
  if (name === 'trace') return 0;
  if (name === 'debug') return 1;
  if (name === 'info') return 2;
  if (name === 'warn') return 3;
  if (name === 'error') return 4;
  throw new TypeError('all cases should be covered');
}

export function logOnServerIfLoggedIn(
  rollbar: Rollbar,
  localLogLevel: ReportedLogLevel = config.localLogLevel,
  serverLogLevel: ReportedLogLevel = config.serverLogLevel
) {
  log.methodFactory = function (methodName, logLevel, loggerName) {
    const rawMethod = originalFactory(methodName, logLevel, loggerName);
    const methodLevel = logLevelNameToLogLevelNumber(methodName);

    if (methodLevel >= ReportedLogLevelToLogLevelNumber(serverLogLevel))
      return function (message) {
        if (logLevel >= log.levels.ERROR) {
          rollbar.critical(message);
        }

        if (getAuthToken() !== null) {
          LogOnServer({
            message,
            log_level: logLevelNumberToReportedLogLevel(methodLevel),
          }).catch((error) =>
            rawMethod(
              `logging of message '${message}' on server failed. error : ${error}`
            )
          );
        }
        rawMethod(message);
      };
    return rawMethod;
  };
  log.setLevel(ReportedLogLevelToLogLevelNumber(localLogLevel));
}

export function resetLogLevelConfig(
  serverLogLevel: ReportedLogLevel = config.serverLogLevel
) {
  log.methodFactory = originalFactory;
  log.setLevel(ReportedLogLevelToLogLevelNumber(serverLogLevel));
}
