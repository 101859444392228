import {
  ExperimentId,
  ExperimentInfo,
  ExperimentInfoPatch,
  GenericAPIRequestMethod,
} from 'types/APITypes/APITypes';
import { PaginatedList } from 'types/APITypes/pagination';
import { TabFilteredExperimentQueryParams } from 'types/QueryParams';

import { applyDefaultSorting, toUrlSearchParams } from 'utils/api/api';
import { getUrl } from 'utils/helpers/requestbuilder';

import { axiosInstance, parseAPIResponse } from './CASAPI';
import { parseFileInfoFromAPI } from './files';

// Parsing

export const parseExperimentFromAPI = <
  ExperimentInfoType extends ExperimentInfo,
>(
  experiment: ExperimentInfoType
): ExperimentInfoType => ({
  ...experiment,
  containedIn: parseFileInfoFromAPI(experiment.containedIn),
});

// API Calls

export const getExperiments: GenericAPIRequestMethod<
  TabFilteredExperimentQueryParams,
  never,
  PaginatedList<ExperimentInfo>
> = async (qp, requestConfig) => {
  const queryParams = applyDefaultSorting(qp, {
    sort: 'fileCreatedAt',
    sortDirection: 'descending',
  });
  let parsedFileTypeFilter = undefined;
  if (queryParams?.filtering?.fileType !== undefined) {
    if (typeof queryParams.filtering.fileType === 'string') {
      parsedFileTypeFilter = queryParams.filtering.fileType.toLowerCase();
    } else {
      parsedFileTypeFilter = queryParams.filtering.fileType.map((t) =>
        t.toLowerCase()
      );
    }
  }
  const response = await axiosInstance.get(getUrl('experimentList'), {
    ...requestConfig,
    params: toUrlSearchParams({
      limit: queryParams?.limit,
      offset: queryParams?.offset,
      sort: queryParams?.sorting?.sort,
      sortDirection: queryParams?.sorting?.sortDirection,
      experimentName: queryParams?.filtering?.experimentName,
      measurementName: queryParams?.filtering?.measurementName,
      experimentType: queryParams?.filtering?.experimentType,
      experimentStatus: queryParams?.filtering?.experimentStatus,
      filename: queryParams?.filtering?.filename,
      file_types: parsedFileTypeFilter,
      searchValue: queryParams?.searchValue,
    }),
  });
  return parseAPIResponse(response, (res: PaginatedList<ExperimentInfo>) => {
    return {
      total: res.total,
      data: res.data.map(parseExperimentFromAPI),
    };
  });
};

export const updateExperiment: GenericAPIRequestMethod<
  string,
  ExperimentInfoPatch,
  null
> = async (
  experimentId: string,
  updatedField: ExperimentInfoPatch,
  requestConfig
) => {
  const response = await axiosInstance.patch(
    getUrl('experimentUpdate', { id: experimentId }),
    {
      annotation: updatedField.annotation,
      measurementName: updatedField.measurementName,
    },
    { ...requestConfig }
  );
  return parseAPIResponse(response);
};

export const getExperimentTypes: GenericAPIRequestMethod<
  never,
  never,
  string[]
> = async (requestConfig) => {
  const response = await axiosInstance.get(getUrl('experimentTypeList'), {
    ...requestConfig,
  });
  return parseAPIResponse(response);
};

export const getExperimentStatuses: GenericAPIRequestMethod<
  never,
  never,
  string[]
> = async (requestConfig) => {
  const response = await axiosInstance.get(getUrl('experimentStatusList'), {
    ...requestConfig,
  });

  return parseAPIResponse(response);
};

export const getExperimentById: GenericAPIRequestMethod<
  ExperimentId,
  never,
  ExperimentInfo
> = async (experimentId, requestConfig) => {
  const response = await axiosInstance.get(
    getUrl('experiment', { id: experimentId }),
    {
      ...requestConfig,
    }
  );
  return parseAPIResponse(response, parseExperimentFromAPI);
};
