import { Theme } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  appBar: {
    zIndex: theme.zIndex.appToolbar,
    height: theme.sizing?.toolbarHeight,
  },
  toolbar: {
    margin: '8px 0',
    padding: '0 12px',
    [theme.breakpoints.up('md')]: {
      padding: '0 4px',
    },
  },
  logo: {
    // The logo is 24x15px but should be padded to 48x48
    margin: '16.5px 12px',
  },
  badge: {
    transform: 'scale(1) translate(25%, -25%)',
    outline: '1px solid #fff',
  },
  info: {
    backgroundColor: '#84368A',
  },
  success: {
    backgroundColor: '#08893E',
  },
  error: {
    backgroundColor: '#D60404',
  },
  warning: {
    backgroundColor: '#ED7104',
  },
}));
