import { createSlice } from '@reduxjs/toolkit';

export type SnackbarState = {
  open: boolean;
  message: string;
};

const initialState: SnackbarState = {
  open: false,
  message: '',
};

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState: initialState,
  reducers: {
    openSnackbar: (state, action) => {
      state.open = true;
      state.message = action.payload;
    },
    closeSnackbar: () => initialState,
  },
});

export const { openSnackbar, closeSnackbar } = snackbarSlice.actions;

export const snackbarReducer = snackbarSlice.reducer;
