import React, { useCallback, useEffect, useMemo } from 'react';

import { AnalysisEditModalProps } from './types';
import {
  ExperimentInfo,
  ExperimentId,
  ExperimentType,
} from 'types/APITypes/APITypes';

import { selectAnalysisById } from 'reducers/analysis/analysisSelectors';
import { setLinkedExperiments } from 'reducers/analysis/thunk';
import { useAppDispatch, useAppSelector } from 'store/store';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';

import ExperimentSelector from 'components/ExperimentSelector/ExperimentSelector';

import { useStyles } from './styles';

import { useErrorCallback } from 'utils/notifications/notifications';
import { checkStringArrayEquality } from 'utils/utils';

export function AnalysisEditModal(props: AnalysisEditModalProps): JSX.Element {
  const { classes } = useStyles();
  const { experimentSelectLimit = 10, onClose, editId } = props;

  const dispatch = useAppDispatch();
  const analysis = useAppSelector((state) => selectAnalysisById(state, editId));
  const onError = useErrorCallback();

  const [experiments, setExperiments] = React.useState<ExperimentInfo[]>([]);

  const analysisExperimentIds = useMemo((): ExperimentId[] => {
    return analysis?.experiments.map((e) => e.uuid).sort() ?? [];
  }, [analysis]);

  const allowedExperimentType = useMemo((): ExperimentType => {
    const experimentTypes = analysis?.experiments.map((e) => e.experimentType);
    const allowedTypes = [...new Set(experimentTypes ?? [])];
    return allowedTypes[0];
  }, [analysis]);

  // Computed State
  const hasChanges = useMemo(() => {
    return !checkStringArrayEquality(
      experiments.map((e) => e.uuid).sort(),
      analysisExperimentIds
    );
  }, [analysisExperimentIds, experiments]);

  const selectionValid = useMemo(() => {
    return (
      experiments.length > 0 && experiments.length <= experimentSelectLimit
    );
  }, [experiments, experimentSelectLimit]);

  const canBeSaved = useMemo(
    () => hasChanges && selectionValid,
    [hasChanges, selectionValid]
  );

  // Callbacks
  const handleLinkedExperimentsChanged = useCallback(
    (experiments: ExperimentInfo[]): void => {
      setExperiments(experiments);
    },
    [setExperiments]
  );

  const handleCommit = useCallback(async (): Promise<void> => {
    if (analysis == null) {
      onClose();
      return;
    }
    dispatch(
      setLinkedExperiments({ analysisId: analysis.uuid, experiments, onError })
    );
    onClose();
  }, [analysis, onError, dispatch, experiments, onClose]);

  // Update Experiments when the analysis changes from the outside
  useEffect(() => {
    setExperiments(analysis?.experiments ?? []);
  }, [analysis]);

  return (
    <Dialog
      open={props.open}
      onClose={onClose}
      classes={{
        paper: classes.root,
      }}
      aria-labelledby="modal-edit-analysis-title"
      aria-describedby="modal-edit-analysis-description"
      data-testid="analysis-edit-modal"
    >
      <DialogTitle
        id="analysis-edit-modal-title"
        className={classes.dialogTitle}
      >
        Edit Analysis
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" height="100%">
          <DialogContentText id="analysis-edit-modal-description">
            Choose to add or remove Experiments to or from your Analysis
          </DialogContentText>
          <Box display="flex" flexDirection="column" height="100%">
            <ExperimentSelector
              selected={analysis?.experiments}
              onSelectionChanged={handleLinkedExperimentsChanged}
              experimentFilterContainer={() =>
                document.getElementsByClassName('MuiDialog-container')[0] ??
                null
              }
              fixedFilters={{
                experimentType: allowedExperimentType,
              }}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box m="auto" ml="0">
          <Typography component="p" variant="caption">
            Select between 1 and {experimentSelectLimit} Experiments
          </Typography>
        </Box>
        <Button
          color="primary"
          size="medium"
          onClick={onClose}
          data-testid="analysis-edit-modal-cancel-button"
        >
          Cancel
        </Button>
        <Button
          data-testid="analysis-edit-modal-apply-changes-button"
          variant="contained"
          color="primary"
          size="medium"
          disabled={!canBeSaved}
          onClick={handleCommit}
          disableElevation
        >
          Apply Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AnalysisEditModal;
