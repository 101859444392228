import React, { useCallback } from 'react';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';

import { CustomTextField } from 'components/Inputs/CustomTextField';

import { useStyles } from './styles';

type DataTableFilterSelectProps = {
  id?: string;
  name: string;
  label: string;
  values: string[];
  value?: string;
  fixedValue?: string;
  onChange: (name: string, value?: string) => void;
};

function DataTableFilterSelect(props: DataTableFilterSelectProps) {
  const { classes } = useStyles();
  const { onChange } = props;

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      onChange(event.target.name, value !== '' ? value : undefined);
    },
    [onChange]
  );

  const fixed = props.fixedValue !== undefined;

  return (
    <CustomTextField
      select
      disabled={fixed}
      id={props.id}
      name={props.name}
      label={props.label}
      value={props.value ?? ''}
      onChange={handleChange}
      className={classes.formControl}
      inputProps={{ 'data-testid': `${props.label}` }}
      SelectProps={{
        MenuProps: {
          disablePortal: true,
        },
      }}
      fullWidth
    >
      <MenuItem key={0} value={''}>
        <Box component="span" visibility="hidden">
          Disable
        </Box>
      </MenuItem>
      {props.values.map((value, index) => (
        <MenuItem key={index + 1} value={value}>
          {value}
        </MenuItem>
      ))}
    </CustomTextField>
  );
}

export default DataTableFilterSelect;
