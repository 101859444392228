export const analysisNameforbiddenCharacters = [
  '~',
  '#',
  '%',
  '&',
  '*',
  '{',
  '}',
  '\\',
  ':',
  '<',
  '>',
  '?',
  '/',
  '|',
  '"',
];
