import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AccountInformation } from 'types/APITypes/APITypes';

import log from 'loglevel';

type AuthError = {
  title: string;
  message: string;
};

type Auth = {
  isAuthenticated: boolean;
  token: null | string;
};

export type AuthenticationState = {
  isLoading: boolean;
  error: AuthError | null;
  accountInformation: AccountInformation | null;
} & Auth;

export const initialState: AuthenticationState = {
  isAuthenticated: false,
  token: null,
  isLoading: false,
  error: null,
  accountInformation: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state: AuthenticationState, action: PayloadAction<Auth>) => {
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        token: action.payload.token,
      };
    },
    setAuthError: (
      state: AuthenticationState,
      action: PayloadAction<AuthError | null>
    ) => {
      if (action.payload) {
        log.error(
          `AuthReducer ERROR: ${action.payload.title} ${action.payload.message}`
        );
      }
      return { ...state, error: action.payload };
    },
    setCurrentUser: (
      state: AuthenticationState,
      action: PayloadAction<AccountInformation | null>
    ) => {
      return { ...state, accountInformation: action.payload };
    },
    setLoading: (
      state: AuthenticationState,
      action: PayloadAction<boolean>
    ) => {
      return { ...state, isLoading: action.payload };
    },
    resetAuthSlice: () => ({ ...initialState }),
  },
});

export const authActions = authSlice.actions;

export const authReducer = authSlice.reducer;
