import React from 'react';

import {
  DataTableBodyRendererProps,
  DataTableFormatOutput,
  DataTableRowBase,
} from 'components/DataTable/types/types';
import { DeepPrefixedKeys, DeepPrefixedSlice } from 'types/Utilities';

import { Tooltip, Typography } from '@mui/material';
import Box from '@mui/material/Box';

import { useStyles } from './styles';

export type DataTableCellProps<
  TRow extends DataTableRowBase,
  TRowId,
  Name_t extends DeepPrefixedKeys<TRow> | DeepPrefixedKeys<TRow>[],
> = DataTableBodyRendererProps<TRowId, DeepPrefixedSlice<TRow, Name_t>> & {
  formatValue?: (
    value: DeepPrefixedSlice<TRow, Name_t>
  ) => DataTableFormatOutput;
};

function DataTableCell<
  TRow extends DataTableRowBase,
  TRowId,
  Name_t extends DeepPrefixedKeys<TRow> | DeepPrefixedKeys<TRow>[],
>(props: DataTableCellProps<TRow, TRowId, Name_t>) {
  const { value } = props;
  const { classes } = useStyles();
  const formatValue = props.formatValue ?? ((value: any) => value || '');
  const formattedValue =
    value === 0 || value ? formatValue(value as any) : 'N/A';
  return (
    <Box data-testid="value" className={classes.tableCellContentBox}>
      <Tooltip title={formattedValue}>
        <Typography variant="body2" className={classes.tableCellPara}>
          {formattedValue}
        </Typography>
      </Tooltip>
    </Box>
  );
}

type CreateDataTableCellOptions<
  TRow extends DataTableRowBase,
  Name_t extends DeepPrefixedKeys<TRow> | DeepPrefixedKeys<TRow>[],
> = {
  formatValue?: (
    value: DeepPrefixedSlice<TRow, Name_t>
  ) => DataTableFormatOutput;
};

export function createDataTableCell<
  TRow extends DataTableRowBase,
  TRowId,
  Name_t extends DeepPrefixedKeys<TRow> | DeepPrefixedKeys<TRow>[],
>(options?: CreateDataTableCellOptions<TRow, Name_t>) {
  return (
    props: Omit<
      DataTableCellProps<TRow, TRowId, Name_t>,
      'formatValue' | 'allowZero'
    >
  ) => <DataTableCell {...props} formatValue={options?.formatValue} />;
}
