import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  icon: {
    marginLeft: '-3.5px',
    marginRight: '-3.5px',
    '&.active': {
      color: theme.palette.secondary.main,
    },
    '&.inactive': {
      color: 'rgba(14, 37, 58, 0.5)',
    },
  },
}));
