import React from 'react';

import { AnalysisId } from 'types/APITypes/APITypes';

import { Box, IconButton, ListItemButton, Typography } from '@mui/material';

import { AddIcon } from './icons';

import { useStyles } from './styles';

import { stopEvent, stopPropagation } from 'utils/utils';

import { withStyles } from 'tss-react/mui';

export const ListSubItemSpecial = withStyles(ListItemButton, {
  root: {
    '&.Mui-disabled': {
      opacity: 1,
    },
  },
});

type EditAnalysisButtonProps = {
  analysisId: AnalysisId;
  onEditExperiments: () => void;
};

function EditAnalysisButton(props: EditAnalysisButtonProps): JSX.Element {
  const { analysisId, onEditExperiments } = props;
  const { classes, cx } = useStyles();

  return (
    <ListSubItemSpecial
      onClick={onEditExperiments}
      className={classes.subItemWrapper}
      data-testid={`edit-analysis-button-${analysisId}-wrapper`}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        className={cx({
          [classes.subItemRoot]: true,
        })}
        sx={{ my: 0.5 }}
      >
        <Typography
          variant="button"
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            my: 'auto',
            color: 'primary.main',
            fontSize: '14px',
            lineHeight: '20px',
          }}
        >
          Edit experiment list
        </Typography>

        <IconButton
          aria-label="edit analysis"
          onClick={stopPropagation(onEditExperiments)}
          onMouseDown={stopEvent}
          size="large"
          sx={{ padding: 1, my: 'auto', color: 'primary.main' }}
          data-testid={`edit-analysis-button-${analysisId}`}
        >
          <AddIcon sx={{ fontSize: 20 }} />
        </IconButton>
      </Box>
    </ListSubItemSpecial>
  );
}

export default React.memo(EditAnalysisButton);
