export enum Severity {
  ERROR = 1,
  WARNING = 2,
}
export interface ErrorShape {
  error: boolean;
  message: string;
  errorSeverity?: Severity;
}

export type ValidatorFunction<T> = (input: T) => ErrorShape;
