import React, { useMemo } from 'react';

import { ExperimentTableFilterProps } from './types';

import { FileTypeTabLabel } from 'reducers/fileTypeTabs/fileTypeTabsSlice';

import DataTableFilter from 'components/DataTableAccessories/DataTableFilter/DataTableFilter';
import DataTableFilterSelect from 'components/DataTableAccessories/DataTableFilter/components/DataTableFilterSelect/DataTableFilterSelect';
import DataTableFilterTextField from 'components/DataTableAccessories/DataTableFilter/components/DataTableFilterTextField/DataTableFilterTextField';
import { useDataTableFilter } from 'components/DataTableAccessories/DataTableFilter/useDataTableFilter';

import { debounce } from 'utils/utils';

function ExperimentTableFilter(props: ExperimentTableFilterProps) {
  const [experimentFilter, handleChange, handleReset, active] =
    useDataTableFilter(props.onChange, {
      fixedFilter: props.fixedFilters,
      initialFilter: props.intialFilter,
    });

  const chooseFileTypeTabSpecificFilter = useMemo(() => {
    if (props.selectedFileTypeTab === FileTypeTabLabel.Stability) {
      return (
        <DataTableFilterTextField
          id="measurement-name"
          name="measurementName"
          label="Measurement Name"
          data-testid="measurement_name_filter_field"
          value={experimentFilter.measurementName}
          onChange={debounce(handleChange)}
        />
      );
    } else {
      return (
        <DataTableFilterSelect
          id="file-type"
          name="fileType"
          label="File Type"
          data-testid="file_type_filter_field"
          values={props.fileTypes}
          value={experimentFilter.fileType}
          fixedValue={props.fixedFilters?.fileType}
          onChange={handleChange}
        />
      );
    }
  }, [
    props.selectedFileTypeTab,
    experimentFilter.fileType,
    props.fileTypes,
    handleChange,
    experimentFilter.measurementName,
    props.fixedFilters?.fileType,
  ]);

  return (
    <>
      <DataTableFilter
        container={props.container}
        onReset={handleReset}
        active={active}
      >
        <DataTableFilterSelect
          id="experiment-status"
          name="experimentStatus"
          label="Experiment Status"
          values={props.experimentStatuses}
          value={experimentFilter.experimentStatus}
          fixedValue={props.fixedFilters?.experimentStatus}
          onChange={handleChange}
        />
        <DataTableFilterTextField
          id="experiment-name"
          name="experimentName"
          label="Experiment Name"
          value={experimentFilter.experimentName}
          fixedValue={props.fixedFilters?.experimentName}
          onChange={debounce(handleChange)}
        />
        <DataTableFilterSelect
          id="experiment-type"
          name="experimentType"
          label="Experiment Type"
          values={props.experimentTypes}
          value={experimentFilter.experimentType}
          fixedValue={props.fixedFilters?.experimentType}
          onChange={handleChange}
        />
        <DataTableFilterTextField
          data-testid="table-filter-search"
          id="filename"
          name="filename"
          label="Filename"
          value={experimentFilter.filename}
          fixedValue={props.fixedFilters?.filename}
          onChange={debounce(handleChange)}
        />
        {chooseFileTypeTabSpecificFilter}
      </DataTableFilter>
    </>
  );
}

export default ExperimentTableFilter;
