import { useState, useCallback, useMemo } from 'react';

import {
  ClickAwayListener,
  IconButton,
  InputAdornment,
  SxProps,
  TextField,
  TextFieldProps,
} from '@mui/material';

import { ErrorIcon, CloseIcon } from './icons';

import { ErrorShape, stopEvent, stopPropagation } from 'utils/utils';

import { ValidateComponentProps } from './ValidatedComponent';

type TextFieldCompositeProps = {
  label?: string;
  value?: string;
  onCancel?: () => void;
  onCommit?: (value: string) => void;
  sx?: SxProps;
  size?: TextFieldProps['size'];
  autoFocus?: TextFieldProps['autoFocus'];
  'data-testid'?: string;
} & ValidateComponentProps &
  Partial<ErrorShape>;

export function TextFieldComponent(props: TextFieldCompositeProps) {
  const [value, setValue] = useState<string>(props.value ?? '');
  const { onChange, onCancel, onCommit, error } = props;
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const changedValue = event.target.value;
      setValue(changedValue);
      onChange?.(changedValue);
    },
    [onChange]
  );

  const handleCommit = useCallback((): void => {
    if (error) {
      onCancel?.();
    } else {
      onCommit?.(value);
    }
  }, [onCommit, onCancel, error, value]);

  const handleKeydown = useCallback(
    (e: React.KeyboardEvent): void => {
      if (e.key === 'Enter') {
        handleCommit();
      } else if (e.key === 'Esc' || e.key === 'Escape') {
        onCancel?.();
      }
    },
    [onCancel, handleCommit]
  );

  const handleClose = useMemo(() => stopPropagation(onCancel), [onCancel]);
  const handleClickAway = useCallback(() => handleCommit?.(), [handleCommit]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <TextField
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeydown}
        onMouseDown={stopEvent}
        error={error}
        helperText={props.message}
        label={props.label}
        variant="outlined"
        margin="dense"
        size={props.size}
        sx={props.sx}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" style={{ padding: 0 }}>
              {error && (
                <ErrorIcon
                  sx={{
                    fontSize: 20,
                    color: (theme) => theme.palette.error.main,
                  }}
                />
              )}
              {!error && (
                <IconButton
                  onClick={handleClose}
                  onMouseDown={stopEvent}
                  edge="end"
                  size={props.size}
                  data-testid="textfield-cancel-button"
                >
                  <CloseIcon
                    sx={{
                      fontSize: 20,
                      color: (theme) => theme.palette.secondary.main,
                    }}
                  />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
        fullWidth
        autoFocus={props.autoFocus}
        data-testid={props['data-testid']}
      />
    </ClickAwayListener>
  );
}

export default TextFieldComponent;
