import React from 'react';
import { RouteProps } from 'react-router-dom';

const SignInPage = React.lazy(() => import('pages/SignIn/SignIn'));
const SignUpPage = React.lazy(() => import('pages/SignUp/SignUp'));
const ForgotPasswordPage = React.lazy(
  () => import('pages/ForgotPassword/ForgotPassword')
);

const authRoutes: RouteProps[] = [
  { path: '/sign-in', element: <SignInPage /> },
  { path: '/sign-up', element: <SignUpPage /> },
  { path: '/forgot-password', element: <ForgotPasswordPage /> },
  { path: '*', element: <SignInPage /> },
];

export default authRoutes;
