import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  AnalysisId,
  AnalysisTypeSlug,
  AnalysisTypeToSlug,
  ExperimentId,
  ExperimentInfo,
} from 'types/APITypes/APITypes';
import { errorCallback } from 'types/ListHookTypes';

import {
  createAffinityScreeningAnalysis,
  getAffinityScreeningAnalysis,
  patchAffinityScreeningAnalysis,
} from 'api/analyses/affinityScreening';
import {
  createBindingAffinityAnalysis,
  getBindingAffinityAnalysis,
  patchBindingAffinityAnalysis,
} from 'api/analyses/bindingAffinity';
import {
  createCovalentKineticsAnalyses,
  getCovalentKineticsAnalysis,
  patchCovalentKineticsAnalysis,
} from 'api/analyses/covalentKinetics';

import { openSnackbar } from 'reducers/snackbar/slice';
import type { RootState } from 'store/store';

import { analysisActions } from './slice';

const {
  setAnalysisCreationLoading,
  setAnalysisOpeningLoading,
  setAnalysisChangesPending,
  insertAnalysis,
  updateAnalysis,
  setAnalysisError,
  setActiveAnalysis,
} = analysisActions;

export const openAnalysis = createAsyncThunk<
  void,
  { analysisId: AnalysisId; analysisType: AnalysisTypeSlug },
  { state: RootState }
>(
  'analyses/openAnalysis',
  async (
    {
      analysisId,
      analysisType,
    }: { analysisId: AnalysisId; analysisType: AnalysisTypeSlug },
    { dispatch, getState }
  ) => {
    // Check if we already have the analysis locally
    const locallySynced = getState().analysis.analyses[analysisId] != null;

    // A a locally synced analysis can be activated right now
    if (locallySynced) {
      dispatch(
        setActiveAnalysis({
          analysisId,
        })
      );
    }

    // Fetch the data
    dispatch(fetchAnalysis({ analysisId, analysisType }));

    // If the analysis is not locally synced, we can activate it now
    if (!locallySynced) {
      dispatch(
        setActiveAnalysis({
          analysisId,
        })
      );
    }
  }
);

export const fetchAnalysis = createAsyncThunk<
  void,
  { analysisId: AnalysisId; analysisType: AnalysisTypeSlug },
  { state: RootState }
>(
  'analyses/fetchById',
  async (
    {
      analysisId,
      analysisType,
    }: { analysisId: AnalysisId; analysisType: AnalysisTypeSlug },
    { dispatch, getState }
  ) => {
    // Check if we're updating an analysis:
    let state = getState();
    const updatingAnalysis = state.analysis.analyses[analysisId] != null;

    if (updatingAnalysis) {
      dispatch(setAnalysisChangesPending({ id: analysisId, pending: true }));
    } else {
      dispatch(setAnalysisOpeningLoading(true));
    }
    let response = {} as any;

    switch (analysisType) {
      case 'binding-affinity':
        response = await getBindingAffinityAnalysis(analysisId);
        break;
      case 'affinity-screening':
        response = await getAffinityScreeningAnalysis(analysisId);
        break;
      case 'covalent-kinetics':
        response = await getCovalentKineticsAnalysis(analysisId);
        break;
    }

    const { status, body } = response;

    if (updatingAnalysis) {
      dispatch(setAnalysisChangesPending({ id: analysisId, pending: false }));
    } else {
      dispatch(setAnalysisOpeningLoading(false));
    }

    if (status !== 200) {
      dispatch(
        setAnalysisError({
          title: 'An error has occurred during FetchAnalysis',
          message: `The server responded with status code ${status} instead of 200.`,
        })
      );
      return;
    }

    if (!body) {
      dispatch(
        setAnalysisError({
          title: 'An error has occurred during FetchAnalysis',
          message: 'The server returned an empty response.',
        })
      );
      return;
    }

    if (updatingAnalysis) {
      dispatch(updateAnalysis(body));
    } else {
      dispatch(insertAnalysis(body));
    }
  }
);

export const removeLinkedExperiment = createAsyncThunk<
  void,
  { analysisId: AnalysisId; experimentId: ExperimentId },
  { state: RootState }
>(
  'analyses/removedLinkedExperiment',
  async ({ analysisId, experimentId }, { dispatch, getState }) => {
    // Check if the analysis exists
    const analysis = getState().analysis.analyses[analysisId];
    if (!analysis) {
      dispatch(
        setAnalysisError({
          title: 'An error has occurred during RemoveLinkedExperiment',
          message:
            'Cannot remove experiment from analysis, which is not saved locally.',
        })
      );
      return;
    }

    // If the analysis is not handled by the server
    // We can only save the changes locally
    if (
      analysis.analysisType !== 'Binding Affinity' &&
      analysis.analysisType !== 'Affinity Screening'
    ) {
      dispatch(
        analysisActions.removeLinkedExperiment({
          analysisId,
          experimentId,
        })
      );
      return;
    }

    // Check if the experiment is linked to the analysis
    if (
      !analysis.experiments.find(
        (experiment) => experiment.uuid === experimentId
      )
    ) {
      dispatch(
        setAnalysisError({
          title: 'An error has occurred during RemoveLinkedExperiment',
          message: 'Cannot remove experiment which is not in analysis.',
        })
      );
      return;
    }

    // Patch the analysis
    dispatch(setAnalysisChangesPending({ id: analysis.uuid, pending: true }));
    let status;
    const experimentIds = analysis.experiments
      .map(({ uuid }) => uuid)
      .filter((id) => id !== experimentId);

    if (analysis.analysisType === 'Binding Affinity') {
      const res = await patchBindingAffinityAnalysis(analysisId, {
        experimentIds,
      });
      status = res.status;
    }

    if (analysis.analysisType === 'Affinity Screening') {
      const res = await patchAffinityScreeningAnalysis(analysisId, {
        experimentIds,
      });
      status = res.status;
    }
    dispatch(setAnalysisChangesPending({ id: analysis.uuid, pending: false }));

    if (status !== 204) {
      dispatch(
        setAnalysisError({
          title: 'An error has occurred during RemoveLinkedExperiment',
          message: `The server responded with status code ${status} instead of 204.`,
        })
      );
      return;
    }

    // Update the analysis from the server
    dispatch(
      fetchAnalysis({
        analysisId: analysis.uuid,
        analysisType: AnalysisTypeToSlug[analysis.analysisType],
      })
    );
  }
);

export const createBindingAffinityAnalysisAction = createAsyncThunk<
  void,
  { experimentIds: ExperimentId[]; onError?: errorCallback },
  { state: RootState }
>(
  'analyses/createBindingAffinityAnalysis',
  async ({ experimentIds, onError }, { dispatch }) => {
    dispatch(setAnalysisCreationLoading(true));
    const { status, body } = await createBindingAffinityAnalysis(experimentIds);
    dispatch(setAnalysisCreationLoading(false));

    if (status !== 201) {
      const errorMessage = {
        title: 'An error has occurred during CreateBindingAffinityAnalysis',
        message: `The server responded with status code ${status} instead of 201.`,
      };

      dispatch(setAnalysisError(errorMessage));
      onError?.(errorMessage.title, errorMessage.message);

      return;
    }

    if (!body) {
      const errorMessage = {
        title: 'An error has occurred during CreateBindingAffinityAnalysis',
        message: 'The server returned an empty response.',
      };

      dispatch(setAnalysisError(errorMessage));
      onError?.(errorMessage.title, errorMessage.message);

      return;
    }

    dispatch(
      fetchAnalysis({
        analysisId: body.uuid,
        analysisType: 'binding-affinity',
      })
    );
  }
);

export const createBindingAffinityScreeningAction = createAsyncThunk<
  void,
  { experimentIds: ExperimentId[]; onError?: errorCallback },
  { state: RootState }
>(
  'analyses/createAffinityScreeningAnalysis',
  async ({ experimentIds, onError }, { dispatch }) => {
    dispatch(setAnalysisCreationLoading(true));
    const { status, body } =
      await createAffinityScreeningAnalysis(experimentIds);
    dispatch(setAnalysisCreationLoading(false));

    if (status !== 201) {
      const errorMessage = {
        title: 'An error has occurred during CreateAffinityScreeningAnalysis',
        message: `The server responded with status code ${status} instead of 201.`,
      };

      dispatch(setAnalysisError(errorMessage));
      onError?.(errorMessage.title, errorMessage.message);

      return;
    }

    if (!body) {
      const errorMessage = {
        title: 'An error has occurred during CreateAffinityScreeningAnalysis',
        message: 'The server returned an empty response.',
      };

      dispatch(setAnalysisError(errorMessage));
      onError?.(errorMessage.title, errorMessage.message);

      return;
    }
    dispatch(
      fetchAnalysis({
        analysisId: body.uuid,
        analysisType: 'affinity-screening',
      })
    );
  }
);

export const createCovalentKineticsAnalysisAction = createAsyncThunk<
  void,
  { experimentIds: ExperimentId[]; onError?: errorCallback },
  { state: RootState }
>(
  'analyses/createCovalentKineticsAnalysis',
  async ({ experimentIds, onError }, { dispatch }) => {
    dispatch(setAnalysisCreationLoading(true));
    const { status, body } =
      await createCovalentKineticsAnalyses(experimentIds);
    dispatch(setAnalysisCreationLoading(false));

    if (status !== 201) {
      const errorMessage = {
        title: 'An error has occurred during CreateCovalentKineticsAnalysis',
        message: `The server responded with status code ${status} instead of 201.`,
      };

      dispatch(setAnalysisError(errorMessage));
      onError?.(errorMessage.title, errorMessage.message);

      return;
    }

    if (!body) {
      const errorMessage = {
        title: 'An error has occurred during CreateCovalentKineticsAnalysis',
        message: 'The server returned an empty response.',
      };

      dispatch(setAnalysisError(errorMessage));
      onError?.(errorMessage.title, errorMessage.message);

      return;
    }

    const numberOfCreatedAnalyses = body.uuids.length;
    if (numberOfCreatedAnalyses === 0) {
      const errorMessage = {
        title: 'No analyses were created',
        message:
          'With the current experiment selection, no covalent kinetics analysis could be created.',
      };
      dispatch(setAnalysisError(errorMessage));
      onError?.(errorMessage.title, errorMessage.message);

      return;
    } else {
      if (numberOfCreatedAnalyses > 1) {
        dispatch(
          openSnackbar(
            `${numberOfCreatedAnalyses} covalent kinetics analyses successfully created from selection`
          )
        );
      }
      for (const analysisId of body.uuids) {
        dispatch(
          fetchAnalysis({
            analysisId,
            analysisType: 'covalent-kinetics',
          })
        );
      }
    }
  }
);

export const setLinkedExperiments = createAsyncThunk<
  void,
  {
    analysisId: AnalysisId;
    experiments: ExperimentInfo[];
    onError?: errorCallback;
  },
  { state: RootState }
>(
  'analyses/setLinkedExperiments',
  async ({ analysisId, experiments, onError }, { dispatch, getState }) => {
    // Check if the analysis exists
    const analysis = getState().analysis.analyses[analysisId];
    if (!analysis) {
      dispatch(
        setAnalysisError({
          title: 'An error has occurred during SetLinkedExperiments',
          message:
            'Cannot set experiments for analysis, which is not saved locally.',
        })
      );
      return;
    }

    // TODO: do we need to include Covalent Kinetics here?
    // Handle non server-synced analyses locally
    if (
      analysis.analysisType !== 'Binding Affinity' &&
      analysis.analysisType !== 'Affinity Screening'
    ) {
      dispatch(
        analysisActions.setLinkedExperiments({
          analysisId,
          experiments,
        })
      );
    }

    dispatch(setAnalysisChangesPending({ id: analysisId, pending: true }));
    let status;
    const experimentIds = experiments.map(({ uuid }) => uuid);

    if (analysis.analysisType === 'Binding Affinity') {
      const res = await patchBindingAffinityAnalysis(analysisId, {
        experimentIds,
      });
      status = res.status;
    } else if (analysis.analysisType === 'Affinity Screening') {
      const res = await patchAffinityScreeningAnalysis(analysisId, {
        experimentIds,
      });
      status = res.status;
    }

    dispatch(setAnalysisChangesPending({ id: analysisId, pending: false }));

    if (status !== 204) {
      const errorMessage = {
        title: 'An error has occurred during SetLinkedExperiments',
        message: `The server responded with status code ${status} instead of 204.`,
      };
      dispatch(setAnalysisError(errorMessage));
      if (onError !== undefined) {
        onError(
          `Could not update ${analysis.analysisType} Analysis`,
          errorMessage.message
        );
      }
      return;
    }

    dispatch(
      fetchAnalysis({
        analysisId: analysisId,
        analysisType: AnalysisTypeToSlug[analysis.analysisType],
      })
    );
  }
);

export const renameAnalysis = createAsyncThunk<
  void,
  { analysisId: AnalysisId; newName: string },
  { state: RootState }
>(
  'analyses/renameAnalysis',
  async ({ analysisId, newName }, { dispatch, getState }) => {
    const analysis = getState().analysis.analyses[analysisId];
    if (!analysis) {
      dispatch(
        setAnalysisError({
          title: 'An error has occurred during RenameAnalysis',
          message: 'Cannot rename an analysis, which is not saved locally.',
        })
      );
      return;
    }

    const oldName = analysis.analysisName;

    // Dispatch an update to the local data, first, then make the API-Call
    // Just so the page stays responsive
    dispatch(
      updateAnalysis({
        ...analysis,
        analysisName: newName,
      })
    );

    dispatch(setAnalysisChangesPending({ id: analysis.uuid, pending: true }));
    let response = null;
    if (analysis.analysisType === 'Binding Affinity') {
      response = await patchBindingAffinityAnalysis(analysisId, {
        analysisName: newName,
      });
    } else if (analysis.analysisType === 'Affinity Screening') {
      response = await patchAffinityScreeningAnalysis(analysisId, {
        analysisName: newName,
      });
    } else if (analysis.analysisType === 'Covalent Kinetics') {
      response = await patchCovalentKineticsAnalysis(analysisId, {
        analysisName: newName,
      });
    }

    dispatch(setAnalysisChangesPending({ id: analysis.uuid, pending: false }));

    if (!response) {
      return;
    }

    const { status } = response;
    if (status !== 204) {
      dispatch(
        analysisActions.renameAnalysis({
          analysisId,
          name: oldName,
        })
      );
      dispatch(
        setAnalysisError({
          title: 'An error has occurred during RenameAnalysis',
          message: `The server responded with status code ${status} instead of 204.`,
        })
      );
      return;
    }

    dispatch(
      fetchAnalysis({
        analysisId: analysis.uuid,
        analysisType: AnalysisTypeToSlug[analysis.analysisType],
      })
    );
  }
);
