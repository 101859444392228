import { createAsyncThunk } from '@reduxjs/toolkit';

import { authActions } from './auth/slice';
import { notificationsActions } from './notifications/slice';
import { tableSettingsActions } from './tableSettings/slice';

export const resetStore = createAsyncThunk('resetStore', (_, { dispatch }) => {
  dispatch(authActions.resetAuthSlice());
  dispatch(tableSettingsActions.resetTableSettingsSlice());
  dispatch(notificationsActions.resetNotificationSlice());
});
