import { useCallback } from 'react';

import {
  debounce as _debounce,
  values as _values,
  isEmpty as _isEmpty,
} from 'lodash-es';
import { defaultDebounceBy } from 'utils/constants/debounce';

export const checkStringArrayEquality = (
  arr1: string[],
  arr2: string[]
): boolean => {
  if (arr1.length !== arr2.length) return false;
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }
  return true;
};

export const cleanObject = (obj: any): any => {
  for (let propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }
  return obj;
};

export const downloadFileFromLink = (
  fileUrl: string,
  fileName: string
): void => {
  const link = document.createElement('a');
  link.href = fileUrl;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const debounce = <T extends (...args: any) => any>(
  func: T,
  wait = defaultDebounceBy
) => _debounce(func, wait);

export const isFilterEmpty = <T>(dataTableFilter: T): boolean => {
  return _values(dataTableFilter).every(_isEmpty);
};

export const toLocaleDateString = (date?: number | Date | string) => {
  if (date) {
    return new Date(date).toLocaleDateString();
  }
  return new Date().toLocaleDateString();
};

export const formatDateDDMMYY = (date: string): string => {
  return date;
};

// useCallback but with a translation function in between
type FunctionWithReturnType<T> = (...args: any[]) => T;
export const useTranslatedCallback = <
  Callback extends (...args: any[]) => void,
  TranslationFn extends FunctionWithReturnType<
    Parameters<Callback> | Promise<Parameters<Callback>>
  >,
>(
  translationFn: TranslationFn,
  callback?: Callback
) => {
  const mappedCallback = useCallback(
    async (...args: Parameters<TranslationFn>) => {
      if (!callback) return;
      // If the translationFn is async or not - this resolves to either the promises' return value or just the fn's return value
      const translatedArgs = await Promise.resolve(translationFn(...args));
      callback(...translatedArgs);
    },
    [callback, translationFn]
  );
  if (callback === undefined) {
    return undefined;
  }
  return mappedCallback;
};
