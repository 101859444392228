import { Brand } from 'types/Utilities';

export type TableId = Brand<string, 'TableId'>;
export type RowsPerPageId = Brand<string, 'RowsPerPageId'>;

/**
 * The table IDs are used to cache the results of a query.
 * Since the keys can be dynamic, we use a function to generate the key.
 */
export const tableIds = {
  covalentKineticsAnalysisList: 'CovalentKineticsAnalysisList' as TableId,
  affinityScreeningAnalysisList: 'AffinityScreeningAnalysisList' as TableId,
  bindingAffinityAnalysisList: 'BindingAffinityAnalysisList' as TableId,
  experimentList: (fileType: string | undefined) =>
    `ExperimentList/${fileType}` as TableId,
} as const;

export const defaultRowsPerPage = 10;

/**
 * The rows per page ids are used to store the rows per page pagination setting
 * per table.
 *
 * We use the same pattern as for `tableIds` even if it's not strictly necessary.
 */
export const rowsPerPageIds = {
  analysis: 'analysis' as RowsPerPageId,
  experiment: 'experiment' as RowsPerPageId,
} as const;
