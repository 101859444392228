import log from 'loglevel';

const LOCAL_STORAGE_TOKEN_KEY = 'accessToken';

export const getAuthToken = () => {
  return localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
};

export const registerAuthToken = (token: string) => {
  localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
};

export const clearAuthToken = () => {
  localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
};

type JWTPayload = {
  sub: string;
  exp: number;
};

/**
 * Checks if the token provided can be decoded and if it's not expired
 * Does not actually perform any verification apart from that
 */
export const verifyJWTPayload = (token: string | null): boolean => {
  if (!token) return false;

  const parts = token.split('.') as [
    string,
    string | undefined,
    string | undefined,
  ];
  if (parts.length !== 3 || !parts[1]) return false;

  try {
    const decodedJWTPayload = atob(parts[1]);
    const parsedDecodedJWTPayload: JWTPayload = JSON.parse(decodedJWTPayload);
    const tokenExpired = parsedDecodedJWTPayload.exp * 1000 <= Date.now();
    return !tokenExpired;
  } catch (error) {
    log.error('Could not decode JWT, token is invalid');
  }

  return false;
};
