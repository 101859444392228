import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store/store';

import { TableId } from 'utils/hooks/tableIds';

const getPersistedQueryParams = (state: RootState) =>
  state.tableSettings.persistedQueryParams;
const getTableId = (_: RootState, id: TableId | null | undefined) => id;

export const selectPersistedQueryParamsById = createSelector(
  getPersistedQueryParams,
  getTableId,
  (persistedQueryParams, id) => {
    return id ? persistedQueryParams[id] : null;
  }
);
