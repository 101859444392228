import React, { useCallback, useMemo } from 'react';

import {
  DataTableColumn_t,
  DataTableRowBase,
  RowClickCallback,
  RowIDMapper,
  RowSelectionCallback,
} from '../types/types';

import { Checkbox, TableCell, TableRow } from '@mui/material';

import { useStyles } from '../styles';

import UniversalTableCell from './UniversalTableCell';

export type DataTableRowProps<
  TRow extends DataTableRowBase,
  TRowId extends string | number,
> = {
  // Meta-dataclasses
  getRowId: RowIDMapper<TRow, TRowId>;
  columns: DataTableColumn_t<TRow, TRowId>[];

  // selection
  selected: boolean;
  onRowSelect?: RowSelectionCallback<TRow, TRowId>;
  onRowClick?: RowClickCallback<TRow, TRowId>;

  // split-view
  opened?: boolean;

  // Actual row-data
  row: TRow;
  rowIndex: number;

  // configuration
  hideCheckbox?: boolean;
};

function DataTableRow<TRow extends DataTableRowBase, TRowId extends string>(
  props: DataTableRowProps<TRow, TRowId>
): JSX.Element {
  const { row, getRowId, onRowSelect, onRowClick, opened } = props;
  const { classes, cx } = useStyles();
  const rowId = useMemo(() => getRowId(row), [getRowId, row]);

  const handleRowSelection = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      onRowSelect?.(rowId, row, checked);
    },
    [onRowSelect, rowId, row]
  );
  const handleRowClick = useCallback(() => {
    onRowClick?.(rowId, row);
  }, [onRowClick, rowId, row]);
  return (
    <TableRow
      onClick={handleRowClick}
      selected={opened}
      data-testid={`datatable-row-${rowId}`}
    >
      {/* Selection Checkbox */}
      {!props.hideCheckbox && (
        <TableCell className={cx(classes.tableCellSticky, 'checkbox')}>
          <Checkbox
            sx={{
              pt: '7px',
              pb: '7px',
              '& .MuiSvgIcon-root': { fontSize: 18 },
            }}
            checked={props.selected}
            onChange={handleRowSelection}
            size="small"
            data-testid="checkbox"
          />
        </TableCell>
      )}
      {/* Individual Cell Rendering */}
      {props.columns.map((column) => (
        <UniversalTableCell
          key={`${column.columnId}_${rowId}`}
          row={props.row}
          rowId={rowId}
          column={column}
          data-testid={`tablecell-${props.rowIndex}-${column.key}`}
        />
      ))}
    </TableRow>
  );
}

export default React.memo(DataTableRow) as unknown as typeof DataTableRow;
