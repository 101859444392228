import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useHeaderTitle } from 'context/TitleContext';
import { handleLogout } from 'reducers/auth/thunk';
import { toggleNotificationDrawer } from 'reducers/notifications/thunk';
import { useAppDispatch, useAppSelector } from 'store/store';

import {
  AppBar,
  Badge,
  Box,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';

import UserAccountModal from 'components/UserAccountModal/UserAccountModal';

import { AccountIcon, Logo, NotificationIcon } from './icons';

import { useStyles } from './styles';

type AppToolbarProps = {};

export function AppToolbar(props: AppToolbarProps): JSX.Element {
  const { classes, cx } = useStyles();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { unreadNotificationSeverity, unreadNotificationCount } =
    useAppSelector((state) => state.notifications);

  const { headerTitle } = useHeaderTitle();

  const handleNotificationDrawerToggle = useCallback(() => {
    dispatch(toggleNotificationDrawer());
  }, [dispatch]);

  const { isAuthenticated } = useAppSelector((state) => state.auth);

  // User Account Modal state
  const [userModalAnchorEl, setUserModalAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);
  const handleUserModalOpen = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setUserModalAnchorEl(event.currentTarget);
    },
    []
  );
  const handleUserModalClose = useCallback(() => {
    setUserModalAnchorEl(null);
  }, []);
  const handleLogoutClick = useCallback(() => {
    setUserModalAnchorEl(null);
    navigate('/');
    dispatch(handleLogout());
  }, [dispatch, navigate]);

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar variant="dense" className={classes.toolbar}>
        {/* Sidebar Button */}
        <Logo className={classes.logo} />

        {/* Title */}
        <Typography variant="h5" data-testid="heading" sx={{ ml: 1 }}>
          {headerTitle}
        </Typography>

        {/* Spacer */}
        <Box flexGrow={1} />

        {/* Account Button */}
        {isAuthenticated && (
          <IconButton
            color="inherit"
            aria-label="menu"
            onClick={handleUserModalOpen}
            data-testid="user-account-button"
            size="large"
          >
            <AccountIcon sx={{ fontSize: 24 }} />
          </IconButton>
        )}

        {/* NotificationButton */}
        {isAuthenticated && (
          <IconButton
            color="inherit"
            aria-label="menu"
            onClick={handleNotificationDrawerToggle}
            data-testid="toolbar-notification-button"
            size="large"
          >
            <Badge
              badgeContent=" "
              classes={{
                // This works, since there's a class with a name for each NotificationType
                badge: cx(classes.badge, classes[unreadNotificationSeverity]),
              }}
              invisible={unreadNotificationCount === 0}
              variant="dot"
              data-testid="toolbar-notification-badge"
            >
              <NotificationIcon sx={{ fontSize: 24 }} />
            </Badge>
          </IconButton>
        )}
      </Toolbar>

      {/* UserAccountModal */}
      <UserAccountModal
        anchorEl={userModalAnchorEl}
        onClose={handleUserModalClose}
        onLogout={handleLogoutClick}
      />
    </AppBar>
  );
}

export default AppToolbar;
