import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import { CssBaseline, ThemeProvider } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';

import './index.css';

import App from './App';
import AuthProvider from './components/Auth/AuthProvider';
import LogConfig from './context/LogContext';
import { TitleProvider } from './context/TitleContext';
import reportWebVitals from './reportWebVitals';
import store from './store/store';
import { theme } from './theme/theme';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <LogConfig>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <React.Fragment>
              <CssBaseline />
              <Router>
                <TitleProvider>
                  <AuthProvider>
                    <App />
                  </AuthProvider>
                </TitleProvider>
              </Router>
            </React.Fragment>
          </ThemeProvider>
        </Provider>
      </LogConfig>
    </StyledEngineProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
