import React, { useCallback, useMemo } from 'react';

import { DataTablePageChangeCallback } from 'components/DataTable/types/types';

import { setRowsPerPage } from 'reducers/tableSettings/slice';
import { useAppDispatch, useAppSelector } from 'store/store';

import {
  Box,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Typography,
  SelectChangeEvent,
} from '@mui/material';

import { ArrowIcon } from './icons';

import { RowsPerPageId, defaultRowsPerPage } from 'utils/hooks/tableIds';

export type ToolbarPaginationProps = {
  totalRows?: number;
  rowsPerPageId: RowsPerPageId;
  page?: number;
  onPageChange?: DataTablePageChangeCallback;
};

function ToolbarPaginationControls(props: ToolbarPaginationProps): JSX.Element {
  const { page, totalRows, onPageChange } = props;

  const dispatch = useAppDispatch();
  const { rowsPerPage: rowsPerPageConfig } = useAppSelector(
    (state) => state.tableSettings
  );
  const rowsPerPage =
    rowsPerPageConfig[props.rowsPerPageId] ?? defaultRowsPerPage;

  const handleRowsPerPageChange = useCallback(
    (event: SelectChangeEvent<number>) => {
      const value = event.target.value as number;
      dispatch(
        setRowsPerPage({
          tableId: props.rowsPerPageId,
          rowsPerPage: value,
        })
      );
    },
    [dispatch, props.rowsPerPageId]
  );

  const { from, to, total, isLastPage, isFirstPage } = useMemo(() => {
    const total = totalRows ?? 0;
    const perPage = rowsPerPage ?? 0;
    const currentPage = page ?? 0;
    const from = page != null ? page * perPage + 1 : 0;
    const to = Math.min((currentPage + 1) * perPage, total);

    const isLastPage = to === total;
    const isFirstPage = page != null ? from === 1 : true;

    return {
      to,
      from,
      total,
      isLastPage,
      isFirstPage,
      currentPage,
    };
  }, [totalRows, rowsPerPage, page]);

  // Forward/Back callback
  const handlePreviousPage = useCallback(() => {
    // Note: this cant be !page, since 0 is also fasly
    if (page == null) return;
    onPageChange?.(page - 1);
  }, [onPageChange, page]);

  const handleNextPage = useCallback(() => {
    // Note: this cant be !page, since 0 is also fasly
    if (page == null) return;
    onPageChange?.(page + 1);
  }, [onPageChange, page]);

  return (
    <Box display={'flex'} height={60} data-testid="toolbar-pagination">
      {/* Number of Rows/Page control */}
      <Box my="auto" mr={1.5}>
        <Typography
          component={'span'}
          variant="subtitle2"
          color="textSecondary"
          gutterBottom
        >
          <Box color={'text.secondary'} component={'span'}>
            Rows per page:
          </Box>
        </Typography>
      </Box>
      <Box my="auto">
        <FormControl>
          <Select
            variant="standard"
            aria-label="Select Rows Per Page"
            value={rowsPerPage}
            onChange={handleRowsPerPageChange}
            style={{ width: 50 }}
          >
            <MenuItem value={10}> 10 </MenuItem>
            <MenuItem value={15}> 15 </MenuItem>
            <MenuItem value={100}> 100 </MenuItem>
          </Select>
        </FormControl>
      </Box>

      {/* xx-xx of xx */}
      <Box my="auto" ml={1.5} mr={2.375}>
        <Typography
          component={'span'}
          variant="subtitle2"
          color="textSecondary"
          gutterBottom
          data-testid="toolbar-pagination-index"
        >
          <Box color={'text.secondary'} component={'span'}>
            {from}-{to} of {total}
          </Box>
        </Typography>
      </Box>

      {/* Foward/back controls */}
      <Box my="auto">
        <IconButton
          disabled={isFirstPage}
          color="primary"
          onClick={handlePreviousPage}
          data-testid="toolbar-pagination-backbutton"
          size="large"
        >
          <ArrowIcon sx={{ fontSize: 24 }} />
        </IconButton>

        <IconButton
          disabled={isLastPage}
          color="primary"
          onClick={() => {
            handleNextPage();
          }}
          data-testid="toolbar-pagination-forwardbutton"
          size="large"
        >
          <ArrowIcon sx={{ fontSize: 24, transform: 'rotate(180deg)' }} />
        </IconButton>
      </Box>
    </Box>
  );
}

export default React.memo(ToolbarPaginationControls);
