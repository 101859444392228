import { useState, useCallback, useMemo, useEffect } from 'react';

import {
  DataTableRowBase,
  RowIDMapper,
  RowSelectionCallback,
} from '../types/types';

import {
  SelectionMap,
  useRowSelectionConversion,
} from '../utils/convertRowSelection';

export type useRowSelectionOptions<
  TRow extends DataTableRowBase,
  TRowId extends string,
> = {
  rows: TRow[];
  rowIdMapper: RowIDMapper<TRow, TRowId>;
  initialSelection?: TRow[];
};

export type useRowSelectionReturn<TRow, TRowId> = {
  selection: TRow[];
  selectedIndices: number[];
  handleChange: RowSelectionCallback<TRow, TRowId>;
  clear: () => void;
};

export function useRowSelection<
  TRow extends DataTableRowBase,
  TRowId extends string,
>(
  options: useRowSelectionOptions<TRow, TRowId>
): useRowSelectionReturn<TRow, TRowId> {
  const { rows, rowIdMapper, initialSelection } = options;

  const { preSelectionMap, preSelectedIds } = useMemo(() => {
    const preSelectionMap = initialSelection
      ? initialSelection.reduce(
          (map, row) => {
            const id = rowIdMapper(row);
            map[id] = { id, data: row, selected: true };
            return map;
          },
          {} as SelectionMap<TRow, TRowId>
        )
      : ({} as SelectionMap<TRow, TRowId>);

    const preSelectedIds = initialSelection
      ? initialSelection.map((r) => rowIdMapper(r))
      : [];

    return {
      preSelectionMap,
      preSelectedIds: preSelectedIds.sort().join(','),
    };
  }, [initialSelection, rowIdMapper]);

  const [globalSelection, setGlobalSelection] =
    useState<SelectionMap<TRow, TRowId>>(preSelectionMap);
  const [currentPreSelectedIds, setCurrentPreSelectedIds] =
    useState(preSelectedIds);
  const { mapToDataTable } = useRowSelectionConversion(rows, rowIdMapper);

  const handleChange = useCallback(
    (id: TRowId, row: TRow, selected: boolean): void => {
      const updatedSelection = {
        [id]: { id, data: row, selected },
      };
      setGlobalSelection((current) => ({ ...current, ...updatedSelection }));
    },
    []
  );

  const clear = useCallback(
    () => setGlobalSelection({} as SelectionMap<TRow, TRowId>),
    [setGlobalSelection]
  );

  // Now with the globalSelection and the rows, we can calculate the actual selection & DataTableSelection
  const { selectedIndices, selection } = useMemo(() => {
    // dataTableIndices
    const selectedIndices = mapToDataTable(globalSelection);

    const selection = [];
    for (const id in globalSelection) {
      const record = globalSelection[id];
      if (record.selected) selection.push(record.data);
    }

    return { selectedIndices, selection };
  }, [globalSelection, mapToDataTable]);

  // Update selection, when initialSelection changes
  // This overrides the global selection map.
  useEffect(() => {
    // Check if the selection has actually changed
    if (preSelectedIds === currentPreSelectedIds) return;
    setCurrentPreSelectedIds(preSelectedIds);
    setGlobalSelection(preSelectionMap);
  }, [preSelectionMap, preSelectedIds, currentPreSelectedIds]);

  return {
    selection,
    selectedIndices,
    handleChange,
    clear,
  };
}
