import React from 'react';
import { RouteProps, Navigate } from 'react-router-dom';

const AnalysisListRoute = React.lazy(
  () => import('routes/analysis/AnalysisList/AnalysisList')
);
const AnalysisPage = React.lazy(
  () => import('pages/AnalysisPage/AnalysisPage')
);

const analysisRoutes: RouteProps[] = [
  {
    path: '/analyses',
    element: <Navigate to="/analyses/binding-affinity" replace />,
  },
  {
    path: '/analyses/:type',
    element: <AnalysisListRoute />,
  },
  {
    path: '/analyses/:analysisType/:id',
    element: <AnalysisPage />,
  },
];

export default analysisRoutes;
