import React, { useContext, useState } from 'react';

import { ErrorShape, Severity } from 'utils/utils';

export interface GridErrors extends ErrorShape {
  columnId: string;
}

export interface ErrorContext extends GridErrors {
  onError?: (error: GridErrors) => void;
}

export const TableErrorContext = React.createContext<ErrorContext>({
  message: '',
  error: false,
  errorSeverity: Severity.ERROR,
  columnId: '',
  onError: () => {},
});

export const useTableErrorContext = () => useContext(TableErrorContext);

const TableErrorContextWrapper = (
  props: React.PropsWithChildren<{}>
): JSX.Element => {
  const [error, setError] = useState<GridErrors>({
    message: '',
    error: false,
    columnId: '',
    errorSeverity: Severity.ERROR,
  });

  return (
    <TableErrorContext.Provider value={{ ...error, onError: setError }}>
      {props.children}
    </TableErrorContext.Provider>
  );
};

export default TableErrorContextWrapper;
