import { Theme } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  paper: {
    padding: '0.5rem',
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
  },
}));
