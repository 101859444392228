import React from 'react';

import { Box, Button, Typography } from '@mui/material';

import { useStyles } from './styles';

import DataTableSearchForm from './DataTableSearchForm/DataTableSearchForm';
import { SearchIcon } from './DataTableSearchForm/icons';
import { useSearch } from './useSearch';

type DataTableSearchProps = {
  onChange: (searchValue?: string) => void;
  initialSearchValue?: string;
};

function DataTableSearch(props: DataTableSearchProps) {
  const { classes } = useStyles();

  const [
    searchValue,
    handleSearchValueChange,
    handleSearchValueClear,
    showSearch,
    toggleShowSearch,
  ] = useSearch(props.onChange, {
    intialSearchValue: props.initialSearchValue,
  });

  return (
    <>
      {!showSearch && (
        <>
          <Button
            variant="text"
            color="primary"
            startIcon={<SearchIcon width="18px" />}
            onClick={toggleShowSearch}
            aria-label="show experiment search"
            data-testid="data-table-search-button"
            className={classes.root}
          >
            <Typography variant="body2">
              <Box component="span" fontWeight={'fontWeightMedium'}>
                Search
              </Box>
            </Typography>
          </Button>
        </>
      )}
      {showSearch && (
        <DataTableSearchForm
          searchValue={searchValue}
          onSearchValueChange={handleSearchValueChange}
          onSearchValueClear={handleSearchValueClear}
          className={classes.root}
        />
      )}
    </>
  );
}

export default DataTableSearch;
