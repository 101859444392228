import { GenericAPIRequestMethod, LogRequest } from 'types/APITypes/APITypes';

import { getUrl } from 'utils/helpers/requestbuilder';

import { axiosInstance, parseAPIResponse } from './CASAPI';

export const LogOnServer: GenericAPIRequestMethod<
  never,
  LogRequest,
  void
> = async (logRequest, requestConfig) => {
  const response = await axiosInstance.post(getUrl('logging'), logRequest, {
    ...requestConfig,
  });
  return parseAPIResponse(response);
};
