// Wrapper component which renders a table-cell, based on Table-Data
// Currently serves as a translation-interface between MUIDataTables and the MUI Table
import React from 'react';

import { DataTableColumn_t, DataTableRowBase } from '../types/types';
import {
  DeepPrefixed,
  DeepPrefixedKeys,
  DeepPrefixedSlice,
} from 'types/Utilities';

import { TableCell } from '@mui/material';

import { useStyles } from '../styles';

import { createDataTableCell } from './DataTableCell/DataTableCell';

// Now onto typing this beast:
// Function overloads?
function accessRowData<TRow, Key_t extends keyof DeepPrefixed<TRow>>(
  row: TRow,
  key: Key_t
): DeepPrefixed<TRow>[Key_t] | undefined;
function accessRowData<TRow, Keys_t extends Array<DeepPrefixedKeys<TRow>>>(
  row: TRow,
  key: Keys_t
): DeepPrefixedSlice<TRow, Keys_t> | undefined;

// Property access helper
// Maybe we can tell typescript, that this returns the type set in the PrefixObjectDeep<TRow>[key]?
// It contains an unholy amount of "as" statements, but I couldn't think of any way to do this properly
// (Without generating the whole "DeepPrefixed" object)
function accessRowData<
  TRow,
  Key_t extends DeepPrefixedKeys<TRow> | DeepPrefixedKeys<TRow>[],
>(row: TRow, key: Key_t): DeepPrefixedSlice<TRow, Key_t> | undefined {
  // Check if we're dealing with an array of keys:
  // For now, let's just return an array
  if (Array.isArray(key)) {
    const data: Record<string, any> = {};
    for (const k of key) {
      data[k] = accessRowData(row, k as any);
    }
    return data as unknown as DeepPrefixedSlice<TRow, Key_t>;
  }

  // deal with the root key
  if (key === '$root') {
    return row as unknown as DeepPrefixedSlice<TRow, Key_t>;
  }

  if (typeof key !== 'string')
    return row[key as unknown as keyof TRow] as unknown as DeepPrefixedSlice<
      TRow,
      Key_t
    >;

  const keyParts = key.split('.');

  if (keyParts.length === 0) return;
  if (keyParts.length === 1)
    return row[keyParts[0] as keyof TRow] as unknown as DeepPrefixedSlice<
      TRow,
      Key_t
    >;

  return accessRowData(
    row[keyParts[0] as keyof TRow],
    keyParts.slice(1).join('.') as any
  );
}

export type UniversalTableCellProps<TRow extends DataTableRowBase, TRowId> = {
  row: TRow;
  rowId: TRowId;
  column: DataTableColumn_t<TRow, TRowId>;
  ['data-testid']?: string;
};

function UniversalTableCell<TRow extends DataTableRowBase, TRowId>(
  props: UniversalTableCellProps<TRow, TRowId>
): JSX.Element {
  const { row, rowId, column } = props;
  const { key } = column;
  const { classes } = useStyles();

  const BodyComponent =
    column.options?.customBodyRender ?? createDataTableCell();

  return (
    <TableCell
      data-testid={props['data-testid']}
      className={classes.customTableCellWidth}
    >
      <BodyComponent
        value={accessRowData(row, key as any) as any}
        rowId={rowId}
      />
    </TableCell>
  );
}

export default React.memo(
  UniversalTableCell
) as unknown as typeof UniversalTableCell;
