import { createAsyncThunk } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { UserInfo } from 'types/APITypes/APITypes';

import { axiosInstance } from 'api/CASAPI';
import { getUserInformation, SigninUser } from 'api/signup';
import { InternalAxiosRequestConfig } from 'axios';

import { resetStore } from 'reducers/thunk';

import {
  clearAuthToken,
  getAuthToken,
  registerAuthToken,
  verifyJWTPayload,
} from 'utils/auth/auth';

import { authActions } from './slice';

import log from 'loglevel';

const { setLoading, setAuth, setAuthError, setCurrentUser } = authActions;

let axiosInterceptors = {
  request: [] as number[],
  response: [] as number[],
};

export const handleSignin = createAsyncThunk(
  'handleSignin',
  async (userInfo: UserInfo, { dispatch }) => {
    // Reset the store back to a base-state
    dispatch(resetStore());

    dispatch(setLoading(true));
    const { status, body } = await SigninUser(userInfo);
    dispatch(setLoading(false));

    // TODO: if there was a better type for this then this mess would not be needed
    // Same goes for the (body as any) below
    if (status === 200 && body != null && 'access_token' in body) {
      const token = body?.access_token;
      if (token) {
        // Make sure all the interceptors we've created are removed
        resetAxiosInterceptors();

        registerAuthToken(token);
        injectTokenInterceptor(token);
        injectErrorResponseInterceptor(dispatch);
        dispatch(setAuth({ isAuthenticated: true, token }));
        dispatch(setAuthError(null));
      }
    } else {
      dispatch(
        setAuthError({
          title: 'Error',
          message: (body as any).detail,
        })
      );
      return;
    }

    // Now we're logged in, we can query the userinfo now:
    dispatch(handleUpdateUserInformation());
  }
);

export const handleLogout = createAsyncThunk(
  'handleLogout',
  async (_, { dispatch }) => {
    // Reset the store back to a base-state
    dispatch(resetStore());
    log.info('user logged out');
    // Delete the token from local storage
    clearAuthToken();
    // Reset the Axios interceptors
    resetAxiosInterceptors();
  }
);

export const retreiveLocalTokenOrLogout = createAsyncThunk(
  'checkAndUseLocalToken',
  async (_, { dispatch }) => {
    // Get the token stored in the localStorage:
    const storedToken = getAuthToken();

    // Decode and check it
    const tokenValid = verifyJWTPayload(storedToken);
    if (!storedToken || !tokenValid) {
      dispatch(handleLogout());
      return;
    }

    dispatch(setLoading(true));
    // Before doing anything with interceptors, make sure we start with a clean slate
    resetAxiosInterceptors();
    // Setup token interceptor - otherwise we can't use the token in the next call
    injectTokenInterceptor(storedToken);

    // Check if the backend also thinks, that the token is valid
    const response = await getUserInformation();
    dispatch(setLoading(false));
    if (response.status !== 200) {
      dispatch(handleLogout());
      return;
    }

    // If we got here, the token is valid and we already have user-info
    // Set up remaining items needed for "login"
    injectErrorResponseInterceptor(dispatch);
    dispatch(setAuth({ isAuthenticated: true, token: storedToken }));
    dispatch(setAuthError(null));
    dispatch(setCurrentUser(response.body));
  }
);

// User-Information
export const handleUpdateUserInformation = createAsyncThunk(
  'handleUpdateUserInformation',
  async (_, { dispatch }) => {
    dispatch(setLoading(true));
    // Get User Account Information
    const { status, body } = await getUserInformation();
    dispatch(setLoading(false));
    if (status === 200) {
      dispatch(setCurrentUser(body));
    } else {
      dispatch(
        setAuthError({
          title: 'Error',
          message: 'Unexpected Error in authentication',
        })
      );
    }
  }
);

// Axios Interceptors
export const resetAxiosInterceptors = () => {
  axiosInterceptors.request.forEach((index) =>
    axiosInstance.interceptors.request.eject(index)
  );
  axiosInterceptors.response.forEach((index) =>
    axiosInstance.interceptors.request.eject(index)
  );
  axiosInterceptors.request = [];
  axiosInterceptors.response = [];
};

const injectErrorResponseInterceptor = (
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>
) => {
  axiosInterceptors.response.push(
    axiosInstance.interceptors.response.use((response) => {
      if (response.status === 401) {
        dispatch(handleLogout());
      }
      return response;
    })
  );
};

const injectTokenInterceptor = (accessToken: string) => {
  // Inject the auth token, if any into all requests
  axiosInterceptors.request.push(
    axiosInstance.interceptors.request.use(
      (config: InternalAxiosRequestConfig<any>) => {
        // If we don't have an access token there's nothing we need to do
        if (!accessToken) {
          return config;
        }

        // Set the Auth-token
        config.headers.set('Authorization', `Bearer ${accessToken}`);

        return config;
      }
    )
  );
};

export const getAxiosInterceptorsForTesting = () => {
  return axiosInterceptors;
};
