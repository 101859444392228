import React, { useCallback, useEffect } from 'react';

import { DataTableFilterTextFieldProps } from './types';

import { CustomTextField } from 'components/Inputs/CustomTextField';

import { useStyles } from './styles';

function DataTableFilterTextField(props: DataTableFilterTextFieldProps) {
  const { classes } = useStyles();

  const [value, setValue] = React.useState(props.value ?? '');

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setValue(value);
      props.onChange(event.target.name, value !== '' ? value : undefined);
    },
    [props]
  );

  const fixed = props.fixedValue !== undefined;

  useEffect(() => {
    setValue(props.value ?? '');
  }, [props.value]);

  return (
    <CustomTextField
      disabled={fixed}
      id={props.id}
      className={classes.formControl}
      name={props.name}
      inputProps={{ 'data-testid': `${props.label}` }}
      label={props.label}
      value={props.fixedValue ?? value}
      onChange={handleChange}
      fullWidth
    />
  );
}

export default DataTableFilterTextField;
