import {
  AnalysisFetchById,
  AnalysisCreator,
  AnalysisFetchPaginated,
  AnalysisPatch,
} from './types';
import {
  AffinityScreeningAnalysis,
  AffinityScreeningAnalysisPatch,
  AnalysisId,
  PaginatedList,
  BindingAffinityAnalysis,
  BindingAffinityAnalysisPatch,
  AffinityScreeningAnalysisOverview,
  APIResponse,
} from 'types/APITypes/APITypes';
import { AffinityAnalysisContainedAnalysisFilter } from 'types/Filters';
import { AnalysisQueryParams } from 'types/QueryParams';

import { axiosInstance, catchError, parseAPIResponse } from 'api/CASAPI';
import { AxiosError } from 'axios';

import { applyDefaultSorting } from 'utils/api/api';
import { getUrl } from 'utils/helpers/requestbuilder';

// API Calls

export const getAffinityScreeningAnalysisList: AnalysisFetchPaginated<
  AffinityScreeningAnalysisOverview
> = async (qp, requestConfig) => {
  const queryParams = applyDefaultSorting(qp, {
    sort: 'createdAt',
    sortDirection: 'descending',
  });
  const response = await axiosInstance.get(
    getUrl('analysisAffinityScreeningList'),
    {
      ...requestConfig,
      params: {
        limit: queryParams.limit,
        offset: queryParams.offset,
        sort: queryParams.sorting?.sort,
        sortDirection: queryParams.sorting?.sortDirection,
        analysisName: queryParams.filtering?.analysisName,
        annotation: queryParams.filtering?.analysisAnnotation,
        searchValue: queryParams.searchValue,
      },
    }
  );
  return parseAPIResponse(
    response,
    (res: PaginatedList<AffinityScreeningAnalysisOverview>) => ({
      total: res.total,
      data: res.data,
    })
  );
};

export const createAffinityScreeningAnalysis: AnalysisCreator = async (
  experimentIds
) => {
  const analysisSetup = {
    experimentIds: experimentIds.slice(0, 10),
  };

  const response = await axiosInstance.post(
    getUrl('analysisAffinityScreeningCreate'),
    analysisSetup
  );
  return parseAPIResponse<AffinityScreeningAnalysis>(response);
};

export const getAffinityScreeningAnalysis: AnalysisFetchById<
  AffinityScreeningAnalysis
> = async (id) => {
  const response = await axiosInstance.get(
    getUrl('analysisAffinityScreening', { id: id }),
    {
      params: {
        limit: 0,
        offset: 0,
      },
    }
  );

  return parseAPIResponse(response);
};

export const getAffinityScreeningAnalysisContainedAnalysisList: AnalysisFetchPaginated<
  BindingAffinityAnalysis,
  AffinityAnalysisContainedAnalysisFilter
> = async (qp, requestConfig) => {
  const queryParams = applyDefaultSorting(qp, {
    sort: 'createdAt',
    sortDirection: 'descending',
  });
  const response = await axiosInstance.get(
    getUrl('analysisAffinityScreeningBindingAffinities', {
      id: queryParams.id,
    }),
    {
      ...requestConfig,
      params: {
        limit: queryParams.limit,
        offset: queryParams.offset,
        sort: queryParams.sorting?.sort,
        sortDirection: queryParams.sorting?.sortDirection,
        analysisName: queryParams.filtering?.analysisName,
        annotation: queryParams.filtering?.analysisAnnotation,
        ligandName: queryParams.filtering?.ligandName,
        searchValue: queryParams.searchValue,
      },
    }
  );

  return parseAPIResponse(
    response,
    (res: PaginatedList<BindingAffinityAnalysis>) => ({
      total: res.total,
      data: res.data,
    })
  );
};

export const patchAffinityScreeningAnalysis: AnalysisPatch<
  BindingAffinityAnalysisPatch
> = async (id: AnalysisId, update: AffinityScreeningAnalysisPatch) => {
  const response = await axiosInstance.patch(
    getUrl('analysisAffinityScreening', { id: id }),
    update
  );

  return parseAPIResponse(response);
};

export async function getAffinityScreeningAnalysisExcelExport(
  qp: AnalysisQueryParams<AffinityAnalysisContainedAnalysisFilter>
): Promise<APIResponse<{ url: string; filename?: string } | null>> {
  const queryParams = applyDefaultSorting(qp, {
    sort: 'createdAt',
    sortDirection: 'descending',
  });
  try {
    const response = await axiosInstance.get(
      getUrl('analysisAffinityScreeningExcelExport', { id: queryParams?.id }),
      {
        responseType: 'blob',
        params: {
          limit: queryParams.limit,
          offset: queryParams.offset,
          sort: queryParams.sorting?.sort,
          sortDirection: queryParams.sorting?.sortDirection,
          analysisName: queryParams.filtering?.analysisName,
          annotation: queryParams.filtering?.analysisAnnotation,
          ligandName: queryParams.filtering?.ligandName,
          searchValue: queryParams.searchValue,
        },
      }
    );
    // Extract the filename from the content-disposition header
    const filename =
      response.headers['content-disposition']?.match(/filename=(.+)$/)?.[1];
    return parseAPIResponse(response, (body: BlobPart) => {
      const url = window.URL.createObjectURL(new Blob([body]));
      return { url, filename };
    });
  } catch (error) {
    return catchError(error as Error | AxiosError);
  }
}
