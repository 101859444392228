import React from 'react';

import {
  Badge,
  Box,
  IconButton,
  Paper,
  Theme,
  Typography,
} from '@mui/material';

import {
  ErrorIcon,
  WarningIcon,
  SuccessIcon,
  InfoIcon,
  CloseIcon,
} from './icons';

import { makeStyles } from 'tss-react/mui';

type ErrorMessageType = 'info' | 'success' | 'warning' | 'error';

export interface ErrorMessageProps {
  title: string;
  message?: string;
  showBadge?: boolean;
  type?: ErrorMessageType;
  onClose?: () => void;
}

const IconMap: { [K in ErrorMessageType]: typeof ErrorIcon } = {
  error: ErrorIcon,
  info: InfoIcon,
  success: SuccessIcon,
  warning: WarningIcon,
};

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    borderLeft: '6px solid',
  },

  rootError: {
    borderColor: '#D60404',
  },

  rootWarning: {
    borderColor: '#ED7104',
  },

  rootSuccess: {
    borderColor: '#08893E',
  },

  rootInfo: {
    borderColor: '#84368A',
  },

  info: {
    color: '#84368A',
  },

  success: {
    color: '#08893E',
  },

  error: {
    color: '#D60404',
  },

  warning: {
    color: '#ED7104',
  },

  badge: {
    transform: 'scale(1) translate(25%, -25%)',
    outline: '1px solid #fff',
  },

  badgeinfo: {
    backgroundColor: '#84368A',
  },

  badgesuccess: {
    backgroundColor: '#08893E',
  },

  badgeerror: {
    backgroundColor: '#D60404',
  },

  badgewarning: {
    backgroundColor: '#ED7104',
  },
}));

function ErrorMessage(props: ErrorMessageProps): JSX.Element {
  const { classes: styles, cx } = useStyles();
  const showBadge = props.showBadge ?? false;

  const type = props.type ?? 'error';
  const Icon = IconMap[type];

  return (
    <Paper
      className={cx({
        [styles.root]: true,
        [styles.rootError]: type === 'error',
        [styles.rootWarning]: type === 'warning',
        [styles.rootSuccess]: type === 'success',
        [styles.rootInfo]: type === 'info',
      })}
      data-testid="errormessage-root"
    >
      <Box
        display="flex"
        overflow="hidden"
        pt={props.message ? 0.5 : 1}
        pb={props.message ? 2 : 1}
      >
        {/* Icon */}
        <Box px={1.75} py="10px">
          <Badge
            invisible={!showBadge}
            color="error"
            classes={{
              badge: cx(styles.badge, styles[`badge${type}`]),
            }}
            variant="dot"
            data-testid="errormessage-icon-badge"
          >
            <Icon
              sx={{ fontSize: 24 }}
              className={styles[type]}
              data-testid="errormessage-icon"
              style={{ overflow: 'visible', verticalAlign: 'middle' }}
            />
          </Badge>
        </Box>

        <Box display="flex" flexDirection="column" flex="1 1 0" minWidth={0}>
          {/* Title */}
          <Typography
            data-testid="error-title"
            sx={{
              mt: '10.5px',
              mb: 0.5,
              maxWidth: '100%',
              fontWeight: 700,
              fontSize: '16px',
              lineHeight: '24px',
              wordBreak: 'break-word',
            }}
            className={styles[type]}
          >
            {props.title}
          </Typography>

          {/* Message */}
          <Box>
            <Typography data-testid="error-message" variant="body2">
              {props.message}
            </Typography>
          </Box>
        </Box>

        {/* Close Button */}
        {props.onClose && (
          <Box mx={1} mt="0.375rem" my={props.message ? 0 : 'auto'}>
            <IconButton
              onClick={props.onClose}
              data-testid="message-close-button"
            >
              <CloseIcon
                sx={{
                  fontSize: 20,
                  color: (theme) => theme.palette.secondary.main,
                }}
              />
            </IconButton>
          </Box>
        )}
      </Box>
    </Paper>
  );
}

export default React.memo(ErrorMessage);
