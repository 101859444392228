import { Join, Split } from 'types/Utilities';

import {
  InhibitorPotencyFitResult,
  SpectralShiftVsTimeFitResult,
} from './covalentKineticsFits';
import { DoseResponseCurveInfo } from './doseResponseCurves';
import {
  ExperimentInfo,
  BindingAffinityInfo,
  ExperimentId,
  AffinityScreeningInfo,
} from './experiments';
import { BindingAffinityFitInfo } from './fit';
import { PaginatedList } from './pagination';

export type AnalysisId = string;

export const AnalysisTypes = [
  'Binding Affinity',
  'Affinity Screening',
  'Single Dose Screening',
  'Binding Check',
  'Covalent Kinetics',
] as const;
export type AnalysisType = (typeof AnalysisTypes)[number];
export type AnalysisTypeSlug = Join<Split<Lowercase<AnalysisType>, ' '>, '-'>;

export const AnalysisTypeToSlug: Record<AnalysisType, AnalysisTypeSlug> = {
  'Binding Affinity': 'binding-affinity',
  'Affinity Screening': 'affinity-screening',
  'Single Dose Screening': 'single-dose-screening',
  'Binding Check': 'binding-check',
  'Covalent Kinetics': 'covalent-kinetics',
};

export const AnalysisSlugToType: Record<AnalysisTypeSlug, AnalysisType> = {
  'binding-affinity': 'Binding Affinity',
  'affinity-screening': 'Affinity Screening',
  'binding-check': 'Binding Check',
  'single-dose-screening': 'Single Dose Screening',
  'covalent-kinetics': 'Covalent Kinetics',
};

export interface BaseAnalysis {
  uuid: AnalysisId;
  analysisName: string;
  analysisType: AnalysisType;
  annotation: string;
  createdAt: string;
  updatedAt: string;
  experiments: Array<ExperimentInfo> | Array<BindingAffinityInfo>;

  // Local extension
  activeExperimentId?: ExperimentId;
}

export interface BaseAnalysisPatch {
  analysisName?: string;
  annotation?: string;
}

export interface BindingAffinityAnalysis extends BaseAnalysis {
  analysisType: 'Binding Affinity';
  experiments: Array<BindingAffinityInfo>;
  doseResponseCurves: DoseResponseCurveInfo[];
  bindingAffinityFit: BindingAffinityFitInfo;
}

export interface CovalentKineticsAnalysis extends BaseAnalysis {
  analysisType: 'Covalent Kinetics';
  experiments: Array<ExperimentInfo>;
  inhibitorPotencyFit: InhibitorPotencyFitResult;
  spectralShiftVsTimeFits: Array<SpectralShiftVsTimeFitResult>;
  ligandName: string;
  targetName: string;
  targetConcentrationInMolar: number;
}

// AffinityScreening Analysis are split into two
// An overview type for the list and the analysis type for the AnalysisView itself
export type AffinityScreeningAnalysis = BaseAnalysis & {
  analysisType: 'Affinity Screening';
  experiments: ExperimentInfo[];
  deltaVsKd: {
    ligandNames: string[];
    kds: number[];
    deltas: number[];
    outlierIndices: boolean[];
  };
};

export interface AffinityScreeningAnalysisOverview extends BaseAnalysis {
  analysisType: 'Affinity Screening';
  targetName: string;
  topKd: number;
  topKdConfidenceIntervalLower: number;
  topKdConfidenceIntervalUpper: number;
  topLigandName: string;
}

export interface CovalentKineticsAnalysisOverview extends BaseAnalysis {
  analysisType: 'Covalent Kinetics';
  targetName: string;
  ligandName: string;
  kInact: number;
  kInactError: number;
  kI: number;
  kIError: number;
  annotation: string;
}

export interface AffinityScreeningSingleAnalysis extends BaseAnalysis {
  analysisType: 'Affinity Screening';
  experiments: Array<AffinityScreeningInfo>;
  analyses: BindingAffinityAnalysis[];
  paginatedBindingAffinityAnalyses: PaginatedList<BindingAffinityAnalysis>;
}

export interface CovalentKineticsAnalysis extends BaseAnalysis {
  analysisType: 'Covalent Kinetics';
}

export interface AffinityScreeningAnalysisPatch extends BaseAnalysisPatch {
  experimentIds?: ExperimentId[];
}

export interface BindingAffinityAnalysisPatch extends BaseAnalysisPatch {
  experimentIds?: ExperimentId[];
}

export interface CovalentKineticsAnalysisPatch extends BaseAnalysisPatch {}

export type Analysis =
  | BindingAffinityAnalysis
  | AffinityScreeningAnalysis
  | CovalentKineticsAnalysis;
