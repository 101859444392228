// Wrapper component which renders a table-cell, based on Table-Data
import React, { useCallback } from 'react';

import {
  DataTableColumn_t,
  DataTableRowBase,
  DataTableSort,
} from '../types/types';

import DataTableHead from './DataTableHead/DataTableHead';

export type UniversalTableHeaderProps<TRow extends DataTableRowBase, TRowId> = {
  column: DataTableColumn_t<TRow, TRowId>;
  columnIndex: number;
  sorting: DataTableSort;
  onSortChange: (sorting: DataTableSort) => void;
};

function UniversalTableHeader<TRow extends DataTableRowBase, TRowId>(
  props: UniversalTableHeaderProps<TRow, TRowId>
): JSX.Element {
  const {
    column: { sortLabel, options },
    sorting,
    onSortChange,
  } = props;

  const handleSortClick = useCallback(() => {
    // Check if the current column was previously sorted
    if (sorting.sort !== sortLabel) {
      onSortChange({
        sort: sortLabel,
        sortDirection: 'ascending',
      });
    } else {
      switch (sorting.sortDirection) {
        case 'ascending':
          onSortChange({
            sort: sortLabel,
            sortDirection: 'descending',
          });
          break;
        case 'descending':
          if (options?.sortThirdClickReset) {
            onSortChange({
              sort: sortLabel,
              sortDirection: undefined,
            });
          } else {
            onSortChange({
              sort: sortLabel,
              sortDirection: 'ascending',
            });
          }
          break;
        case undefined:
          onSortChange({
            sort: sortLabel,
            sortDirection: 'ascending',
          });
          break;
      }
    }
  }, [sortLabel, options, sorting, onSortChange]);

  const BodyComponent = options?.customHeadRender ?? DataTableHead;

  return (
    <BodyComponent
      column={props.column}
      onSortClick={handleSortClick}
      tableSorting={sorting}
    />
  );
}

export default React.memo(
  UniversalTableHeader
) as unknown as typeof UniversalTableHeader;
