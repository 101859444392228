import React, { useMemo } from 'react';
import { Link, NavLink, useMatch } from 'react-router-dom';

import { ListItem, ListItemIcon, ListItemText } from '@mui/material';

import { SvgIconComponent } from '@mui/icons-material';

import { useStyles } from './styles';

type SidebarMenuItemContentProps = {
  label: string;
  IconComponent: SvgIconComponent;
  sidebarOpen: boolean;
};

function SidebarMenuItemContent(
  props: SidebarMenuItemContentProps
): JSX.Element {
  const IconComponent = props.IconComponent;

  return (
    <ListItem
      button
      sx={{
        padding: 0,
        margin: 0,
        color: (theme) => theme.palette.text.primary,
        justifyContent: 'center',
        height: 48,
      }}
    >
      <ListItemIcon
        sx={{
          color: 'inherit',
          width: 56,
          padding: '0 16px',
          '& > svg': {
            margin: 0,
          },
        }}
      >
        <IconComponent sx={{ fontSize: 24 }} />
      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{
          variant: 'body1',
        }}
        primary={props.label}
        sx={{
          whiteSpace: 'nowrap',
          '& > p': {
            pl: 2,
          },
        }}
      />
    </ListItem>
  );
}

type SidebarMenuItemProps = {
  path: string;
  comparePath?: string;
  onClick: () => void;
  IconComponent: SvgIconComponent;
  label: string;
  dataTestId: string;

  sidebarOpen: boolean;
};

function SidebarMenuItem(props: SidebarMenuItemProps): JSX.Element {
  const { comparePath } = props;
  const { classes, cx } = useStyles();

  const routeMatch = useMatch(props.comparePath ?? props.path);
  const useCustomNavLink = useMemo(() => Boolean(comparePath), [comparePath]);
  const linkActive = useMemo(() => Boolean(routeMatch) ?? false, [routeMatch]);

  if (useCustomNavLink) {
    return (
      <Link
        to={props.path}
        onClick={props.onClick}
        className={cx({
          [classes.menuItemLink]: true,
          [classes.menuItemActive]: linkActive,
        })}
        data-testid={props.dataTestId}
      >
        <SidebarMenuItemContent
          label={props.label}
          IconComponent={props.IconComponent}
          sidebarOpen={props.sidebarOpen}
        />
      </Link>
    );
  }

  return (
    <NavLink
      to={props.path}
      onClick={props.onClick}
      className={(navData) =>
        cx(classes.menuItemLink, { [classes.menuItemActive]: navData.isActive })
      }
      data-testid={props.dataTestId}
    >
      <SidebarMenuItemContent
        label={props.label}
        IconComponent={props.IconComponent}
        sidebarOpen={props.sidebarOpen}
      />
    </NavLink>
  );
}

export default React.memo(SidebarMenuItem);
