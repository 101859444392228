import React, { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import config from 'config/Config';

import { axiosInstance } from 'api/CASAPI';
import { AxiosError } from 'axios';

import { retreiveLocalTokenOrLogout } from 'reducers/auth/thunk';
import { useAppSelector, useAppDispatch } from 'store/store';

import { Box } from '@mui/material';

import AppToolbar from 'components/AppToolbar/AppToolbar';

import { AuthRoutes } from 'routes/routes';

import { getUrl } from 'utils/helpers/requestbuilder';

import { useRollbar } from '@rollbar/react';
import LoginBackground from 'assets/LoginBackground.svg?react';
import { logOnServerIfLoggedIn } from 'logging/logConfig';
import log from 'loglevel';

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const rollbar = useRollbar();

  useEffect(() => {
    logOnServerIfLoggedIn(rollbar);
  }, [rollbar]);

  const { isAuthenticated } = useAppSelector((state) => state.auth);

  // Check/Load token from localStorage if we're not authenticated
  useEffect(() => {
    if (isAuthenticated) return;
    dispatch(retreiveLocalTokenOrLogout());
  }, [dispatch, isAuthenticated, navigate]);

  // This effect is to trigger AD login. If at some point we remove AD, this can be removed.
  useEffect(() => {
    function getBackendState() {
      axiosInstance
        .get(config.apiBaseUrl, {
          params: { t: new Date().getTime() },
          withCredentials: true,
        })
        .catch((error: AxiosError) => {
          log.error(error);
          if (
            error.response?.status === 302 ||
            error.response?.status === 401 ||
            error.message === 'Network Error'
          ) {
            window.location.replace(
              `${config.apiBaseUrl}${getUrl('frontdoor')}?redirect=${window.location.pathname}${window.location.search}`
            );
          }
        });
    }

    getBackendState();
  }, []);

  if (isAuthenticated) {
    return <>{children}</>;
  }
  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <AppToolbar />
      <Box
        sx={{
          marginTop: (theme) => `${theme.sizing.toolbarHeight}px`,
          height: (theme) => `calc(100% - ${theme.sizing.toolbarHeight}px)`,
          width: '100%',
        }}
      >
        <Box
          position="relative"
          display="flex"
          height="100%"
          width="100%"
          justifyContent="center"
          alignItems="center"
        >
          {/* Background Image */}
          <Box
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              zIndex: -1,
              background: (theme) => theme.palette.secondary.main,
            }}
          >
            <LoginBackground
              preserveAspectRatio="xMidYMid slice"
              style={{
                height: '100%',
                width: '100%',
              }}
            />
          </Box>
          <AuthRoutes />
        </Box>
      </Box>
    </Box>
  );
};

export default AuthProvider;
