import React, { useState, useCallback } from 'react';

import { ErrorShape, Severity } from 'utils/utils';

export type ValidateComponentProps = {
  onChange?: (value: string) => void;
  onError?: (error: ErrorShape) => void;
} & Partial<ErrorShape>;

export const ValidatedComponent = <P extends ValidateComponentProps>(
  Component: React.ComponentType<P>,
  validator?: (value: string) => ErrorShape
): React.ComponentType<P> => {
  if (!validator) {
    return Component;
  }

  return function ComponentWithValidationResultAndFlags(props: P): JSX.Element {
    const [validation, setValidation] = useState<ErrorShape>({
      error: false,
      message: '',
      errorSeverity: Severity.ERROR,
    });

    let onChange = useCallback(
      (value: string) => {
        const validationResult = validator(value);
        setValidation(validationResult);
        if (!validationResult.error) {
          props.onChange?.(value);
        }
        if (props.onError) {
          props.onError(validationResult);
        }
      },
      [props]
    );

    return <Component {...props} onChange={onChange} {...validation} />;
  };
};

export default ValidatedComponent;
