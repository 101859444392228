import { useState, useCallback } from 'react';

const transformSearchValue = (value: string) => {
  const trimmedValue = value.trim();
  return trimmedValue !== '' ? trimmedValue : undefined;
};

type UseSearchOptions = {
  intialSearchValue?: string;
};

export function useSearch(
  onSearchValueChange: (searchValue?: string) => void,
  options?: UseSearchOptions
) {
  const [searchValue, setSearchValue] = useState(
    options?.intialSearchValue ?? ''
  );
  const [showSearch, setShowSearch] = useState<boolean>(!!searchValue);

  const toggleShowSearch = useCallback(() => {
    setShowSearch((showSearch) => !showSearch);
  }, []);

  const handleSearchValueChange = useCallback(
    (value: string) => {
      setSearchValue(value);
      onSearchValueChange(transformSearchValue(value));
    },
    [onSearchValueChange]
  );

  const handleSearchValueClear = useCallback(() => {
    setSearchValue('');
    setShowSearch(false);
    onSearchValueChange(undefined);
  }, [onSearchValueChange]);

  return [
    searchValue,
    handleSearchValueChange,
    handleSearchValueClear,
    showSearch,
    toggleShowSearch,
  ] as const;
}
