import React from 'react';

import { Box, IconButton, ListItem } from '@mui/material';

import { ChevronLeft } from '@mui/icons-material';

import { useStyles } from './styles';

type SidebarMenuItemOpenCloseProps = {
  sidebarOpen: boolean;
  onSidebarToggle?: () => void;
};

const SidebarMenuItemOpenClose = (
  props: SidebarMenuItemOpenCloseProps
): JSX.Element => {
  const { classes, cx } = useStyles();

  return (
    <ListItem
      sx={{
        padding: 0,
        margin: 0,
        color: (theme) => theme.palette.text.primary,
        justifyContent: 'center',
        height: 48,
      }}
    >
      <Box display="flex" width="100%" my="auto">
        <Box flex="1 1 0" />
        <Box className={classes.chevronWrapper}>
          <IconButton
            onClick={props.onSidebarToggle}
            data-testid="sidebar-toggle-button"
          >
            <ChevronLeft
              className={cx(classes.chevron, {
                [classes.chevronClosed]: !props.sidebarOpen,
              })}
            />
          </IconButton>
        </Box>
      </Box>
    </ListItem>
  );
};

export default React.memo(SidebarMenuItemOpenClose);
