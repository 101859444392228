import React, { useCallback } from 'react';

import { DataTableSearchFormProps } from './types';

import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

import { CloseIcon, SearchIcon } from './icons';

function DataTableSearchForm(props: DataTableSearchFormProps) {
  const { onSearchValueChange } = props;

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onSearchValueChange(event.target.value);
    },
    [onSearchValueChange]
  );

  return (
    <TextField
      variant="outlined"
      color="primary"
      size="small"
      value={props.searchValue}
      onChange={handleChange}
      placeholder="Search"
      data-testid="data-table-search"
      className={props.className}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon sx={{ fontSize: 20 }} />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              data-testid="search-clear"
              onClick={props.onSearchValueClear}
              aria-label="clear experiment search"
            >
              <CloseIcon
                sx={{
                  fontSize: 24,
                  color: (theme) => theme.palette.secondary.main,
                }}
              />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

export default DataTableSearchForm;
