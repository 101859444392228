export type NotificationType = 'info' | 'success' | 'warning' | 'error';

export const NotificationSeverityMap: Record<NotificationType, number> = {
  info: 0,
  success: 1,
  warning: 2,
  error: 3,
};

export type Notification = {
  type: NotificationType;
  title: string;
  message?: string;
  autoHideDuration?: number;
};

export type NotificationId = string;

export type InternalNotification = Notification & {
  uuid: NotificationId;
  creationTimestamp: number;
  read: boolean;
  autoHideDuration: number;
  removalTimeout?: NodeJS.Timeout;
};

export type NotificationContextState = {
  notifications: InternalNotification[];
  notificationDrawerOpen: boolean;
};

export type NotificationBulkOperationPayload = {
  RemoveNotification: NotificationId[];
  MarkNotificationAsRead: NotificationId[];
  SetRemovalTimeout: {
    id: NotificationId;
    timeout: InternalNotification['removalTimeout'];
  }[];
};
