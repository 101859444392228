import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  tableHead: {
    padding: '8px 16px',
    textAlign: 'start',

    // Hack -> is needed so in Chrome children of th can have height: 100%
    height: '1px',

    // But since this hack is not needed and doesn't work in FF, disable it there:
    // And there's no nice way to only target chrome.
    '@supports (-moz-appearance:none)': {
      height: 'auto',
    },

    background: theme.palette.background.default,
  },

  tableHeadEditableColumn: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    '&.warning': {
      border: 0,
      borderBottom: `1px solid ${theme.palette.warning.dark}`,
    },
    '&.error': {
      border: 0,
      borderBottom: `1px solid ${theme.palette.error.dark}`,
    },
  },
}));
