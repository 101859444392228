import { Theme } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

// Styling related constants
export const SCROLLBAR_ARROW_PADDING = 30;

export const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    position: 'relative',
    height: '100%',
    maxHeight: '100%',

    '&.vertical': {
      paddingRight: theme.sizing.scrollbarThickness,
    },
    '&.horizontal': {
      paddingBottom: theme.sizing.scrollbarThickness,
    },
  },
  root: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',

    // Hide the browser's scrollbars
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  rootEnableSnapping: {
    // Enable snapping only for devices with a TouchScreen
    '@media (hover: none)': {
      scrollBehavior: 'smooth',
      scrollSnapType: 'both proximity',
      scrollSnapStop: 'normal',
    },
  },

  scrollbarRoot: {
    position: 'absolute',
    // This is not the best, yet, but at some point we'll need to update the theme anyways
    // background: 'in',
    zIndex: theme.zIndex.overlay - 1,

    '&.vertical': {
      right: 0,
      top: 0,
      width: theme.sizing.scrollbarThickness,
      height: '100%',
    },
    '&.horizontal': {
      left: 0,
      bottom: 0,
      width: '100%',
      height: theme.sizing.scrollbarThickness,
    },
  },

  scrollbarThumb: {
    position: 'absolute',
    borderRadius: '4px',
    background: theme.palette.action.focus,

    '&.vertical': {
      top: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      width: '6px',
    },
    '&.horizontal': {
      top: '50%',
      left: 0,
      transform: 'translateY(-50%)',
      height: '6px',
    },
  },

  scrollbarIcon: {
    position: 'absolute',
    color: theme.palette.action.disabled,

    '&.active': {
      color: theme.palette.primary.main,
    },

    '&.vertical': {
      left: '50%',
      transform: 'translateX(-50%)',
      '&.start': {
        top: 5,
      },
      '&.end': {
        bottom: 5,
      },
    },
    '&.horizontal': {
      top: '50%',
      transform: 'translateY(-50%) rotateZ(-90deg)',
      '&.start': {
        left: 5,
      },
      '&.end': {
        right: 5,
      },
    },
  },

  xyScrollFiller: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: theme.sizing.scrollbarThickness,
    height: theme.sizing.scrollbarThickness,
    background: theme.palette.grey['100'],
  },
}));
