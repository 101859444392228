import { NotificationState } from './types';
import { NotificationSeverityMap } from 'types/notifications';

/**
 * Updates the unreadCount and unreadSeverity properties of the state
 * @param state NotificationSliceState
 * @returns updated NotificationSliceState
 */
export const updateInferredNotificationState = (
  state: NotificationState
): NotificationState => {
  const unreadNotifications = state.notifications.filter(
    (notification) => !notification.read
  );
  const unreadCount = unreadNotifications.length;
  const unreadSeverity =
    [...new Set(unreadNotifications.map((n) => n.type))].sort((t1, t2) => {
      return NotificationSeverityMap[t2] - NotificationSeverityMap[t1];
    })[0] ?? 'info';

  return {
    ...state,
    unreadNotificationCount: unreadCount,
    unreadNotificationSeverity: unreadSeverity,
  };
};
