import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  root: {
    height: '100vh',
  },
  dialogTitle: {
    paddingBottom: '0',
  },
}));
