import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SortFilterSearchQueryParams } from 'types/QueryParams';

import { RowsPerPageId, TableId } from 'utils/hooks/tableIds';

type GenericQueryparams = SortFilterSearchQueryParams<
  Partial<Record<string, string>>
>;

export type TableSettingsState = {
  rowsPerPage: Record<RowsPerPageId, number>;
  persistedQueryParams: Record<TableId, GenericQueryparams>;
};

export const initialState: TableSettingsState = {
  rowsPerPage: {},
  persistedQueryParams: {},
};

const tableSettingsSlice = createSlice({
  name: 'analysis',
  initialState,
  reducers: {
    setRowsPerPage: (
      state,
      action: PayloadAction<{
        tableId: RowsPerPageId;
        rowsPerPage: number;
      }>
    ) => {
      state.rowsPerPage[action.payload.tableId] = action.payload.rowsPerPage;
    },
    saveQueryParams: (
      state,
      action: PayloadAction<{
        tableId: TableId;
        queryParams: GenericQueryparams;
      }>
    ) => {
      const { tableId, queryParams } = action.payload;
      state.persistedQueryParams[tableId] = queryParams;
    },
    resetTableSettingsSlice: () => initialState,
  },
});

export const { setRowsPerPage, saveQueryParams } = tableSettingsSlice.actions;

export const tableSettingsActions = tableSettingsSlice.actions;

export const tableSettingsReducer = tableSettingsSlice.reducer;
