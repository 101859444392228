import { ExperimentInfo } from 'types/APITypes/APITypes';

import { updateExperiment } from 'api/experiments';

import {
  DataTableColumn,
  EditableDataTableColumn,
  makeTableColumns,
} from 'components/DataTable/components/DataTableColumn/DataTableColumn';

export const createBindingExperimentTableColumns = makeTableColumns<
  ExperimentInfo,
  string
>(() => [
  DataTableColumn({
    key: 'experimentStatus',
    label: 'Experiment Status',
  }),
  DataTableColumn({
    key: 'experimentName',
    label: 'Experiment Name',
  }),
  DataTableColumn({
    key: 'experimentType',
    label: 'Experiment Type',
  }),
  DataTableColumn({
    key: 'executedAt',
    label: 'Execution Date',
    formatValue: (date: string) => new Date(date).toLocaleDateString(),
  }),
  DataTableColumn({
    key: 'containedIn.filename',
    label: 'Filename',
    sortLabel: 'filename',
  }),
  DataTableColumn({
    key: 'containedIn.fileExtension',
    label: 'File Type',
    sortLabel: 'filetype',
  }),
  DataTableColumn({
    key: 'containedIn.createdAt',
    label: 'Upload Date',
    sortLabel: 'fileCreatedAt',
    formatValue: (date: string) => new Date(date).toLocaleDateString(),
  }),
  EditableDataTableColumn({
    key: 'annotation',
    label: 'Experiment Annotation',
    sortLabel: 'annotation',
    placeholder: 'Add annotation',
    onUpdate: (uuid, annotation) => {
      updateExperiment(uuid, { annotation });
    },
  }),
]);

export const createStabilityMeasurementTableColumns = makeTableColumns<
  ExperimentInfo,
  string
>(() => [
  DataTableColumn({
    key: 'experimentStatus',
    label: 'Measurement Status',
  }),
  EditableDataTableColumn({
    key: 'measurementName',
    label: 'Measurement Name',
    onUpdate: (uuid, measurementName) => {
      updateExperiment(uuid, { measurementName });
    },
  }),
  DataTableColumn({
    key: 'experimentType',
    label: 'Measurement Type',
  }),
  DataTableColumn({
    key: 'experimentName',
    label: 'Experiment Name',
  }),
  DataTableColumn({
    key: 'executedAt',
    label: 'Execution Date',
    formatValue: (date: string) => new Date(date).toLocaleDateString(),
  }),
  DataTableColumn({
    key: 'containedIn.filename',
    label: 'Filename',
    sortLabel: 'filename',
  }),
  DataTableColumn({
    key: 'containedIn.createdAt',
    label: 'Upload Date',
    sortLabel: 'fileCreatedAt',
    formatValue: (date: string) => new Date(date).toLocaleDateString(),
  }),
  EditableDataTableColumn({
    key: 'annotation',
    label: 'Annotation',
    sortLabel: 'annotation',
    placeholder: 'Add annotation',
    onUpdate: (uuid, annotation) => {
      updateExperiment(uuid, { annotation });
    },
  }),
]);
