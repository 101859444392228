import React, { useCallback } from 'react';

import { DataTableCellEditableProps, EditableCellOnUpdate } from './types';
import { DataTableBodyRendererProps } from 'components/DataTable/types/types';

import { Tooltip } from '@mui/material';
import InputBase from '@mui/material/InputBase';

import { useTableErrorContext } from 'components/DataTable/context/TableErrorContext';
import { ValidatedComponent } from 'components/Inputs/Inputs';

import { useStyles } from './styles';

import { debounce, ErrorShape, Severity } from 'utils/utils';

export function DataTableCellEditable<TRowId>(
  props: DataTableCellEditableProps<TRowId>
) {
  const { onChange, value: propValue } = props;
  const { classes, cx } = useStyles();

  const [value, setValue] = React.useState(propValue);
  const [lastValidValue, setLastValidValue] = React.useState(propValue);

  const [isEditing, setIsEditing] = React.useState(false);
  const [showTooltip, setShowTooltip] = React.useState(false);

  const handleChange = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      setValue(value);
      onChange(value);
    },
    [onChange]
  );

  // Sync in value changes from the outside
  React.useEffect(() => {
    setValue(propValue);
  }, [propValue]);

  const errorSeverity = props.error && props.errorSeverity;

  const handleBlur = useCallback(() => {
    if (props.error) {
      // If the value is not valid on blur, reset the value to the last valid value
      setValue(lastValidValue);
      onChange(lastValidValue);
    } else {
      setLastValidValue(value);
    }
    setIsEditing(false);
  }, [props.error, lastValidValue, onChange, value]);

  return (
    <Tooltip
      title={value}
      open={!isEditing && showTooltip}
      onMouseEnter={() => {
        setShowTooltip(true);
      }}
      onMouseLeave={() => {
        setShowTooltip(false);
      }}
    >
      <InputBase
        classes={classes}
        className={cx({
          error: props.error && errorSeverity === Severity.ERROR,
          warning: props.error && errorSeverity === Severity.WARNING,
        })}
        placeholder={props.placeholder}
        data-testid="datatablecell-editable-inputbase"
        inputProps={{
          'aria-label': props.label,
          maxLength: props.maxLength,
          'data-testid': 'datatablecell-editable-input',
        }}
        value={value}
        onFocus={() => setIsEditing(true)}
        onBlur={handleBlur}
        onChange={handleChange}
      />
    </Tooltip>
  );
}

type CreateDataTableCellEditableOptions<TRowId> = {
  columnId: string;
  label: string;
  placeholder: string;
  onChange: EditableCellOnUpdate<TRowId>;
  maxLength?: number;
  EditableCellComponent?: React.VFC<DataTableCellEditableProps<TRowId>>;
};

export function createDataTableCellEditable<TRowId>(
  options: CreateDataTableCellEditableOptions<TRowId>
) {
  const { columnId, placeholder, label, onChange } = options;
  const EditableCellComponent =
    options.EditableCellComponent ?? DataTableCellEditable;
  return (
    props: DataTableBodyRendererProps<TRowId, string> & Partial<ErrorShape>
  ) => {
    const { rowId, value, error, errorSeverity, message } = props;
    const { onError } = useTableErrorContext();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleChange = useCallback(
      debounce((newValue: string) => onChange(rowId, newValue)),
      [onChange, rowId]
    );

    const handleError = useCallback(
      (error: ErrorShape) => {
        onError?.({ ...error, columnId });
      },
      [onError]
    );

    return (
      <EditableCellComponent
        rowId={rowId}
        value={value?.toString() ?? ''}
        label={label || ''}
        onChange={handleChange}
        placeholder={placeholder || ''}
        maxLength={50}
        onError={handleError}
        error={error}
        errorSeverity={errorSeverity}
        message={message}
      />
    );
  };
}

// Helper function to generate a Validated, editable DataTableCell

export const makeValidatedDataTableCellEditable = (
  validator?: (value: string) => ErrorShape
) => ValidatedComponent(DataTableCellEditable, validator);
