import React from 'react';

import { useAppSelector } from 'store/store';

import {
  Card,
  ClickAwayListener,
  Popover,
  Box,
  Typography,
  Avatar,
  Button,
  Skeleton,
} from '@mui/material';

import { Logout as LogoutIcon } from '@mui/icons-material';

type UserAccountModalProps = {
  anchorEl?: HTMLElement | null;
  onClose: (event: MouseEvent | TouchEvent) => void;
  onLogout: () => void;
};

const UserAccountModal = (props: UserAccountModalProps): JSX.Element => {
  const { anchorEl, onClose, onLogout } = props;
  const { accountInformation } = useAppSelector((state) => state.auth);

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        horizontal: 'center',
        vertical: 'top',
      }}
    >
      <ClickAwayListener onClickAway={onClose}>
        <Card sx={{ p: 2, minWidth: 325 }} data-testid="useraccountmodal-root">
          <Box sx={{ display: 'flex', mb: 2 }}>
            <Avatar sx={{ mr: 2 }} />
            <Box flex="1 1 0">
              <Typography data-testid="useraccountmodal-fullname">
                {accountInformation ? (
                  accountInformation.fullName
                ) : (
                  <Skeleton data-testid="useraccountmodal-fullname-placeholder" />
                )}
              </Typography>
              <Typography
                component="p"
                variant="caption"
                data-testid="useraccountmodal-username"
              >
                {accountInformation ? (
                  accountInformation.username
                ) : (
                  <Skeleton data-testid="useraccountmodal-username-placeholder" />
                )}
              </Typography>
            </Box>
          </Box>

          {/* Button */}
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            <Button
              color="secondary"
              startIcon={<LogoutIcon />}
              onClick={onLogout}
              data-testid="useraccountmodal-logout-button"
            >
              Log Out
            </Button>
          </Box>
        </Card>
      </ClickAwayListener>
    </Popover>
  );
};

export default React.memo(UserAccountModal);
