import React, { forwardRef, useRef, useState } from 'react';

import {
  Box,
  InputAdornment,
  OutlinedInputProps,
  styled,
  TextField,
  TextFieldProps,
} from '@mui/material';
import Popper from '@mui/material/Popper';

import { ErrorIcon } from './icons';

import { theme } from 'theme/theme';

import clsx from 'clsx';

const emptyObject: Partial<OutlinedInputProps> = {};

export const CustomTextField = styled(
  forwardRef(function RenderCustomTextField(props: TextFieldProps, ref) {
    const {
      InputProps,
      onBlur,
      onFocus,
      error,
      helperText,
      ...remainingProps
    } = props;
    const { className, endAdornment, ...otherInputProps } =
      InputProps ?? emptyObject;

    const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null);
    const [popperWidth, setPopperWidth] = useState(0);
    const textFieldBoxRef = useRef<HTMLDivElement>(null);

    const displayError = error && !props.disabled;

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
      setAnchorEl((e) => e ?? event.currentTarget);
      setPopperWidth(textFieldBoxRef.current!.offsetWidth);
      onFocus?.(event);
    };

    const handleBlur = (
      event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
      onBlur?.(event);
      setAnchorEl(null);
    };

    return (
      <Box ref={textFieldBoxRef}>
        <TextField
          InputProps={
            {
              ...otherInputProps,
              className: clsx(
                className,
                props.helperText ? '' : 'no-helpertext',
                props.label ? '' : 'no-label'
              ),
              endAdornment: (
                <>
                  {endAdornment}
                  {displayError && (
                    <InputAdornment position="end">
                      <ErrorIcon
                        sx={{
                          color: props.disabled
                            ? theme.palette.action.disabled
                            : theme.palette.error.main,
                        }}
                      />
                    </InputAdornment>
                  )}
                </>
              ),
            } as Partial<OutlinedInputProps>
          }
          InputLabelProps={{
            ...props.InputLabelProps,
            shrink: true,
          }}
          onFocus={handleFocus}
          onBlur={handleBlur}
          error={displayError}
          helperText={helperText}
          inputRef={ref}
          {...remainingProps}
        />
        {props.helperText && (
          <Popper
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            placement="bottom-start"
            sx={{
              width: popperWidth,
              padding: '3px 12px 5px 12px',
              border: '2px solid',
              color: props.error
                ? theme.palette.error.main
                : theme.palette.primary.main,
              borderColor: props.error
                ? theme.palette.error.main
                : theme.palette.primary.main,
              backgroundColor: props.error ? '#fdf5f5' : '#ebf4fc',
              borderTop: 'none',
              borderRadius: '0 0 4px 4px',
              zIndex: theme.zIndex.tooltip,
              fontSize: 12,
              lineHeight: 1.5,
            }}
          >
            {props.helperText}
          </Popper>
        )}
      </Box>
    );
  })
)(() => ({
  '& .MuiFormHelperText-root': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '& .MuiInputLabel-shrink': {
    transform: 'translate(11px, 6px)',
    fontSize: 12,
  },
  '& .MuiOutlinedInput-root': {
    '& input, & .MuiSelect-select': {
      padding: '21px 12px 5px 12px',
      height: '24px',
    },
    '&.no-label': {
      '& input, & .MuiSelect-select': {
        padding: '13px 12px 13px 12px',
      },
    },
    '& legend': {
      maxWidth: 0,
    },
    '&.Mui-focused': {
      borderRadius: '4px 4px 0 0',
    },
    '&.no-helpertext': {
      borderRadius: 4,
    },
  },
}));
