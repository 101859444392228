import {
  UserDetails,
  UserInfo,
  UUIDObject,
  SigninResponse,
  AccountInformation,
  GenericAPIRequestMethod,
} from 'types/APITypes/APITypes';

import { AxiosError } from 'axios';

import { getUrl } from 'utils/helpers/requestbuilder';

import { axiosInstance, parseAPIResponse, catchError } from './CASAPI';

export const SignupUser: GenericAPIRequestMethod<
  never,
  UserDetails,
  UUIDObject
> = async (userDetails, requestConfig) => {
  try {
    const response = await axiosInstance.post(getUrl('users'), userDetails, {
      ...requestConfig,
    });
    return parseAPIResponse(response);
  } catch (error) {
    return catchError(error as Error | AxiosError);
  }
};

export const SigninUser: GenericAPIRequestMethod<
  never,
  UserInfo,
  SigninResponse
> = async (userInfo, requestConfig) => {
  try {
    const data = new FormData();
    data.append('username', userInfo.username);
    data.append('password', userInfo.password);
    const response = await axiosInstance.post(getUrl('userToken'), data, {
      ...requestConfig,
    });
    return parseAPIResponse(response);
  } catch (error) {
    return catchError(error as Error | AxiosError);
  }
};

export const getUserInformation: GenericAPIRequestMethod<
  never,
  never,
  AccountInformation
> = async (requestConfig) => {
  try {
    const response = await axiosInstance.get(getUrl('userMe'), {
      ...requestConfig,
    });
    return parseAPIResponse(response);
  } catch (error) {
    return catchError(error as Error | AxiosError);
  }
};
