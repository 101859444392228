import { LogLevelNumbers } from 'loglevel';

export enum ReportedLogLevel {
  Debug = 'debug',
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
}

export type LogRequest = {
  message: string;
  log_level: ReportedLogLevel;
};

export function ReportedLogLevelToLogLevelNumber(
  input: ReportedLogLevel
): LogLevelNumbers {
  if (input === ReportedLogLevel.Debug) return 1;
  if (input === ReportedLogLevel.Info) return 2;
  if (input === ReportedLogLevel.Warning) return 3;
  if (input === ReportedLogLevel.Error) return 4;
  throw new TypeError(
    'this function should cover all values of ReportedLogLevel'
  );
}
