import React, { MouseEventHandler, useMemo } from 'react';

import { Orientation } from '@mui/material';

import { ArrowUpIcon, ArrowDownIcon } from './icons';

import { SCROLLBAR_ARROW_PADDING, useStyles } from './styles';

import ScrollbarThumb from './ScrollbarThumb';

type ScrollbarProps = {
  show: boolean;
  size: number;
  thumbSize: number;
  offset: number;
  offsetMax: number;
  onThumbMouseDown: MouseEventHandler<HTMLDivElement>;
  orientation: Orientation;
};

const Scrollbar: React.VFC<ScrollbarProps> = (props) => {
  const { show, orientation, size, offset, offsetMax } = props;
  const { classes, cx } = useStyles();

  const startIconEnabled = useMemo(() => {
    return (offset - SCROLLBAR_ARROW_PADDING) / offsetMax > 0.05;
  }, [offset, offsetMax]);

  const endIconEnabled = useMemo(() => {
    return (offset - SCROLLBAR_ARROW_PADDING) / offsetMax < 0.95;
  }, [offset, offsetMax]);

  if (!show) return null;

  return (
    <div
      className={cx(classes.scrollbarRoot, orientation)}
      style={{
        height: orientation === 'vertical' ? size : undefined,
        width: orientation === 'horizontal' ? size : undefined,
      }}
      data-testid="scrollbar"
    >
      <ArrowUpIcon
        sx={{ fontSize: 20 }}
        className={cx(classes.scrollbarIcon, orientation, 'start', {
          active: startIconEnabled,
        })}
        data-testid="arrow-up"
      />
      <ScrollbarThumb
        size={props.thumbSize}
        offset={props.offset}
        orientation={orientation}
        onThumbMouseDown={props.onThumbMouseDown}
      />
      <ArrowDownIcon
        sx={{ fontSize: 20 }}
        className={cx(classes.scrollbarIcon, orientation, 'end', {
          active: endIconEnabled,
        })}
        data-testid="arrow-down"
      />
    </div>
  );
};

export default React.memo(Scrollbar);
