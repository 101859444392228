import { NotificationBulkOperationPayload } from 'types/notifications';

import type { AppDispatch, RootState } from 'store/store';

import { notificationsActions } from './slice';

const {
  setNotificationDrawerOpenState,
  performBulkOperation,
  removeNotification,
  suspendRemoval,
} = notificationsActions;

export const toggleNotificationDrawer =
  () => (dispatch: AppDispatch, getState: () => RootState) => {
    const { notificationDrawerOpen } = getState().notifications;
    if (notificationDrawerOpen) {
      // Close the drawer
      dispatch(closeNotificationDrawer());
    } else {
      // Open the drawer (simple)
      dispatch(openNotificationDrawer());
    }
  };

// This should handle the removal, etc, not the NotificationContext
export const closeNotificationDrawer =
  () => (dispatch: AppDispatch, getState: () => RootState) => {
    // Check if we're actually closing the drawer
    const { notificationDrawerOpen, notifications } = getState().notifications;
    if (!notificationDrawerOpen) return;

    // Mark Notifications as read and remove expired ones
    {
      // Save the current timestamp for further operations
      const now = Date.now();
      const bulkOperations = notifications.reduce(
        (actions, notification) => {
          const timeElapseSinceCreation = now - notification.creationTimestamp;

          // If the notification was created less than 500ms ago skip it:
          if (timeElapseSinceCreation < 500) return actions;

          // If the notification has expired, remove it (except if it's an error)
          if (
            notification.type !== 'error' &&
            timeElapseSinceCreation > notification.autoHideDuration
          ) {
            actions.RemoveNotification.push(notification.uuid);
            return actions;
          }

          // Set the notification as read
          if (!notification.read) {
            actions.MarkNotificationAsRead.push(notification.uuid);
          }

          // Any non-error notification needs to be marked for removal:
          if (notification.type === 'error') return actions;
          const remainingTime =
            notification.autoHideDuration - timeElapseSinceCreation;
          const removalTimeout = setTimeout(() => {
            dispatch(removeNotification(notification.uuid));
          }, remainingTime);

          actions.SetRemovalTimeout.push({
            id: notification.uuid,
            timeout: removalTimeout,
          });
          return actions;
        },
        {
          RemoveNotification: [],
          MarkNotificationAsRead: [],
          SetRemovalTimeout: [],
        } as NotificationBulkOperationPayload
      );
      dispatch(performBulkOperation(bulkOperations));
    }

    dispatch(setNotificationDrawerOpenState(false));
  };

export const openNotificationDrawer =
  () => (dispatch: AppDispatch, getState: () => RootState) => {
    // Check if we're actually closing the drawer
    const { notificationDrawerOpen } = getState().notifications;
    if (notificationDrawerOpen) return;

    // Disable auto-removal
    dispatch(suspendRemoval());

    // Open the drawer
    dispatch(setNotificationDrawerOpenState(true));
  };
