import React from 'react';
import { RouteProps } from 'react-router-dom';

const UploadPage = React.lazy(() => import('pages/UploadPage/UploadPage'));
// @ts-ignore
const ExperimentListPage = React.lazy(
  () => import('pages/ExperimentList/ExperimentList')
);

const experimentRoutes: RouteProps[] = [
  { path: '/', element: <UploadPage /> },
  { path: '/experiments', element: <ExperimentListPage /> },
  { path: '/experiments/:tab', element: <ExperimentListPage /> },
];

export default experimentRoutes;
