import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NotificationState } from './types';
import {
  InternalNotification,
  NotificationBulkOperationPayload,
  NotificationId,
  NotificationType,
} from 'types/notifications';

import { updateInferredNotificationState } from './utils';

import log from 'loglevel';

export const initialState: NotificationState = {
  notifications: [],
  notificationDrawerOpen: false,
  unreadNotificationCount: 0,
  unreadNotificationSeverity: 'info',
};

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    // Drawer Status
    setNotificationDrawerOpenState: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        notificationDrawerOpen: action.payload,
      };
    },

    // Notification creation and removal
    insertNotification: (
      state,
      action: PayloadAction<InternalNotification>
    ) => {
      // Check if a critical notification happened
      const criticalNotification = action.payload.type === 'error';

      return updateInferredNotificationState({
        ...state,
        notifications: [...state.notifications, action.payload],
        // Auto-open the drawer if a critical notification happened
        notificationDrawerOpen: criticalNotification
          ? true
          : state.notificationDrawerOpen,
      });
    },
    removeNotification: (state, action: PayloadAction<NotificationId>) => {
      const notifications = state.notifications.filter((n) => {
        if (n.uuid === action.payload) {
          if (n.removalTimeout) clearTimeout(n.removalTimeout);
          return false;
        }
        return true;
      });

      return updateInferredNotificationState({
        ...state,
        notifications,
        // Auto-close the drawer if no more notifications remain
        notificationDrawerOpen:
          notifications.length === 0 ? false : state.notificationDrawerOpen,
      });
    },
    markNotificationAsRead: (state, action: PayloadAction<NotificationId>) => {
      return updateInferredNotificationState({
        ...state,
        notifications: state.notifications.map((notification) =>
          notification.uuid === action.payload
            ? { ...notification, read: true }
            : notification
        ),
      });
    },

    suspendRemoval: (state) => {
      return {
        ...state,
        notifications: state.notifications.map((n) => {
          if (n.removalTimeout) clearTimeout(n.removalTimeout);
          return { ...n, removalTimeout: undefined };
        }),
      };
    },

    performBulkOperation: (
      state,
      action: PayloadAction<NotificationBulkOperationPayload>
    ) => {
      const notifications = state.notifications
        // perform removal, first
        .filter(
          (notification) =>
            !action.payload.RemoveNotification.includes(notification.uuid)
        )
        // then, via map, insert the removal timeout and mark as read
        .map((notification) => {
          const setRead = action.payload.MarkNotificationAsRead.includes(
            notification.uuid
          );
          const newRemovalTimeout = action.payload.SetRemovalTimeout.find(
            ({ id }) => id === notification.uuid
          )?.timeout;

          // Check if the notification already has a removal timeout, which is about to be overridden
          // In this case, it needs to be cancelled
          if (notification.removalTimeout && newRemovalTimeout) {
            log.warn(
              'WARNING: A new removal timeout was supplied for a notification, which is already marked for removal'
            );
            clearTimeout(notification.removalTimeout);
          }

          return {
            ...notification,
            read: setRead ? true : notification.read,
            removalTimeout: newRemovalTimeout,
          };
        });

      return updateInferredNotificationState({ ...state, notifications });
    },

    setUnreadState: (
      state,
      action: PayloadAction<{
        unreadNotificationCount: number;
        unreadNotificationSeverity: NotificationType;
      }>
    ) => {
      state.unreadNotificationCount = action.payload.unreadNotificationCount;
      state.unreadNotificationSeverity =
        action.payload.unreadNotificationSeverity;
    },

    resetNotificationSlice: () => {
      return {
        ...initialState,
      };
    },
  },
});

export const {
  insertNotification,
  performBulkOperation,
  removeNotification,
  suspendRemoval,
} = notificationSlice.actions;

export const notificationsActions = notificationSlice.actions;

export const notificationReducer = notificationSlice.reducer;
