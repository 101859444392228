import React, { PropsWithChildren } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { CloseIcon, ResetIcon } from './icons';

import { useStyles } from './styles';

type DataTableFilterFormProps = {
  onClose: () => void;
  onReset: () => void;
};

const DataTableFilterForm = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren<DataTableFilterFormProps>
>((props, ref) => {
  const { classes } = useStyles();

  return (
    <Paper ref={ref} className={classes.paper} data-testid="filter-form">
      <Box my="1rem" mx="0.5rem">
        <Typography variant="h5">Filter</Typography>
      </Box>
      <form className={classes.form}>{props.children}</form>
      <Box display="flex" justifyContent="flex-end">
        <Button
          color="primary"
          startIcon={<ResetIcon sx={{ fontSize: 24 }} />}
          onClick={props.onReset}
          sx={{ mr: '4px' }}
        >
          Reset
        </Button>
        <IconButton
          onClick={props.onClose}
          data-testid="filter-form-close-button"
        >
          <CloseIcon
            sx={{ fontSize: 24, color: (theme) => theme.palette.primary.main }}
          />
        </IconButton>
      </Box>
    </Paper>
  );
});

export default DataTableFilterForm;
