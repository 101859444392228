import { Theme, alpha } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  tableContainer: {
    position: 'relative',
    flex: '1 1 0',
    overflowY: 'hidden',
    maxWidth: '100%',
    overflowX: 'hidden',
  },

  tableRoot: {
    borderCollapse: 'unset',
    // Inject fake-heights into the table, so TableCell children can have height: 100%
    // They're ignored anyways
    '& tr': {
      height: '1px',
    },
    '& td': {
      height: 'inherit',

      // Since firefox handles this differently:
      '@supports (-moz-appearance:none)': {
        height: '100%',
      },
    },
  },

  customTableCellWidth: {
    maxWidth: '8rem',
  },

  tableHeader: {
    top: 0,
    position: 'sticky',
    zIndex: 100,
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: -1,
      boxShadow: '0px 8px 16px -8px rgba(0, 0, 0, 0.1)',
    },
  },

  tableCellStickyTop: {
    top: 0,
    position: 'sticky',
    '&.left': {
      left: 0,
    },
    '&.right': {
      right: 0,
    },
  },

  tableCellSticky: {
    position: 'sticky',
    left: 0,
    zIndex: 10,
    backgroundColor: theme.palette.background.default,
    '&.checkbox': {
      padding: '0px 12px 0px 12px',
      left: 0,
      width: 45.42,
    },
    '&.right': {
      right: 0,
      padding: '6px 12px 6px 12px',
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        width: '100%',
        height: '100%',
        zIndex: -1,
        boxShadow: '-5px 0px 4px -5px rgba(0, 0, 0, 0.1)',
        transition: theme.transitions.create(['box-shadow'], {
          duration: theme.transitions.duration.standard,
        }),
        [theme.breakpoints.down('sm')]: {
          boxShadow: '-5px 0px 10px -5px rgba(0, 0, 0, 0.1)',
        },
      },
    },
    '.Mui-selected &': {
      backgroundColor: '#F1F3F4',
    },
  },

  loaderOverlay: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    left: 0,
    margin: 0,
    width: '100%',
    height: '100%',
    backgroundColor: alpha(theme.palette.grey[100], 0.25),
    zIndex: theme.zIndex.overlay,
  },

  thumbnailTableCell: {
    display: 'flex',
    padding: '8px 16px',
    marginRight: '16px',
    borderBottom: 'none',
    backgroundColor: theme.palette.background.default,
  },
}));
