import { Suspense } from 'react';
import { Routes, Route, Navigate, RouteProps } from 'react-router-dom';

import { Box } from '@mui/material';

import Loader from 'components/loader/loader';

import analysisRoutes from './analysis/route';
import authRoutes from './auth/auth';
import experimentRoutes from './experiments/experiments';

const appRoutes = [...analysisRoutes, ...experimentRoutes];

const RoutingFallback = (): JSX.Element => {
  return (
    <Box sx={{ width: '100%', height: '100%', display: 'flex' }}>
      <Box m="auto">
        <Loader />
      </Box>
    </Box>
  );
};

const AppRoutes = (routes: RouteProps[]) => () => {
  const hasDefaultRoute = Boolean(routes.find((route) => route.path === '*'));
  return (
    <Suspense fallback={<RoutingFallback />}>
      <Routes>
        {routes.map(({ ...routeProps }, index) => (
          <Route {...routeProps} key={index} />
        ))}
        {!hasDefaultRoute && (
          <Route path="*" element={<Navigate to="/" replace />} />
        )}
      </Routes>
    </Suspense>
  );
};

export const AuthRoutes = AppRoutes(authRoutes);
export default AppRoutes(appRoutes);
